// @flow

import React from 'react';
import type { Store as ReduxStore, Dispatch as ReduxDispatch } from 'redux';
import compose from 'recompose/compose';
import { connect } from 'react-redux';
import { remove } from './actions';
import { getGlobalMessages } from './selectors';
import type { GlobalMessage as GlobalMessageType } from './types';
import MessageSnackbar from '../../components/MessageSnackbar';

type Props = {
  messages: Array<GlobalMessageType>,
  removeMessage: (key: number) => void,
};

export const GlobalMessage = ({ messages, removeMessage }: Props) => (
  <div className="global-message">
    {messages.map((item) => {
      const { id } = item;
      return <MessageSnackbar key={id} onExited={removeMessage} {...item} />;
    })}
  </div>
);

export const mapStateToProps = (store: ReduxStore) => ({
  messages: getGlobalMessages(store),
});

export const mapDispatchToProps = (dispatch: ReduxDispatch) => ({
  removeMessage: (id: number) => dispatch(remove(id)),
});

export default compose(connect(mapStateToProps, mapDispatchToProps))(GlobalMessage);
