// @flow

import React, { type Node } from 'react';
import Button from '@material-ui/core/Button';
import classNames from 'classnames';

import { VARIANTS } from '../constants';
import styles from './styles.module.scss';

type Props = {
  classes?: Object,
  variant?: 'primary' | 'secondary' | 'tertiary' | 'tertiarySpecial',
  extraRootClasses?: string,
  size?: 'small' | 'medium',
  startIcon?: Node,
  endIcon?: Node,
  isPill?: boolean,
  disabled?: boolean,
};

function StandardButton(props: Props) {
  const {
    classes,
    extraRootClasses,
    variant = 'primary',
    size = 'medium',
    startIcon,
    endIcon,
    isPill = false,
    disabled = false,
    ...rest
  } = props;
  const buttonVariant = Object.keys(VARIANTS).includes(variant) ? variant : 'primary';

  return (
    <Button
      variant={VARIANTS[buttonVariant]}
      disableRipple
      data-testid={`${buttonVariant}-button`}
      classes={{
        root: classNames(styles.root, extraRootClasses, { [styles.pill]: isPill }),
        disabled: styles.disabled,
        containedSizeSmall: styles.sizeSmall,
        contained: styles.primary,
        outlinedSizeSmall: styles.sizeSmall,
        outlined: styles.secondary,
        sizeSmall: styles.sizeSmall,
        text: variant === 'tertiarySpecial' ? styles.tertiarySpecial : styles.tertiary,
        ...classes,
      }}
      size={size}
      startIcon={startIcon}
      endIcon={endIcon}
      disabled={disabled}
      {...rest}
    />
  );
}

StandardButton.defaultProps = { classes: {} };

export default StandardButton;
