// @flow
import React, { Suspense } from 'react';

import LoadingIndicator from '../../components/LoadingIndicator';

const PayItForward = React.lazy(() => import('./index'));

const PayItForwardLoadable = () => (
  <Suspense fallback={<LoadingIndicator />}>
    <PayItForward />
  </Suspense>
);

export default PayItForwardLoadable;
