// @flow

import React, { useState, useEffect, type Node } from 'react';
import { IntlProvider } from 'react-intl';

import Footer from '../Footer';
import Header from './Header';
import { translationMessages as languages } from '../../i18n';

import styles from './styles.module.scss';

type Props = {
  children?: Node,
  isMobile: boolean,
};

const SelfEnrollmentWebpageLayout = (props: Props) => {
  const { children, isMobile } = props;
  const [language, setLanguage] = useState(sessionStorage.getItem('language') || 'en');

  useEffect(() => {
    let interval = null;

    const hideButton = () => {
      const button = document.getElementsByClassName('helpButtonEnabled');
      if (button.length) {
        button[0].style.display = 'none';
        clearInterval(interval);
        interval = null;
      }
    };

    interval = setInterval(hideButton, 100);
  }, []);

  return (
    <div className={styles.container}>
      <IntlProvider messages={languages[language]} locale={language}>
        <Header language={language} setLanguage={setLanguage} />
        {children}
        <Footer mobile={isMobile} />
      </IntlProvider>
    </div>
  );
};

export default SelfEnrollmentWebpageLayout;
