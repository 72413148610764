// @flow

import { Map } from 'immutable';
import { TOGGLE_MENU } from './constants';

type State = Map<string, boolean>;
type Action = { type: string };

const initialState = Map({ open: false });

function navigationDrawerReducer(state: State = initialState, action: Action) {
  switch (action.type) {
    case TOGGLE_MENU:
      return state.set('open', !(state.get('open') || false));
    default:
      return state;
  }
}

export default navigationDrawerReducer;
