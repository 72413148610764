// @flow
import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

const Trash = (props: Object) => (
  <SvgIcon viewBox="0 0 11 12" {...props}>
    <path
      d="M3.33333 0V0.666667H0V2H0.666667V10.6667C0.666667 11.0203 0.807142 11.3594 1.05719 11.6095C1.30724 11.8595 1.64638 12 2 12H8.66667C9.02029 12 9.35943 11.8595 9.60948 11.6095C9.85952 11.3594 10 11.0203 10 10.6667V2H10.6667V0.666667H7.33333V0H3.33333ZM2 2H8.66667V10.6667H2V2ZM3.33333 3.33333V9.33333H4.66667V3.33333H3.33333ZM6 3.33333V9.33333H7.33333V3.33333H6Z"
      fill="black"
    />
  </SvgIcon>
);

export default Trash;
