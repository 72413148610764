// @flow

import React, { Suspense } from 'react';

import LoadingIndicator from '../../components/LoadingIndicator';

const Component = React.lazy(() => import('./index'));

const ComponentLoadable = () => (
  <div>
    <Suspense fallback={<LoadingIndicator />}>
      <Component />
    </Suspense>
  </div>
);

export default ComponentLoadable;
