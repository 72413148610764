// @flow
import * as Sentry from '@sentry/react';
import { Map } from 'immutable';
import {
  SIGN_IN_SUCCESS,
  SIGN_OUT_SUCCESS,
  REFRESH_TOKEN_SUCCESS,
  REFRESH_TOKEN_FAIL,
  USER_INACTIVE,
  FAILED_PASSWORD_ATTEMPT,
  TWO_FA_REQUIRED,
  REFRESHING_TOKEN,
  RESET_TWO_FA_REQUIRED,
  REFRESH_ENTITIES_SELECTION,
} from './constants';
import { authSet, authGet, authRemove } from '../../utils/authentication';
import type { Auth as AuthType } from '../../utils/authentication';

function authReducer(state: Map<string, any> = authGet(), { type, auth }: { type: string, auth: AuthType }) {
  switch (type) {
    case SIGN_IN_SUCCESS: {
      const newState = state.merge({
        user: auth.user,
        token: auth.token,
        expiresIn: auth.expiresIn,
        createdAt: Date.now(),
        failedPasswordAttempt: false,
        twoFaRequired: false,
        currentMobileApplicationVersions: auth.currentMobileApplicationVersions,
      });
      Sentry.setUser({ id: auth.user.id, uuid: auth.user.uuid });
      authSet(newState);
      return newState;
    }
    case REFRESH_TOKEN_SUCCESS: {
      const newState = state.merge({
        token: auth.token,
        expiresIn: auth.expiresIn,
        createdAt: Date.now(),
        isRefreshingToken: false,
      });
      authSet(newState);
      return newState;
    }
    case REFRESHING_TOKEN: {
      const newState = state.merge({
        isRefreshingToken: true,
      });
      return newState;
    }
    case USER_INACTIVE:
    case REFRESH_TOKEN_FAIL:
    case SIGN_OUT_SUCCESS: {
      Sentry.setUser(null);
      authRemove();
      return state.merge(
        Map({
          user: null,
          token: null,
          twoFaRequired: false,
          expiresIn: null,
          isRefreshingToken: false,
          rootEntitiesModalShown: false,
          selectedRootEntities: [],
        }),
      );
    }
    case FAILED_PASSWORD_ATTEMPT: {
      // $FlowFixMe
      return state.merge(auth);
    }
    case TWO_FA_REQUIRED: {
      return state.merge(Map({ twoFaRequired: true }));
    }
    case RESET_TWO_FA_REQUIRED: {
      return state.merge(Map({ twoFaRequired: false }));
    }
    case REFRESH_ENTITIES_SELECTION: {
      const newState = state.merge(auth);
      authSet(newState);
      return newState;
    }
    default:
      return state;
  }
}

export default authReducer;
