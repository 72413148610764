// @flow

import React, { useEffect } from 'react';
import { useIntl, FormattedMessage } from 'react-intl';
import classnames from 'classnames';
import { useHistory } from 'react-router-dom';
import { isMobile } from 'react-device-detect';
import { useMutation } from '@apollo/react-hooks';
import startsWith from 'lodash/startsWith';
import { useForm, Controller } from 'react-hook-form';
import { isValidNumber, parseNumber } from 'libphonenumber-js';

import useContactNumber from '../../../useContactNumber';
import usePath from '../../../usePath';
import useSelfEnrollment from '../../../../../hooks/useSelfEnrollment';
import StandardButton from '../../../../../components/Buttons/Standard';
import PhonePrefixInput from '../../../FoundPatient/PhoneForm/PhonePrefixInput';

import { PATHS } from '../../../constants';
import { SELF_ENROLLMENT_UNSUCCESSFUL_ATTEMPT } from '../../../mutations';

import styles from './styles.module.scss';

const PhoneForm = () => {
  const getPath = usePath();
  const { formatMessage } = useIntl();

  const phoneNumber = useContactNumber();
  const history = useHistory();
  const { formData, patientData, patientPhoneNumber } = useSelfEnrollment();

  const [selfEnrollmentUnsuccessfulAttempt, { loading }] = useMutation(SELF_ENROLLMENT_UNSUCCESSFUL_ATTEMPT);

  const {
    handleSubmit,
    control,
    errors,
    formState: { isValid },
  } = useForm({
    mode: 'onChange',
    reValidateMode: 'onChange',
    defaultValues: { phone: patientPhoneNumber },
  });

  const handlePhoneSubmission = async ({ phone }) => {
    const response = await selfEnrollmentUnsuccessfulAttempt({
      variables: {
        input: {
          ...formData,
          patientPhoneNumber: !startsWith(phone, '+') ? `+${phone}` : phone,
          selfEnrollmentAttemptId: patientData?.attemptId,
        },
      },
    });

    const selfEnrollmentResponse = response?.data?.saveSelfEnrollmentUnsuccessfulAttempt;

    if (selfEnrollmentResponse?.success) {
      history.push(getPath(PATHS.THANKS));
    } else {
      history.push(getPath(PATHS.IDENTIFY));
    }
  };

  const submitOnEnter = (event) => {
    if (event.key === 'Enter' && isValid && !loading) {
      handlePhoneSubmission({ phone: patientPhoneNumber });
      event.preventDefault();
    }
  };

  useEffect(() => {
    window.addEventListener('keydown', submitOnEnter);
    return () => {
      window.removeEventListener('keydown', submitOnEnter);
    };
  });

  return (
    <>
      <p className={styles.contactContainer}>
        <FormattedMessage
          id="app.self-enroll.notify.not-found.add-number"
          values={{
            phoneNumberNotFound: isMobile ? (
              <a className={styles.link} href={`tel:${phoneNumber}`}>
                {phoneNumber}
              </a>
            ) : (
              <span className={styles.link}>{phoneNumber}</span>
            ),
          }}
        />
      </p>

      <form className={styles.phoneFormContainer}>
        <Controller
          name="phone"
          control={control}
          rules={{
            validate: (phoneValue) => {
              const parsePhoneNumber = parseNumber(`+${phoneValue}`);
              const { country } = parsePhoneNumber;
              const args = [phoneValue.startsWith('+') ? phoneValue : `+${phoneValue}`, country];
              return isValidNumber(...args);
            },
          }}
          render={({ name, value, onChange }) => (
            <PhonePrefixInput
              country="us"
              input={{ name, value, onChange }}
              placeholder={formatMessage({ id: 'app.forms.phone.enter.mobile.number' })}
              meta={{ touched: !!errors[name], error: patientPhoneNumber && errors[name] }}
            />
          )}
          data-testid="patient-not-found-phone"
        />

        <StandardButton
          disabled={!isValid || loading}
          extraRootClasses={classnames(styles.button, {
            [styles.buttonDisabled]: !isValid || loading,
          })}
          variant="secondary"
          onClick={handleSubmit(handlePhoneSubmission)}
          isPill
        >
          <FormattedMessage id="app.self-enroll.identify.form.submit" />
        </StandardButton>
      </form>
    </>
  );
};

export default PhoneForm;
