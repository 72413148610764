// @flow
import React from 'react';
import Pendo from '../../Pendo';
import useSelfEnrollment from '../../../hooks/useSelfEnrollment';

const Tracking = () => {
  const { patientData, entityData } = useSelfEnrollment();

  if (!entityData?.entityId) return null;
  if (!patientData?.patientId) return null;

  const currentPatient = {
    patientId: patientData?.patientId,
    patientEntityId: entityData.entityId,
  };

  return <Pendo type="patient" currentPatient={currentPatient} />;
};

export default Tracking;
