// @flow

import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';

import FormModal from '../../../../components/Modals/Form';
import StandardButton from '../../../../components/Buttons/Standard';

import styles from './styles.module.scss';

type Props = {
  open: boolean,
  hasPatients: boolean,
  hasAppointments: boolean,
  editingUserName: string,
  careTeamMemberName: string,
  reviewerName: string,
  appointmentAssigneeName: string,
  submitting: boolean,
  onClose: () => void,
  onSubmit: () => Promise<void>,
};

const BulkReassignmentConfirm = ({
  open,
  hasPatients,
  hasAppointments,
  editingUserName,
  careTeamMemberName,
  reviewerName,
  appointmentAssigneeName,
  submitting,
  onClose,
  onSubmit,
}: Props) => {
  const { formatMessage } = useIntl();

  const getConfirmationHelpText = () => {
    let helpTextId = 'app.user.bulk-reassignment.confirm.help-text';

    if (hasPatients && !hasAppointments) helpTextId = 'app.user.bulk-reassignment.confirm.help-text.only-patients';
    if (hasAppointments && !hasPatients) helpTextId = 'app.user.bulk-reassignment.confirm.help-text.only-appointments';

    return formatMessage({ id: helpTextId }, { userName: editingUserName });
  };

  return (
    <FormModal open={open} title={formatMessage({ id: 'app.user.bulk-reassignment.title' })}>
      <div className={styles.container}>
        <div className={styles.content}>
          <Box className={styles.helpText} mt={2} mb={6}>
            <strong>{getConfirmationHelpText()}</strong>
          </Box>
          <div>
            <Grid container>
              <Grid item xs={6} className={styles.assignment}>
                <FormattedMessage tagName="strong" id="app.user.bulk-reassignment.careteam" />
                <p>{careTeamMemberName}</p>
              </Grid>
              <Grid item xs={6} className={styles.assignment}>
                <FormattedMessage tagName="strong" id="app.user.bulk-reassignment.reviewer" />
                <p>{reviewerName}</p>
              </Grid>
              <Grid item xs={6} className={styles.assignment}>
                <FormattedMessage tagName="strong" id="app.user.bulk-reassignment.future-appointments" />
                <p>{appointmentAssigneeName}</p>
              </Grid>
            </Grid>
          </div>
        </div>
      </div>
      <div className={styles.footer}>
        <StandardButton variant="secondary" onClick={onClose} disabled={submitting}>
          <FormattedMessage id="app.forms.cancel" />
        </StandardButton>
        <StandardButton onClick={onSubmit} disabled={submitting}>
          <FormattedMessage id="app.user.bulk-reassignment.confirm" />
        </StandardButton>
      </div>
    </FormModal>
  );
};

export default BulkReassignmentConfirm;
