// @flow

import React, { Suspense } from 'react';

import LoadingIndicator from '../../components/LoadingIndicator';

const Search = React.lazy(() => import('./index'));

type Props = {
  onClose: () => void,
  onSelect: (uuid: string) => void,
};

const SearchLoadable = (props: Props) => (
  <div>
    <Suspense fallback={<LoadingIndicator />}>
      <Search {...props} />
    </Suspense>
  </div>
);

export default SearchLoadable;
