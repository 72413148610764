/**
 * i18n.js
 *
 * This will setup the i18n language files and locale data for your app.
 *
 */

import { DEFAULT_LOCALE } from './containers/App/constants';
import enTranslationMessages from './translations/en.json';
import esTranslationMessages from './translations/es.json';

export const appLocales = ['en', 'es'];

export const formatTranslationMessages = (locale, messages) => {
  const defaultFormattedMessages =
    locale !== DEFAULT_LOCALE ? formatTranslationMessages('en', enTranslationMessages) : {};
  return Object.keys({ ...messages, ...defaultFormattedMessages }).reduce((formattedMessages, key) => {
    let message = messages[key];
    if (!message && locale !== DEFAULT_LOCALE) {
      message = defaultFormattedMessages[key];
    }
    return Object.assign(formattedMessages, { [key]: message });
  }, {});
};

export const translationMessages = {
  en: formatTranslationMessages('en', enTranslationMessages),
  es: formatTranslationMessages('es', esTranslationMessages),
};
