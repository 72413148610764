// @flow

import compose from 'recompose/compose';
import { Map } from 'immutable';
import { Route } from 'react-router-dom';
import { connect } from 'react-redux';
import { authTokenSelector, authUserSelector } from '../../containers/SignIn/selectors';
import withAuthentication from './withAuthentication';
import withAuthorization from './withAuthorization';

export const mapStateToProps = (state: Map<string, any>) => ({
  token: authTokenSelector(state),
  user: authUserSelector(state),
});

export default compose(connect(mapStateToProps), withAuthentication, withAuthorization)(Route);
