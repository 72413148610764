import React from 'react';

const Deactivated = () => (
  <svg width="185" height="185" viewBox="0 0 185 185" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect x="92.5" y="0.449219" width="130" height="130" rx="20" transform="rotate(45 92.5 0.449219)" fill="#F9D849" />
    <path
      d="M114.937 111.103C95.4833 111.103 79.7129 95.0411 79.7129 75.228C79.7129 70.2589 80.7049 65.5257 82.4982 61.2226C82.8401 60.4022 82.0661 59.5356 81.2437 59.8728C68.3018 65.1788 59.1663 78.0855 59.1663 93.1654C59.1663 112.978 74.9367 129.04 94.3904 129.04C107.281 129.04 118.554 121.988 124.694 111.465C125.142 110.697 124.392 109.808 123.53 110.027C120.78 110.73 117.901 111.103 114.937 111.103Z"
      fill="#171717"
    />
  </svg>
);

export default Deactivated;
