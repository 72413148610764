// @flow

import React, { Suspense } from 'react';

import LoadingIndicator from '../../components/LoadingIndicator';

const Patient = React.lazy(() => import('./index'));

const PatientLoadable = () => (
  <div>
    <Suspense fallback={<LoadingIndicator />}>
      <Patient />
    </Suspense>
  </div>
);

export default PatientLoadable;
