// @flow

import React, { useEffect, useCallback, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { isMobile } from 'react-device-detect';
import { useMutation } from '@apollo/react-hooks';
import { useHistory } from 'react-router-dom';
import classNames from 'classnames';

import { SELF_ENROLLMENT } from '../../mutations';
import StandardButton from '../../../../components/Buttons/Standard';
import LoadingIndicator from '../../LoadingIndicator';
import useContactNumber from '../../useContactNumber';
import useSelfEnrollment from '../../../../hooks/useSelfEnrollment';

import styles from './styles.module.scss';

const ForgotAccount = () => {
  const contactNumber = useContactNumber();
  const { patientData, formData, setSelfEnrollmentResponse } = useSelfEnrollment();
  const [selfEnrollment, { loading }] = useMutation(SELF_ENROLLMENT);
  const [resetToken, setResetToken] = useState(null);
  const history = useHistory();

  const handleSendSelfEnrollment = useCallback(async () => {
    const response = await selfEnrollment({
      variables: { input: formData },
    });

    const selfEnrollmentResponse = response?.data?.selfEnrollPatient;
    if (selfEnrollmentResponse?.success && selfEnrollmentResponse?.resetToken) {
      setResetToken(selfEnrollmentResponse?.resetToken);
    }

    setSelfEnrollmentResponse(selfEnrollmentResponse);
  }, [formData, selfEnrollment, setSelfEnrollmentResponse]);

  useEffect(() => {
    handleSendSelfEnrollment();
  }, [formData, handleSendSelfEnrollment, selfEnrollment]);

  if (loading) return <LoadingIndicator />;

  const onOpenApp = () => {
    window.location = process.env.REACT_APP_ONELINK_URL;
  };

  const onResetPassword = () => {
    history.push(`/patient-reset-password/${resetToken ?? ''}`);
  };

  return (
    <div>
      <StandardButton isPill variant="primary" onClick={onOpenApp} extraRootClasses={styles.button}>
        <FormattedMessage id="app.self-enroll.found.forgot-account.open-app" />
      </StandardButton>

      <p className={styles.accountMessages}>
        <FormattedMessage
          id="app.self-enroll.found.forgot-account.username"
          values={{
            b: (content) => <b className={styles.bold}>{content}</b>,
            phone: patientData?.phoneNumber ?? '',
            email: patientData?.email ?? '',
          }}
        />
      </p>

      {resetToken && (
        <p className={styles.accountMessages}>
          <FormattedMessage
            id="app.self-enroll.found.forgot-account.password"
            values={{
              b: (content) => <b className={styles.bold}>{content}</b>,
              button: (content) => (
                <button type="button" className={styles.buttonMessage} onClick={onResetPassword}>
                  {content}
                </button>
              ),
            }}
          />
        </p>
      )}

      <p className={styles.troubleMessage}>
        <FormattedMessage id="app.self-enroll.found.forgot-account.troubles" />{' '}
        {isMobile ? (
          <a className={classNames(styles.link, styles.bold)} href={`tel:${contactNumber}`}>
            <FormattedMessage id="app.self-enroll.found.contact-support" />
          </a>
        ) : (
          <span className={classNames(styles.link, styles.bold)}>
            <FormattedMessage id="app.self-enroll.found.call-number" values={{ phoneNumber: contactNumber }} />
          </span>
        )}
      </p>
    </div>
  );
};

export default ForgotAccount;
