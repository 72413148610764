// @flow

import React, { useState, useEffect, useRef } from 'react';

import type { Participant as ParticipantType } from '../types';
import { paused } from './styles.module.scss';

export type Props = {
  participant: ParticipantType,
  className?: Object,
  muted?: boolean,
  title?: string,
};

const Participant = ({ participant, className, muted, title }: Props) => {
  const [videoTracks, setVideoTracks] = useState([]);
  const [audioTracks, setAudioTracks] = useState([]);

  const videoRef = useRef();
  const audioRef = useRef();

  const trackpubsToTracks = (trackMap) =>
    Array.from(trackMap.values())
      .map((publication) => publication.track)
      .filter((track) => track !== null);

  useEffect(() => {
    setVideoTracks(trackpubsToTracks(participant.videoTracks));
    setAudioTracks(trackpubsToTracks(participant.audioTracks));

    const trackSubscribed = (track) => {
      if (track.kind === 'video') {
        setVideoTracks((existingVideoTracks) => [...existingVideoTracks, track]);
      } else if (track.kind === 'audio') {
        setAudioTracks((existingAudioTracks) => [...existingAudioTracks, track]);
      }
    };

    const trackUnsubscribed = (track) => {
      if (track.kind === 'video') {
        setVideoTracks((existingVideoTracks) => existingVideoTracks.filter((v) => v !== track));
      } else if (track.kind === 'audio') {
        setAudioTracks((existingAudioTracks) => existingAudioTracks.filter((a) => a !== track));
      }
    };

    participant.on('trackSubscribed', trackSubscribed);
    participant.on('trackUnsubscribed', trackUnsubscribed);

    return () => {
      setVideoTracks([]);
      setAudioTracks([]);
      participant.removeAllListeners();
    };
  }, [participant]);

  // eslint-disable-next-line consistent-return
  useEffect(() => {
    const videoTrack = videoTracks[0];
    if (videoTrack) {
      videoTrack.attach(videoRef.current);
      return () => {
        videoTrack.detach();
      };
    }
  }, [videoTracks]);

  // eslint-disable-next-line consistent-return
  useEffect(() => {
    const audioTrack = audioTracks[0];
    if (audioTrack) {
      audioTrack.attach(audioRef.current);
      return () => {
        audioTrack.detach();
      };
    }
  }, [audioTracks]);

  const videoPaused = videoTracks.some(({ isEnabled }) => !isEnabled);

  return (
    <div className={className} title={title}>
      {/* eslint-disable-next-line jsx-a11y/media-has-caption */}
      <video ref={videoRef} autoPlay />
      {(videoPaused || muted) && (
        <div className={paused}>
          {videoPaused && <div>Video Disabled</div>}
          {muted && <div>Audio Muted</div>}
        </div>
      )}
      {/* eslint-disable-next-line jsx-a11y/media-has-caption */}
      <audio ref={audioRef} autoPlay />
    </div>
  );
};

export default Participant;
