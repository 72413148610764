// @flow

import React from 'react';

import useScrollToTop from '../useScrollToTop';
import PageHeader from '../PageHeader';
import callCoach from './callCoach.png';

import styles from './styles.module.scss';

const PatientEnrolled = () => {
  useScrollToTop();

  return (
    <div className={styles.container}>
      <section className={styles.imageWrapper}>
        <img src={callCoach} alt="SpotlightCards" />
      </section>
      <PageHeader
        headerId="app.self-enroll.thank-you.header"
        subHeaderId="app.self-enroll.thank-you.sub-header-not-found"
        alignCenter
      />
    </div>
  );
};

export default PatientEnrolled;
