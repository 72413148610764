// @flow

/* eslint react/no-array-index-key: 0 */

import React, { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import map from 'lodash/map';
import isArray from 'lodash/isArray';
import Snackbar from '@material-ui/core/Snackbar';
import SnackbarContent from '@material-ui/core/SnackbarContent';
import Fade from '@material-ui/core/Fade';

import { GLOBAL_MESSAGE_ERROR } from '../../containers/GlobalMessage/constants';
import type { LocalizedMessage } from '../../containers/GlobalMessage/types';

import styles from './styles.module.scss'; // eslint-disable-line css-modules/no-unused-class

type Props = {
  error?: Object,
  message: string | LocalizedMessage,
  type: string,
  onExited: (id: number) => void,
  id: number,
};

const MessageSnackbar = ({ error, message, type, onExited, id }: Props) => {
  const [open, setOpen] = useState(true);

  const handleOnClose = () => setOpen(false);
  const handleOnExited = () => onExited(id);

  const getAnchorOriginAndClassPrefixFromType = () => {
    let vertical;
    let classPrefix;

    if (type === GLOBAL_MESSAGE_ERROR) {
      vertical = 'top';
      classPrefix = 'error';
    } else {
      vertical = 'bottom';
      classPrefix = 'success';
    }

    return {
      anchorOrigin: {
        horizontal: 'center',
        vertical,
      },
      classPrefix,
    };
  };
  const { anchorOrigin, classPrefix } = getAnchorOriginAndClassPrefixFromType();

  return (
    <Snackbar
      autoHideDuration={5000}
      transition={Fade}
      anchorOrigin={anchorOrigin}
      onClose={handleOnClose}
      TransitionProps={{ onExited: handleOnExited }}
      open={open}
      classes={{ root: styles[`${classPrefix}Root`] }}
    >
      <SnackbarContent
        message={
          <div>
            {error && error.customError && (
              <div className={styles.path}>
                <FormattedMessage id="app.error.label" tagName="strong" />
                <FormattedMessage
                  id="app.errors.custom.error"
                  values={{ customError: error.customError }}
                  tagName="strong"
                />
              </div>
            )}
            {(error && error.customDescription && (
              <div className={styles.path}>
                <FormattedMessage id="app.error.label.description" />
                <FormattedMessage
                  id="app.errors.custom.description"
                  values={{ customDescription: error.customDescription }}
                />
              </div>
            )) ||
              (typeof message === 'string' ? message : <FormattedMessage {...message} />)}
            {error && error['x-amzn-requestid'] && (
              <div className={styles.path}>
                <FormattedMessage id="app.error.x-amzn-requestid" tagName="strong" />
                {error['x-amzn-requestid']}
              </div>
            )}
            {error && process.env.NODE_ENV !== 'production' && (
              <div>
                {error.path && (
                  <div className={styles.path}>
                    <FormattedMessage id="app.error.path" tagName="strong" />
                    {error.path}
                  </div>
                )}
                <div className={styles.stack}>
                  <FormattedMessage id="app.error.stack" tagName="strong" />
                  <ul className={styles.stack}>
                    {map(isArray(error.stack) ? error.stack : [error.stack], (line, idx) => (
                      <li key={`line-${idx}`}>{line}</li>
                    ))}
                  </ul>
                </div>
              </div>
            )}
          </div>
        }
        classes={{ root: styles[`${classPrefix}Content`] }}
      />
    </Snackbar>
  );
};

export default MessageSnackbar;
