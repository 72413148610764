// @flow

import React, { useEffect } from 'react';
import { Redirect, useHistory } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import { isMobile } from 'react-device-detect';
import classNames from 'classnames';
import range from 'lodash/range';

import usePath from '../usePath';
import useContactNumber from '../useContactNumber';
import useSelfEnrollment from '../../../hooks/useSelfEnrollment';
import PageHeader from '../PageHeader';
import Download from './Download';
import { MESSAGES, PATHS } from '../constants';

import styles from './styles.module.scss';

const PatientEnrolled = () => {
  const getPath = usePath();
  const contactNumber = useContactNumber();
  const history = useHistory();
  const { selfEnrollmentResponse, setMessage } = useSelfEnrollment();

  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });

    return () => {
      setMessage(MESSAGES.ACCOUNT_PENDING_TO_BE_COMPLETED);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (!selfEnrollmentResponse || !selfEnrollmentResponse.success) {
    return <Redirect to={getPath(PATHS.IDENTIFY)} />;
  }

  const onResetPassword = async () => {
    setMessage(MESSAGES.ACCOUNT_PENDING_TO_BE_COMPLETED);
    history.push(getPath(PATHS.FOUND));
  };

  return (
    <div className={styles.container}>
      <div className={styles.imageWrapper}>
        <Download />
      </div>
      <PageHeader headerId="app.self-enroll.thank-you.header" subHeaderId="app.self-enroll.thank-you.sub-header" />

      <div className={styles.didntReceiveMessage}>
        <FormattedMessage
          id="app.self-enroll.thank-you.didnt-receive"
          values={{
            button: (content) => (
              <button type="button" className={styles.buttonMessage} onClick={onResetPassword}>
                {content}
              </button>
            ),
            span: (content) => (
              <>
                {isMobile ? (
                  <a id={content} className={classNames(styles.link, styles.bold)} href={`tel:${contactNumber}`}>
                    <FormattedMessage id="app.self-enroll.found.contact-support" />
                  </a>
                ) : (
                  <span id={content} className={classNames(styles.link, styles.bold)}>
                    <FormattedMessage id="app.self-enroll.found.call-number" values={{ phoneNumber: contactNumber }} />
                  </span>
                )}
              </>
            ),
          }}
        />
      </div>

      <div className={styles.downloadMessages}>
        <p className={styles.bold}>
          <FormattedMessage id="app.self-enroll.thank-you.why-download" />
        </p>
        <ul>
          {range(1, 4).map((item) => (
            <li key={`download-app-reason-${item}`}>
              <FormattedMessage id={`app.self-enroll.thank-you.why-download.item${item}`} />
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};

export default PatientEnrolled;
