// @flow

import React, { useState, useEffect, type Element } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Collapse from '@material-ui/core/Collapse';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import classNames from 'classnames';

import styles from './styles.module.scss';

type Props = {
  defaultOpen?: boolean,
  title: string,
  children: Element<any>,
  wrapperCustomClass?: string,
  locationHash?: string,
};

const Collapsible = ({ defaultOpen = false, locationHash, title, children, wrapperCustomClass = '' }: Props) => {
  const history = useHistory();
  const { hash, pathname } = useLocation();
  const [open, setOpen] = useState(defaultOpen);

  useEffect(() => {
    if (!open && locationHash && locationHash === hash) {
      setOpen(true);
    }
  }, [hash, open, locationHash]);

  const handleOnClick = () =>
    setOpen((currentState) => {
      if (currentState) {
        history.push({ pathname });
      }

      return !currentState;
    });

  return (
    <List className={styles.list}>
      <ListItem className={styles.title} button onClick={handleOnClick}>
        <ListItemText primary={title} />
        <div className={styles.arrow}>{open ? <ExpandLess /> : <ExpandMore />}</div>
      </ListItem>
      <Collapse in={open} timeout="auto" classes={{ wrapper: classNames(styles.collapseWrapper, wrapperCustomClass) }}>
        {children}
      </Collapse>
    </List>
  );
};

export default Collapsible;
