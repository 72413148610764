// @flow
import React, { Suspense } from 'react';

import LoadingIndicator from '../../components/LoadingIndicator';

const PayerHome = React.lazy(() => import('./index'));

const PayerHomeLoadable = () => (
  <div>
    <Suspense fallback={<LoadingIndicator />}>
      <PayerHome />
    </Suspense>
  </div>
);

export default PayerHomeLoadable;
