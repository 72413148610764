import { createSelector } from 'reselect';

import { appLocales } from '../../i18n';
import { DEFAULT_LOCALE } from './constants';

/**
 * Select the language locale
 */
const getLanguage = () => navigator.language || navigator.browserLanguage;

const makeSelectLocale = createSelector(
  getLanguage,
  (language) =>
    appLocales.find((l) => l === language) ||
    appLocales.find((l) => l === language.replace(/^([a-zA-Z]+).*/, '$1')) ||
    DEFAULT_LOCALE,
);

export { makeSelectLocale, getLanguage };
