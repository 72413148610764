// @flow
import React, { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';

import ConfirmModal from '../../../../Modals/Confirm';

type Props = {
  anchorEl: HTMLElement | null,
  open: boolean,
  onEdit: () => void,
  onDelete: () => void,
  onClose: () => void,
};

const ProfilePictureMenu = ({ anchorEl, open, onClose, onEdit, onDelete }: Props) => {
  const [confirmationOpen, setConfirmationOpen] = useState(false);

  const handleOpenConfirmation = () => setConfirmationOpen(true);

  const handleCloseConfirmation = () => {
    setConfirmationOpen(false);
    onClose();
  };

  const handleDelete = async () => {
    await onDelete();
    handleCloseConfirmation();
  };

  return (
    <>
      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={onClose}
        getContentAnchorEl={null}
        anchorOrigin={{ vertical: 'bottom' }}
      >
        <MenuItem onClick={onEdit}>
          <FormattedMessage id="app.user-profile.picture.actions.edit" />
        </MenuItem>
        <MenuItem onClick={handleOpenConfirmation}>
          <FormattedMessage id="app.user-profile.picture.actions.remove" />
        </MenuItem>
      </Menu>
      <ConfirmModal
        open={confirmationOpen}
        title="app.user-profile.picture.remove-modal.title"
        message="app.user-profile.picture.remove-modal.message"
        confirmLabel="app.user-profile.picture.remove-modal.confirm"
        onCancel={handleCloseConfirmation}
        onConfirm={handleDelete}
        size="medium"
      />
    </>
  );
};

export default ProfilePictureMenu;
