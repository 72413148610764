// @flow

import { Map } from 'immutable';
import { createSelector } from 'reselect';

export const authSelector = (store: Map<string, any>) => store.get('auth');

export const authTokenSelector = createSelector(authSelector, (auth) => (auth && auth.get('token')) || null);

export const authTwoFaRequiredSelector = createSelector(
  authSelector,
  (auth) => (auth && auth.get('twoFaRequired')) || false,
);

export const authExpiresInSelector = createSelector(authSelector, (auth) => (auth && auth.get('expiresIn')) || null);

export const authCreatedAtSelector = createSelector(authSelector, (auth) => (auth && auth.get('createdAt')) || null);

export const authUserSelector = createSelector(authSelector, (auth) => (auth && auth.get('user')) || null);

export const authUserUuidSelector = createSelector(authUserSelector, (authUser) => (authUser && authUser.uuid) || null);

export const authUserTimezoneSelector = createSelector(authUserSelector, ({ entities: [{ timezone }] }) => timezone);

export const authFailedPasswordAttempt = createSelector(
  authSelector,
  (authUser) => (authUser && authUser.get('failedPasswordAttempt')) || null,
);

export const isRefreshingTokenSelector = createSelector(
  authSelector,
  (auth) => (auth && auth.get('isRefreshingToken')) || false,
);

export const selectedRootEntitiesSelector = createSelector(
  authSelector,
  (auth) => (auth && auth.get('selectedRootEntities')) || [],
);
