// @flow

import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

const Checkmark = (props: Object) => (
  <SvgIcon viewBox="-4 -3 21 15" {...props}>
    <path
      d="M2.02228 4.42975L5.57404 7.47065L11.8698 0.5L13 1.29549L5.68144 9.38889L1 5.36545L2.02228 4.42975Z"
      fill="#171717"
      stroke="#171717"
    />
  </SvgIcon>
);

export default Checkmark;
