// @flow
import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

const RotateLeft = (props: Object) => (
  <SvgIcon viewBox="0 0 18 18" {...props}>
    <path
      d="M5.49153 4.78814L0 10.2881L5.5 15.7797L11 10.2881L5.49153 4.78814ZM2.3983 10.2881L5.5 7.18644L8.59322 10.2881L5.49153 13.3898L2.3983 10.2881ZM15.678 4.98305C14.1949 3.49153 12.2373 2.74576 10.2881 2.74576V0L6.69491 3.59322L10.2881 7.18644V4.44068C11.8051 4.44068 13.322 5.01695 14.4831 6.17797C16.7966 8.49153 16.7966 12.2542 14.4831 14.5678C13.322 15.7288 11.8051 16.3051 10.2881 16.3051C9.4661 16.3051 8.64407 16.1271 7.88136 15.7881L6.61864 17.0508C7.74576 17.678 9.01695 18 10.2881 18C12.2373 18 14.1949 17.2542 15.678 15.7627C18.661 12.7881 18.661 7.95763 15.678 4.98305Z"
      fill="#2863EF"
    />
  </SvgIcon>
);

export default RotateLeft;
