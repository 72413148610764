// @flow

import React, { Suspense } from 'react';

import LoadingIndicator from '../../components/LoadingIndicator';

const ContentManagement = React.lazy(() => import('./index'));

const ContentManagementLoadable = () => (
  <div>
    <Suspense fallback={<LoadingIndicator />}>
      <ContentManagement />
    </Suspense>
  </div>
);

export default ContentManagementLoadable;
