// @flow

import React from 'react';
import cx from 'classnames';
import MicOff from '@material-ui/icons/MicOff';
import VideocamOffIcon from '@material-ui/icons/VideocamOff';
import FullscreenIcon from '@material-ui/icons/Fullscreen';
import FullscreenExitIcon from '@material-ui/icons/FullscreenExit';
import ArrowDropUpIcon from '@material-ui/icons/ArrowDropUp';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import CallEndIcon from '@material-ui/icons/CallEnd';

import styles from './styles.module.scss';

type Props = {
  maximized: boolean,
  bottom: boolean,
  muted: boolean,
  room: null | {},
  toggleMaximize: () => void,
  toggleBottom: () => void,
  toggleMute: () => void,
  toggleVideo: () => void,
  onHangup: () => void,
  videoPaused: boolean,
};

const VideoButtons = ({
  maximized,
  bottom,
  room,
  videoPaused,
  muted,
  toggleMaximize,
  toggleBottom,
  toggleMute,
  toggleVideo,
  onHangup,
}: Props) => (
  <>
    {maximized ? (
      <FullscreenExitIcon className={styles.fullScreen} onClick={toggleMaximize} />
    ) : (
      <FullscreenIcon className={styles.fullScreen} onClick={toggleMaximize} />
    )}
    {bottom ? (
      <ArrowDropUpIcon className={styles.bottomButton} onClick={toggleBottom} />
    ) : (
      <ArrowDropDownIcon className={styles.bottomButton} onClick={toggleBottom} />
    )}

    <div className={styles.videoButtons}>
      {room && (
        <>
          <div className={styles.videoButtonGroup}>
            <MicOff className={muted ? styles.videoButtonOn : styles.videoButtonOff} onClick={toggleMute} />
            <div>Mute</div>
          </div>
          <div className={styles.videoButtonGroup}>
            <VideocamOffIcon
              className={videoPaused ? styles.videoButtonOn : styles.videoButtonOff}
              onClick={toggleVideo}
            />
            <div>Video</div>
          </div>
          <div className={styles.videoButtonGroup}>
            <CallEndIcon className={cx(styles.videoButtonOn, styles.hangup)} onClick={onHangup} />
            <div>End</div>
          </div>
        </>
      )}
    </div>
  </>
);

export default VideoButtons;
