// @flow

import type { Auth as AuthType } from '../../utils/authentication';
import {
  SIGN_IN_SUCCESS,
  SIGN_OUT_SUCCESS,
  FAILED_PASSWORD_ATTEMPT,
  TWO_FA_REQUIRED,
  RESET_TWO_FA_REQUIRED,
} from './constants';

export const signInSuccess = (auth: AuthType) => ({
  type: SIGN_IN_SUCCESS,
  auth,
});

export const setFailedPasswordAttempt = (failedPasswordAttempt: AuthType) => ({
  type: FAILED_PASSWORD_ATTEMPT,
  auth: { user: null, token: null, expiresIn: null, failedPasswordAttempt },
});

export const signOutSuccess = () => ({ type: SIGN_OUT_SUCCESS });

export const markTwoFaRequired = () => ({ type: TWO_FA_REQUIRED });

export const resetTwoFaRequired = () => ({ type: RESET_TWO_FA_REQUIRED });
