/**
 * Combine all reducers in this file and export the combined reducers.
 */

import { combineReducers } from 'redux-immutable';
import { fromJS } from 'immutable';
import { LOCATION_CHANGE } from 'react-router-redux';
import { reducer as form } from 'redux-form/immutable';
import auth from './containers/SignIn/reducer';
import navigationDrawer from './components/NavigationDrawer/reducer';
import globalMessage from './containers/GlobalMessage/reducer';
import chat from './containers/Messages/MessageWindows/reducer';
import patientSubmissions from './containers/PatientSubmissions/reducer';

/*
 * routeReducer
 *
 * The reducer merges route location changes into our immutable state.
 * The change is necessitated by moving to react-router-redux@4
 *
 */

// Initial routing state
const routeInitialState = fromJS({
  location: null,
});

/**
 * Merge route into the global application state
 */
function route(state = routeInitialState, action) {
  switch (action.type) {
    /* istanbul ignore next */
    case LOCATION_CHANGE:
      return state.merge({
        location: action.payload,
      });
    default:
      return state;
  }
}

/**
 * Creates the main reducer with the dynamically injected ones
 */
export default function createReducer(injectedReducers) {
  return combineReducers({
    route,
    form,
    auth,
    navigationDrawer,
    globalMessage,
    chat,
    patientSubmissions,
    ...injectedReducers,
  });
}
