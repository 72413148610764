// @flow

import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

const UploadIcon = (props: {}) => (
  <SvgIcon viewBox="0 0 30 36" {...props}>
    <path d="M13.2588 31.7647C13.1484 31.7647 13.0588 31.6752 13.0588 31.5647L13.0588 19.2588C13.0588 19.1484 12.9693 19.0588 12.8588 19.0588L11.064 19.0588C10.8858 19.0588 10.7966 18.8434 10.9226 18.7174L15.035 14.605C15.1132 14.5268 15.2398 14.5268 15.3179 14.605L19.4303 18.7174C19.5563 18.8434 19.4671 19.0588 19.2889 19.0588L17.4941 19.0588C17.3837 19.0588 17.2941 19.1484 17.2941 19.2588L17.2941 31.5647C17.2941 31.6752 17.2046 31.7647 17.0941 31.7647L13.2588 31.7647ZM8.82353 35.8C8.82353 35.9105 8.91307 36 9.02353 36L21.3294 36C21.4399 36 21.5294 35.9105 21.5294 35.8L21.5294 23.4941C21.5294 23.3837 21.619 23.2941 21.7294 23.2941L29.5172 23.2941C29.6953 23.2941 29.7846 23.0787 29.6586 22.9527L15.3179 8.61201C15.2398 8.5339 15.1132 8.5339 15.035 8.61201L0.694363 22.9527C0.568372 23.0787 0.657603 23.2941 0.835784 23.2941L8.62353 23.2941C8.73399 23.2941 8.82353 23.3837 8.82353 23.4941L8.82353 35.8ZM0.352941 4.03529C0.352941 4.14575 0.442483 4.2353 0.552939 4.2353L29.8 4.23529C29.9105 4.23529 30 4.14575 30 4.03529L30 0.199997C30 0.0895424 29.9105 7.8281e-09 29.8 1.74846e-08L0.552941 2.57434e-06C0.442483 2.584e-06 0.35294 0.089545 0.35294 0.2L0.352941 4.03529Z" />
  </SvgIcon>
);

export default UploadIcon;
