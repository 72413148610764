// @flow

import React from 'react';

import ToggleButton from '../Buttons/Toggle';

import styles from './styles.module.scss';

type Props = {
  language: string,
  setLanguage: (language: string) => void,
};

const LanguageToggle = (props: Props) => {
  const { language, setLanguage } = props;

  const onLeftButtonClick = () => {
    sessionStorage.setItem('language', 'en');
    setLanguage('en');
  };

  const onRightButtonClick = () => {
    sessionStorage.setItem('language', 'es');
    setLanguage('es');
  };

  return (
    <div className={styles.container}>
      <ToggleButton
        variant="primary"
        leftProps={{
          text: 'English',
        }}
        rightProps={{
          text: 'Español',
        }}
        onToggleLeft={onLeftButtonClick}
        onToggleRight={onRightButtonClick}
        toggleDefault={language === 'en' ? 'left' : 'right'}
        customButtonStyle={{ outlined: styles.customOutlinedStyle }}
        customButtonRootStyle={styles.customButtonRootStyle}
        customToggleContainer={styles.customToggleContainer}
      />
    </div>
  );
};

export default LanguageToggle;
