// @flow
import React from 'react';
import { useIntl } from 'react-intl';
import PhoneInput from 'react-phone-input-2';
import classNames from 'classnames';

import useSelfEnrollment from '../../../../../hooks/useSelfEnrollment';
import { inputStyle } from './constants';

import 'react-phone-input-2/lib/style.css';
import styles from './styles.module.scss';

type Props = {
  country?: string,
  label?: string,
  input: {
    name?: string,
    value?: string,
    onChange?: (phoneNumber: string) => void,
    onBlur?: () => void,
  },
  submitOnEnter?: () => void,
  meta?: {
    touched?: any,
    error?: any,
  },
  placeholder?: string,
};

function PhonePrefixInput(props: Props) {
  const { patientPhoneNumber, setPatientPhoneNumber } = useSelfEnrollment();
  const {
    country,
    label = '',
    input: { onChange, value, name, ...events },
    meta,
    submitOnEnter,
    placeholder = '',
    ...custom
  } = props;
  const { formatMessage } = useIntl();
  const { touched, error } = meta || {};
  const optional = {
    ...(value ? { value } : { value: patientPhoneNumber }),
  };
  const inputProps = {
    ...(name ? { id: name, name } : null),
    required: true,
    autoFocus: true,
  };

  return (
    <div className={styles.container}>
      <div className={styles.phoneInputcontainer}>
        <PhoneInput
          className={styles.phoneInput}
          inputClass={classNames({
            [styles.phoneInputError]: (touched && error) || (touched && !value && !patientPhoneNumber),
          })}
          label={label}
          placeholder={placeholder}
          country={country}
          inputStyle={inputStyle}
          buttonClass={classNames(styles.button, {
            [styles.buttonError]: (touched && error) || (touched && !value && !patientPhoneNumber),
          })}
          inputProps={inputProps}
          onChange={(phoneNumber) => {
            if (onChange) onChange(phoneNumber);
            setPatientPhoneNumber(phoneNumber);
          }}
          onEnterKeyPress={submitOnEnter}
          {...meta}
          {...optional}
          {...custom}
          {...events}
        />
      </div>
      {touched && error && (
        <p className={styles.phoneError}>
          {formatMessage({ id: 'app.onboarding.download.app.error.invalid-phone-number' })}
        </p>
      )}
      {touched && !value && !patientPhoneNumber && (
        <p className={styles.phoneError}>{formatMessage({ id: 'app.self-enroll.identify.form.field-required' })}</p>
      )}
    </div>
  );
}

export default PhonePrefixInput;
