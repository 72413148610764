// @flow
import useAuth from './useAuth';

const usePermission = (permission: string) => {
  const { user } = useAuth();
  const { role: { permissions = [] } = {} } = user || {};
  const permissionNames = permissions.map(({ name }) => name);

  return permissionNames.includes(permission);
};

export default usePermission;
