// @flow
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { isMobile } from 'react-device-detect';

import InputField from '../../../../../components/FormControls/InputField';
import useSelfEnrollment from '../../../../../hooks/useSelfEnrollment';

import styles from './styles.module.scss';
import useContactNumber from '../../../useContactNumber';

const OrganizationDisplay = () => {
  const phoneNumber = useContactNumber();
  const { entityData } = useSelfEnrollment();
  const organizationName = entityData?.organizationName ?? '';

  return (
    <>
      <InputField
        disabled
        placeholder={organizationName}
        input={{ name: 'healthInsurancePlan', value: '', onChange: () => {} }}
      />
      <p className={styles.phoneLinkText}>
        <span className={styles.insuranceContactMessage}>
          <FormattedMessage id="app.self-enroll.identify.form.insurance-contact-message" />
        </span>
        <span className={styles.phoneLinkCall}>
          <FormattedMessage id="app.self-enroll.call" />{' '}
          {isMobile ? (
            <a href={`tel:${phoneNumber}`}>{phoneNumber}</a>
          ) : (
            <span className={styles.contactNumber}>{phoneNumber}</span>
          )}
        </span>
      </p>
    </>
  );
};

export default OrganizationDisplay;
