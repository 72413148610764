// @flow

import { GLOBAL_MESSAGE_ERROR, GLOBAL_MESSAGE_SUCCESS, GLOBAL_MESSAGE_REMOVE } from './constants';
import type { LocalizedMessage } from './types';

const getID = () => new Date().valueOf() + Math.random();

export const addError = (message: string | LocalizedMessage, error?: Object) => ({
  type: GLOBAL_MESSAGE_ERROR,
  id: getID(),
  message,
  error,
});

export const addSuccess = (message: string | LocalizedMessage) => ({
  type: GLOBAL_MESSAGE_SUCCESS,
  id: getID(),
  message,
});

export const remove = (id: number) => ({
  type: GLOBAL_MESSAGE_REMOVE,
  id,
});
