// @flow

export const SIGN_IN_SUCCESS = 'SIGN_IN_SUCCESS';
export const SIGN_OUT_SUCCESS = 'SIGN_OUT_SUCCESS';
export const REFRESH_TOKEN_SUCCESS = 'REFRESH_SUCCESS';
export const FAILED_PASSWORD_ATTEMPT = 'FAILED_PASSWORD_ATTEMPT';
export const TWO_FA_REQUIRED = 'TWO_FA_REQUIRED';
export const RESET_TWO_FA_REQUIRED = 'RESET_TWO_FA_REQUIRED';

export const REFRESH_TOKEN_FAIL = 'REFRESH_FAIL';
export const USER_INACTIVE = 'USER_INACTIVE';
export const REFRESHING_TOKEN = 'REFRESHING_TOKEN';
export const REFRESH_ENTITIES_SELECTION = 'REFRESH_ENTITIES_SELECTION';
