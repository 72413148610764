// @flow

const createImage = (url: string) =>
  new Promise((resolve) => {
    const image = new Image();
    image.addEventListener('load', () => resolve(image));
    image.src = url;
  });

const getRadianAngle = (degreeValue: number) => (degreeValue * Math.PI) / 180;

const rotateSize = (width: number, height: number, rotation: number) => {
  const angle = getRadianAngle(rotation);

  return {
    width: Math.abs(Math.cos(angle) * width) + Math.abs(Math.sin(angle) * height),
    height: Math.abs(Math.sin(angle) * width) + Math.abs(Math.cos(angle) * height),
  };
};

export const getCroppedImg = async ({
  imageSrc,
  pixelCrop,
  rotation,
  flip = { horizontal: false, vertical: false },
}: {
  imageSrc: string,
  pixelCrop: { x: number, y: number, width: number, height: number },
  rotation: number,
  flip?: { horizontal: boolean, vertical: boolean },
}) => {
  const image = await createImage(imageSrc);
  const canvas = document.createElement('canvas');
  const ctx = canvas.getContext('2d');

  if (!ctx) return null;

  const angle = getRadianAngle(rotation);

  // calculate bounding box of the rotated image
  const { width: boxWidth, height: boxHeight } = rotateSize(image.width, image.height, rotation);

  // set canvas size to match the bounding box
  canvas.width = boxWidth;
  canvas.height = boxHeight;

  // translate canvas context to a central location to allow rotating and flipping around the center
  ctx.translate(boxWidth / 2, boxHeight / 2);
  ctx.rotate(angle);
  ctx.scale(flip.horizontal ? -1 : 1, flip.vertical ? -1 : 1);
  ctx.translate(-image.width / 2, -image.height / 2);

  // draw rotated image
  ctx.drawImage(image, 0, 0);

  // croppedAreaPixels values are bounding box relative
  // extract the cropped image using these values
  const data = ctx.getImageData(pixelCrop.x, pixelCrop.y, pixelCrop.width, pixelCrop.height);

  // set canvas width to final desired crop size - this will clear existing context
  canvas.width = pixelCrop.width;
  canvas.height = pixelCrop.height;

  // paste generated rotate image at the top left corner
  ctx.putImageData(data, 0, 0);

  return new Promise((resolve) => {
    canvas.toBlob((file) => {
      resolve({ file, url: URL.createObjectURL(file) });
    }, 'image/jpeg');
  });
};
