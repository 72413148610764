// @flow

import React from 'react';

import Info from '../../../../../components/Icons/Info';
import useSelfEnrollment from '../../../../../hooks/useSelfEnrollment';

import styles from './styles.module.scss';

type Props = {
  text: string,
  onClick: () => void,
};

const Description = ({ text, onClick }: Props) => {
  const { entityData } = useSelfEnrollment();
  const sampleInsuranceCard = entityData?.selfEnrollment?.sampleInsuranceCard ?? '';

  return (
    <div className={styles.labelContainer}>
      <p className={styles.label}>
        {text}{' '}
        {sampleInsuranceCard && (
          <button type="button" className={styles.info} onClick={onClick}>
            <Info className={styles.infoBubble} />
          </button>
        )}
      </p>
    </div>
  );
};

export default Description;
