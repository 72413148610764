// @flow

import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import Box from '@material-ui/core/Box';

import FormModal from '../../../../components/Modals/Form';
import StandardButton from '../../../../components/Buttons/Standard';

import styles from './styles.module.scss';

type Props = {
  open: boolean,
  userName: string,
  submitting: boolean,
  onClose: () => void,
  onSubmit: () => Promise<void>,
};

const DeleteUserConfirm = ({ open, userName, submitting, onClose, onSubmit }: Props) => {
  const { formatMessage } = useIntl();

  return (
    <FormModal
      customClassName={styles.modal}
      open={open}
      title={formatMessage({ id: 'app.user.delete-confirmation-title' })}
    >
      <div className={styles.container}>
        <div className={styles.content}>
          <Box className={styles.helpText} mt={2} mb={6}>
            <FormattedMessage id="app.user.delete-confirmation-help-text" values={{ userName }} />
          </Box>
        </div>
        <div className={styles.footer}>
          <StandardButton onClick={onSubmit} disabled={submitting}>
            <FormattedMessage id="app.user.bulk-reassignment.confirm" />
          </StandardButton>
          <StandardButton variant="secondary" onClick={onClose} disabled={submitting}>
            <FormattedMessage id="app.forms.cancel" />
          </StandardButton>
        </div>
      </div>
    </FormModal>
  );
};

export default DeleteUserConfirm;
