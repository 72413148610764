// @flow

import gql from 'graphql-tag';

export const BULK_REASSIGN_PATIENTS = gql`
  mutation bulkReassignPatients(
    $entityId: Int!
    $userUuid: ID!
    $careTeamUuid: ID
    $reviewerUuid: ID
    $appointmentAssigneeUuid: ID
  ) {
    bulkReassignPatients(
      entityId: $entityId
      userUuid: $userUuid
      careTeamUuid: $careTeamUuid
      reviewerUuid: $reviewerUuid
      appointmentAssigneeUuid: $appointmentAssigneeUuid
    ) {
      id
      careTeamPatients {
        id
      }
      reviewedPatients {
        id
      }
      appointments {
        id
      }
    }
  }
`;

export const DELETE_USER = gql`
  mutation destroyUser($uuid: ID!) {
    deleteUser(uuid: $uuid) {
      uuid
      id
    }
  }
`;
