// @flow
import React from 'react';
import { Switch, Redirect, useRouteMatch } from 'react-router-dom';

import SecureRoute from '../../components/SecureRoute';
import PayerHome from '../PayerHome/Loadable';
import DocumentCenter from '../DocumentCenter/Loadable';

const Payer = () => {
  const { path } = useRouteMatch();

  return (
    <Switch>
      <SecureRoute path={`${path}/documents`} permission="documentCenter" component={DocumentCenter} />
      <SecureRoute path={`${path}/`} permission="payerHomepage" component={PayerHome} />
      <Redirect to={`${path}/`} />
    </Switch>
  );
};

export default Payer;
