// @flow
import React from 'react';
import { useIntl } from 'react-intl';
import { useQuery } from '@apollo/react-hooks';

import Dropdown, { type Option } from '../../../../../components/FormControls/Dropdown';
import { ORGANIZATIONS } from './queries';

type Props = {
  value: string,
  onChange: () => void,
};

const OrganizationSelector = ({ value, onChange }: Props) => {
  const { formatMessage } = useIntl();
  const { loading, data } = useQuery(ORGANIZATIONS, { fetchPolicy: 'cache-and-network' });

  let options: Option[] = [];
  if (data) {
    const { organizations } = data;
    options = organizations.map((organization) => ({
      value: organization.suffix,
      label: organization.displayName ?? organization.name,
    }));
  }

  return (
    <Dropdown
      input={{
        name: `organization`,
        value,
        onChange,
      }}
      placeholder={formatMessage({ id: 'app.self-enroll.identify.form.select-insurance-plan' })}
      options={options}
      meta={{ touched: true, error: '' }}
      loading={loading}
    />
  );
};

export default OrganizationSelector;
