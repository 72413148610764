// @flow
import React, { Suspense } from 'react';

import LoadingIndicator from '../../components/LoadingIndicator';

const DocumentCenter = React.lazy(() => import('./index'));

const DocumentCenterLoadable = () => (
  <div>
    <Suspense fallback={<LoadingIndicator />}>
      <DocumentCenter />
    </Suspense>
  </div>
);

export default DocumentCenterLoadable;
