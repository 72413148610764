// @flow

import React, { useEffect, useRef } from 'react';
type Props = {
  onWidthCalculated: (width: number) => void,
  onHeightCalculated: (height: number) => void,
};

export default function SizeCalculator(props: Props) {
  const { onWidthCalculated, onHeightCalculated } = props;
  const spaceFillerRef = useRef<any>();

  useEffect(() => {
    if (spaceFillerRef.current) {
      onHeightCalculated(spaceFillerRef.current.clientHeight);
      onWidthCalculated(spaceFillerRef.current.clientWidth);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [spaceFillerRef.current]);

  if (spaceFillerRef.current) {
    return null;
  }

  // use inline style because width and height canont be customized
  return <div style={{ width: '100%', height: '100%' }} ref={spaceFillerRef} />;
}
