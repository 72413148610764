// @flow
import React from 'react';
import MuiSlider from '@material-ui/core/Slider';

import styles from './styles.module.scss';

type Props = {
  value: number,
  min?: number,
  max?: number,
  onChange: (event: Object, value: number) => void,
};

const Slider = (props: Props) => (
  <MuiSlider
    classes={{
      rail: styles.sliderRail,
      track: styles.sliderTrack,
      thumb: styles.sliderThumb,
      active: styles.sliderThumbActive,
    }}
    {...props}
  />
);

export default Slider;
