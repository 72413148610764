// @flow

import React, { useMemo } from 'react';
import { Redirect } from 'react-router-dom';
import { parsePhoneNumber } from 'libphonenumber-js';
import startsWith from 'lodash/startsWith';

import useScrollToTop from '../useScrollToTop';
import usePath from '../usePath';
import useContactNumber from '../useContactNumber';
import useSelfEnrollment from '../../../hooks/useSelfEnrollment';
import PageHeader from '../PageHeader';
import Image from './Image';
import PageButtons from './PageButtons';
import { MESSAGES, SCENARIOS, PATHS } from '../constants';

import styles from './styles.module.scss';

const responses = {
  [MESSAGES.PATIENT_DEACTIVATED]: SCENARIOS.DEACTIVATED,
  [MESSAGES.PATIENT_NOT_FOUND]: SCENARIOS.NOT_FOUND,
  [MESSAGES.MULTIPLE_PATIENTS_FOUND]: SCENARIOS.MULTIPLE_FOUND,
  [MESSAGES.PATIENT_PARTIALLY_MATCHED]: SCENARIOS.PARTIALLY_FOUND,
  [MESSAGES.MULTIPLE_PATIENTS_PARTIALLY_MATCHED]: SCENARIOS.PARTIALLY_FOUND,
};

const NotifyPatient = () => {
  useScrollToTop();
  const getPath = usePath();
  const contactNumber = useContactNumber();
  const { message, patientData, entityData } = useSelfEnrollment();
  const { orgPhone = '', selfEnrollment = {} } = entityData ?? {};
  const { selfEnrollmentSupportNumber } = selfEnrollment;
  const phone = selfEnrollmentSupportNumber ?? orgPhone;
  const name = patientData?.name ?? '';
  const scenario = responses[message] ?? SCENARIOS.UNEXPECTED_ERROR;

  const formattedPhoneNumber = useMemo(() => {
    if (!phone) return '';
    if (startsWith(phone, '+')) parsePhoneNumber(phone).formatInternational();
    return parsePhoneNumber(`+1${phone}`).formatNational();
  }, [phone]);

  if (!message) {
    return <Redirect to={getPath(PATHS.IDENTIFY)} />;
  }

  return (
    <div className={styles.container}>
      <Image scenario={scenario} />
      <PageHeader
        headerId={`app.self-enroll.notify.${scenario}.header`}
        phoneNumber={contactNumber}
        scenario={scenario}
        subHeaderId={`app.self-enroll.notify.${scenario}.sub-header`}
        values={{ name }}
      />
      <div className={styles.buttonContainer}>
        <PageButtons phoneNumber={contactNumber} scenario={scenario} formattedPhoneNumber={formattedPhoneNumber} />
      </div>
    </div>
  );
};

export default NotifyPatient;
