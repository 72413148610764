// @flow
import useSelfEnrollment from '../../hooks/useSelfEnrollment';

const useContactNumber = () => {
  const { entityData } = useSelfEnrollment();
  const { orgPhone, selfEnrollment } = entityData || {};
  const contactNumber = selfEnrollment?.selfEnrollmentSupportNumber ?? orgPhone ?? '';
  const cleaned = contactNumber.replace(/[^0-9]/g, '');
  const match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
  const phoneNumber = match ? `${match[1]}-${match[2]}-${match[3]}` : '';

  return phoneNumber;
};

export default useContactNumber;
