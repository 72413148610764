// @flow

import React from 'react';
import isEmpty from 'lodash/isEmpty';
import Box from '@material-ui/core/Box';
import Divider from '@material-ui/core/Divider';
import { FormattedMessage } from 'react-intl';

import LoadingIndicator from '../LoadingIndicator';
import StandardButton from '../Buttons/Standard';
import AssignmentList from './AssignmentList';
import usePermission from '../../hooks/usePermission';
import type { EntityType } from '../Forms/ManageUser/types';

import styles from './styles.module.scss';

type Patient = {
  uuid: string,
  firstName: string,
  lastName: string,
  entity: {
    id: number,
  },
};

type Props = {
  loading: boolean,
  entities: EntityType[],
  careTeamPatientsByEntity: { [number]: Patient[] },
  reviewedPatientsByEntity: { [number]: Patient[] },
  patientWithAppointmentsByEntity: { [number]: Patient[] },
  onBulkReassignmentModalOpen: (entityId: number) => void,
};

const PatientAssignments = ({
  loading,
  entities,
  careTeamPatientsByEntity,
  reviewedPatientsByEntity,
  patientWithAppointmentsByEntity,
  onBulkReassignmentModalOpen,
}: Props) => {
  const canBulkReassignPatients = usePermission('users');

  if (!canBulkReassignPatients) {
    return null;
  }

  if (loading) {
    return <LoadingIndicator />;
  }

  if (
    isEmpty(careTeamPatientsByEntity) &&
    isEmpty(reviewedPatientsByEntity) &&
    isEmpty(patientWithAppointmentsByEntity)
  ) {
    return (
      <div className={styles.noAssignment}>
        <FormattedMessage key="message" tagName="p" id="app.forms.manage.user.patient.assignment.note" />
      </div>
    );
  }

  const entityHasAssignments = (entityId) =>
    careTeamPatientsByEntity[entityId] ||
    reviewedPatientsByEntity[entityId] ||
    patientWithAppointmentsByEntity[entityId];

  return (
    <fieldset key="assignments">
      <div>
        <FormattedMessage tagName="legend" id="app.forms.patient.assignments" />
        {entities.map(({ id: entityId, name: entityName }) => {
          if (!entityHasAssignments(entityId)) return null;

          return (
            <div key={entityId}>
              <Box
                className={styles.entityHeader}
                display="flex"
                justifyContent="space-between"
                alignItems="center"
                my={3}
              >
                <strong>{entityName}</strong>
                <StandardButton variant="secondary" onClick={() => onBulkReassignmentModalOpen(entityId)}>
                  <FormattedMessage id="app.user.bulk-reassignment.bulk-reassign" />
                </StandardButton>
              </Box>
              <Divider className={styles.divider} />
              <AssignmentList
                labelId="app.forms.patient.assignments.careteam"
                patients={careTeamPatientsByEntity[entityId]}
              />
              <AssignmentList
                labelId="app.forms.patient.assignments.reviewer"
                patients={reviewedPatientsByEntity[entityId]}
              />
              <AssignmentList
                labelId="app.forms.patient.assignments.appointment"
                patients={patientWithAppointmentsByEntity[entityId]}
              />
            </div>
          );
        })}
      </div>
    </fieldset>
  );
};

export default PatientAssignments;
