// @flow

import { SELECTED_SUBMISSION, SET_SUBMISSION_NOTES } from './constants';
import type { SubmissionDetails } from './types';
import type { PatientNote } from '../PatientNotes';

export const setSubmissionDetailsAction = (submissionDetails: SubmissionDetails) => ({
  type: SELECTED_SUBMISSION,
  payload: submissionDetails,
});

export const setSubmissionNotesAction = (notesGroupedBySubmission: { [key: string]: PatientNote }[]) => ({
  type: SET_SUBMISSION_NOTES,
  payload: { notesGroupedBySubmission },
});
