// @flow

import React, { type Node, useState } from 'react';
import Box from '@material-ui/core/Box';
import classNames from 'classnames';

import StandardButton from '../Standard';

import styles from './styles.module.scss';

type ButtonProps = {
  startIcon?: Node,
  endIcon?: Node,
  text?: string,
};

type Props = {
  variant?: 'primary' | 'secondary',
  leftProps: ButtonProps,
  rightProps: ButtonProps,
  onToggleLeft: () => void,
  onToggleRight: () => void,
  disabled?: boolean,
  toggleDefault?: 'left' | 'right',
  leftDisabled?: boolean,
  rightDisabled?: boolean,
  customButtonStyle?: {},
  customButtonRootStyle?: {},
  customToggleContainer?: {},
};

const Toggle = (props: Props) => {
  const {
    variant = 'primary',
    leftProps: { startIcon: leftStartIcon, endIcon: leftEndIcon, text: leftText },
    rightProps: { startIcon: rightStartIcon, endIcon: rightEndIcon, text: rightText },
    onToggleLeft,
    onToggleRight,
    disabled,
    toggleDefault = 'left',
    leftDisabled = false,
    rightDisabled = false,
    customButtonRootStyle,
    customButtonStyle,
    customToggleContainer,
  } = props;
  const [toggle, setToggle] = useState(toggleDefault === 'left');

  const handleToggle = () => {
    if (leftDisabled || rightDisabled) return;

    if (toggle) onToggleRight();
    else onToggleLeft();
    setToggle(!toggle);
  };

  return (
    <Box
      className={classNames(
        styles.toggleContainer,
        { [styles.disabled]: disabled || leftDisabled || rightDisabled },
        customToggleContainer,
      )}
    >
      <StandardButton
        variant={toggle ? variant : 'secondary'}
        startIcon={leftStartIcon}
        endIcon={leftEndIcon}
        onClick={handleToggle}
        disabled={disabled || leftDisabled}
        extraRootClasses={classNames(
          styles.toggleLeft,
          {
            [styles.primary]: variant === 'primary',
            [styles.secondary]: variant === 'secondary',
            [styles.active]: toggle,
            [styles.disabledButton]: disabled || leftDisabled,
          },
          customButtonRootStyle,
        )}
        data-testid="toggle-left"
        classes={customButtonStyle}
      >
        {leftText}
      </StandardButton>
      <StandardButton
        variant={!toggle ? variant : 'secondary'}
        startIcon={rightStartIcon}
        endIcon={rightEndIcon}
        onClick={handleToggle}
        disabled={disabled || rightDisabled}
        extraRootClasses={classNames(
          styles.toggleRight,
          {
            [styles.primary]: variant === 'primary',
            [styles.secondary]: variant === 'secondary',
            [styles.active]: !toggle,
            [styles.disabledButton]: disabled || rightDisabled,
          },
          customButtonRootStyle,
        )}
        data-testid="toggle-right"
        classes={customButtonStyle}
      >
        {rightText}
      </StandardButton>
    </Box>
  );
};

export default Toggle;
