// @flow

import { List, Map } from 'immutable';
import type { Map as MapType, List as ListType } from 'immutable';
import { GLOBAL_MESSAGE_ERROR, GLOBAL_MESSAGE_SUCCESS, GLOBAL_MESSAGE_REMOVE } from './constants';

type State = ListType<MapType<string, string>>;

export default function globalMessageReducer(state: State = List(), action: Object) {
  const { type } = action;

  switch (type) {
    case GLOBAL_MESSAGE_ERROR:
    case GLOBAL_MESSAGE_SUCCESS: {
      return state.push(Map(action));
    }
    case GLOBAL_MESSAGE_REMOVE: {
      return state.delete(state.findIndex((msg) => msg.get('id') === action.id));
    }
    default:
      return state;
  }
}
