// @flow
import * as React from 'react';
import isString from 'lodash/isString';
import { FormattedMessage } from 'react-intl';
import Modal from '@material-ui/core/Modal';
import cx from 'classnames';
import StandardButton from '../../Buttons/Standard';
import styles from './styles.module.scss';

type FormattedMessageType = { id: string, values?: Object };

export type Props = {
  cancelLabel?: string,
  confirmLabel?: string,
  message?: string | FormattedMessageType,
  title: string | FormattedMessageType,
  onCancel?: () => Promise<any> | void,
  onConfirm?: () => Promise<any> | void,
  children?: React.Node,
  size?: 'small' | 'medium' | 'large',
};

type State = {
  loading: boolean,
};

export default class ConfirmModal extends React.Component<Props, State> {
  state = {
    loading: false,
  };

  handleConfirm = () => {
    const { onConfirm } = this.props;

    this.setState({ loading: true }, () => {
      if (!onConfirm) {
        return;
      }
      const results: Promise<any> | void = onConfirm();
      const stopLoading = () => this.setState({ loading: false });

      if (results) {
        results.then(stopLoading).catch((error) => {
          // eslint-disable-next-line no-console
          console.error(error);
          stopLoading();
        });
      } else {
        stopLoading();
      }
    });
  };

  handleCancel = () => {
    const { onCancel } = this.props;

    if (onCancel) {
      onCancel();
    }
  };

  renderCancel = () => {
    const { loading } = this.state;
    const { cancelLabel } = this.props;
    return (
      <StandardButton variant="secondary" onClick={this.handleCancel} disabled={loading} fullWidth>
        <FormattedMessage data-testid="confirm-no" id={cancelLabel || 'app.modal.confirm.no'} />
      </StandardButton>
    );
  };

  renderConfirm = () => {
    const { loading } = this.state;
    const { confirmLabel } = this.props;
    return (
      <StandardButton data-testid="confirm-yes" onClick={this.handleConfirm} disabled={loading} fullWidth>
        <FormattedMessage id={confirmLabel || 'app.modal.confirm.yes'} />
      </StandardButton>
    );
  };

  handleStylesModalSize = () => {
    const { size = 'small' } = this.props;

    if (size === 'large') return styles.largeWidth;
    if (size === 'medium') return styles.mediumWidth;

    return styles.smallWidth;
  };

  render = () => {
    const { title, message, cancelLabel, confirmLabel, onCancel, onConfirm, children, ...rest } = this.props;
    const hasChildren = !!children;

    let messageAttributes = {};
    let titleAttributes = {};

    if (message && typeof message !== 'string' && !isString(message) && message.id) {
      messageAttributes = message;
    } else {
      messageAttributes = { id: message };
    }
    if (title && typeof title !== 'string' && !isString(title) && title.id) {
      titleAttributes = title;
    } else {
      titleAttributes = { id: title };
    }

    return (
      <Modal data-testid="confirm-modal" {...rest} onCancel={onCancel}>
        <div className={cx(styles.modal, this.handleStylesModalSize())}>
          <div className={styles.title}>
            <FormattedMessage {...titleAttributes} />
          </div>
          <div className={cx({ [styles.content]: hasChildren || message })}>
            {!hasChildren && message && (
              <p className={styles.message}>
                <FormattedMessage {...messageAttributes} />
              </p>
            )}
            {hasChildren && children}
          </div>
          <div className={styles.buttonContainer}>
            {onConfirm && this.renderConfirm()}
            {onCancel && this.renderCancel()}
          </div>
        </div>
      </Modal>
    );
  };
}
