// @flow

const KILO_BASE = 1000;
const BYTE_UNITS = ['B', 'KB', 'MB'];

export const convertBytesToReadableSize = (bytes: number, i: number = 0) => {
  if (Math.abs(bytes) < KILO_BASE) {
    return `${bytes.toFixed(0)}${BYTE_UNITS[i]}`;
  }
  const res = bytes / KILO_BASE;
  const iterator = i + 1;
  return convertBytesToReadableSize(res, iterator);
};

export const getFileName = (location?: string) => {
  if (!location) return '';

  const matches = /\/\d+\.(?<fileName>.+\.\w+)/.exec(location);
  if (matches && matches.groups) {
    const { groups: { fileName } = {} } = matches;
    return decodeURIComponent(fileName);
  }

  return '';
};

export const getFileExtension = (file: File) => {
  const { name } = file;

  if (!name) return '';

  return name.split('.').slice(-1)[0];
};
