// @flow
import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

const StepOne = (props: Object) => (
  <SvgIcon viewBox="0 0 20 20" {...props}>
    <path
      d="M8 5V7H10V15H12V5H8ZM10 0C11.3132 0 12.6136 0.258658 13.8268 0.761205C15.0401 1.26375 16.1425 2.00035 17.0711 2.92893C17.9997 3.85752 18.7362 4.95991 19.2388 6.17317C19.7413 7.38642 20 8.68678 20 10C20 12.6522 18.9464 15.1957 17.0711 17.0711C15.1957 18.9464 12.6522 20 10 20C8.68678 20 7.38642 19.7413 6.17317 19.2388C4.95991 18.7362 3.85752 17.9997 2.92893 17.0711C1.05357 15.1957 0 12.6522 0 10C0 7.34784 1.05357 4.8043 2.92893 2.92893C4.8043 1.05357 7.34784 0 10 0Z"
      fill="black"
    />
  </SvgIcon>
);

export default StepOne;
