// @flow
import React, { useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { useDropzone } from 'react-dropzone';
import endsWith from 'lodash/endsWith';
import classNames from 'classnames';

import ForwardRedo from '../../Icons/ForwardRedo';
import PdfFileIcon from '../../Icons/PdfFileIcon';
import UploadIcon from '../../Icons/UploadIcon';
import { convertBytesToReadableSize } from '../../../utils/file';

import styles from './styles.module.scss';

type Props = {
  fileName?: string,
  maxFiles: number,
  maxSize: number,
  accept?: string,
  customClassName?: string,
  onChange: (files: Array<File> | null) => void,
};

const FileDropzone = (props: Props) => {
  const { fileName = '', maxFiles = 1, maxSize, onChange, accept, customClassName = '' } = props;
  const [useDefault, setUseDefault] = useState(Boolean(fileName));
  const [containsFiles, setContainsFiles] = useState(Boolean(fileName));
  const { getRootProps, getInputProps, isDragActive, isDragAccept, isDragReject, acceptedFiles } = useDropzone({
    maxFiles,
    maxSize,
    onDrop: onChange,
    onFileDialogCancel: onChange,
    accept,
  });

  const clearFiles = () => {
    acceptedFiles.splice(0, acceptedFiles.length);
    onChange(null);
    setContainsFiles(false);
    setUseDefault(false);
  };

  if (acceptedFiles.length < 1 && useDefault) acceptedFiles.push({ name: fileName, type: 'application/pdf' });

  useEffect(() => setContainsFiles(acceptedFiles.length > 0 && fileName), [acceptedFiles, fileName]);

  return (
    <div>
      {containsFiles ? (
        acceptedFiles.map((file) => (
          <>
            <div key={file.name} className={styles.uploadedFile}>
              <div className={styles.fileDescription}>
                {file.type === 'application/pdf' && <PdfFileIcon />}
                <p>{endsWith(file.name, ['.pdf']) ? file.name.slice(0, file.name.length - 4) : file.name}</p>
              </div>
              {file.size && <div>{convertBytesToReadableSize(file.size)}</div>}
            </div>
            <button type="button" className={styles.fileReupload} onClick={clearFiles}>
              <div className={styles.fileRedo}>
                <ForwardRedo />
                <FormattedMessage id="app.file.dropzone.clear-and-upload-different" />
              </div>
            </button>
          </>
        ))
      ) : (
        <div
          {...getRootProps({
            className: classNames(styles.baseStyle, customClassName, {
              [styles.fileReupload]: containsFiles,
              [styles.fileUpload]: !containsFiles,
              [styles.activeStyle]: isDragActive,
              [styles.acceptStyle]: isDragAccept,
              [styles.rejectStyle]: isDragReject,
            }),
          })}
        >
          <input {...getInputProps()} />
          <UploadIcon className={styles.uploadIcon} />
          <div className={styles.uploadDescription}>
            <FormattedMessage
              tagName="p"
              id={maxFiles > 1 ? 'app.file.dropzone.default-text' : 'app.file.dropzone.default-text-singular'}
            />
            <FormattedMessage
              tagName="p"
              id="app.file.dropzone.maximum-upload-file-size"
              values={{ size: convertBytesToReadableSize(maxSize) }}
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default FileDropzone;
