// @flow

import React from 'react';
import classNames from 'classnames';
import type { Element } from 'react';
import Modal from '@material-ui/core/Modal';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import { injectIntl } from 'react-intl';

import Close from '../../Icons/Close';

import styles from './styles.module.scss';

type TabType = {
  label: string,
  href: string,
};

type Props = {
  open: boolean,
  title: string,
  tabs?: Array<TabType>,
  tab: number,
  intl: { formatMessage: ({ id: string }) => string },
  children: Element<any>,
  onTabChange: (event: {}, value: number) => void,
  onClose?: () => void,
  sm?: boolean,
  md?: boolean,
  lg?: boolean,
  customClassName?: string,
};

export function FormModal(props: Props) {
  const {
    open,
    title,
    tabs,
    tab,
    children,
    onTabChange,
    onClose,
    sm,
    md,
    lg,
    intl: { formatMessage },
    customClassName,
    ...others
  } = props;

  return (
    <Modal open={open} BackdropProps={{ classes: { root: styles.backdropRoot } }} disableBackdropClick {...others}>
      <div
        className={classNames(
          styles.modalPaper,
          {
            [styles.modalPaperSmall]: sm,
            [styles.modalPaperMedium]: md,
            [styles.modalPaperLarge]: lg,
          },
          customClassName,
        )}
      >
        <div className={styles.modalHeader}>
          <h2 className={styles.modalTitle}>{title}</h2>
          {tabs && (
            <Tabs value={tab} indicatorColor="primary" textColor="primary" onChange={onTabChange}>
              {tabs.map(({ label, href }) => (
                <Tab
                  label={formatMessage({ id: label })}
                  classes={{
                    root: styles.tabRoot,
                    label: styles.tabLabel,
                    labelContainer: styles.tabLabelContainer,
                    textColorPrimary: styles.tabTextColorPrimary,
                    textColorPrimarySelected: styles.tabTextColorPrimarySelected,
                  }}
                  href={href}
                  key={label}
                  disableRipple
                />
              ))}
            </Tabs>
          )}
          {onClose && <Close onClick={onClose} />}
        </div>
        {children}
      </div>
    </Modal>
  );
}

FormModal.defaultProps = { sm: false };

export default injectIntl(FormModal);
