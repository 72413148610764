// @flow

export const addClass = (el: { className: string }, className: string) => {
  const classNames = el.className.trim().split(' ');
  const newClassName = className.trim();
  if (classNames.indexOf(newClassName) < 0) {
    classNames.push(newClassName);
    el.className = classNames.map((s) => s.trim()).join(' '); /* eslint no-param-reassign: 0 */
  }
};
