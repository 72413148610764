// @flow

import React from 'react';
import Grid from '@material-ui/core/Grid';
import { FormattedMessage } from 'react-intl';

import styles from './styles.module.scss';

type Patient = {
  uuid: string,
  firstName: string,
  lastName: string,
  entity: {
    id: number,
  },
};

type Props = {
  labelId: string,
  patients: Patient[],
};

const AssignmentList = ({ labelId, patients = [] }: Props) => {
  if (!patients.length) return null;

  return (
    <div>
      <Grid container>
        <Grid item xs={12} className={styles.assignmentHeader}>
          <FormattedMessage tagName="strong" id={labelId} />
        </Grid>
        {patients.map(({ firstName, lastName, uuid: patientUuid }) => (
          <Grid item xs={6} key={patientUuid} className={styles.assignment}>
            <p>
              {firstName} {lastName}
            </p>
          </Grid>
        ))}
      </Grid>
    </div>
  );
};

export default AssignmentList;
