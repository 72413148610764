// @flow

import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

function DoseOmit(props: Object) {
  return (
    <SvgIcon viewBox="0 0 22 22" {...props}>
      <path
        fill="#8f9297"
        d="M11,0.2C5,0.2,0.2,5,0.2,11S5,21.8,11,21.8S21.8,17,21.8,11S17,0.2,11,0.2z M11,2.6c1.8,0,3.5,0.6,4.8,1.5L4.1,15.8c-1-1.4-1.5-3-1.5-4.8C2.6,6.4,6.4,2.6,11,2.6z M11,19.4c-1.8,0-3.5-0.6-4.8-1.5L17.9,6.2c1,1.4,1.5,3,1.5,4.8C19.4,15.6,15.6,19.4,11,19.4z"
      />
    </SvgIcon>
  );
}

export default DoseOmit;
