// @flow

import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

function DoseNo(props: Object) {
  return (
    <SvgIcon viewBox="0 0 22 22" {...props}>
      <g fill="none" fillRule="evenodd">
        <circle cx="11" cy="11" r="11" fill="#FF1744" />
        <polygon
          fill="#FFF"
          points="16.923 7.247 15.599 5.923 11.423 10.099 7.247 5.923 5.923 7.247 10.099 11.423 5.923 15.599 7.247 16.923 11.423 12.747 15.599 16.923 16.923 15.599 12.747 11.423"
        />
      </g>
    </SvgIcon>
  );
}

export default DoseNo;
