// @flow

import React from 'react';

import styles from './styles.module.scss';

type Props = {
  text: string,
  required: boolean,
};

const Label = (props: Props) => {
  const { text, required } = props;
  return (
    <div className={styles.labelContainer}>
      <div className={styles.label}>{text}</div>
      {required && <div className={styles.asterisk}>*</div>}
    </div>
  );
};

export default Label;
