// @flow

import first from 'lodash/first';

type GraphQLError = {
  path: Array<string>,
  extensions: {
    code?: number,
    stack?: Array<string>,
    state?: { _error: string | Array<string> },
  },
};

type NetworkError = {
  stack: Array<string>,
};

type ApolloLinkError = {
  graphQLErrors: Array<GraphQLError>,
  networkError: NetworkError,
  operation: Object,
  response: Object,
};

const getErrors = (params: ApolloLinkError) => {
  const {
    graphQLErrors,
    networkError,
    response,
    operation: { operationName, getContext },
  } = params;

  const xAmznRequestId = getContext().response && getContext().response.headers.get('x-amzn-requestid');
  const errors = [];

  if (networkError) {
    const { stack } = networkError;
    errors.push({
      status: 500,
      message: { id: 'app.error.network' },
      error: { stack: [stack], path: operationName, 'x-amzn-requestid': xAmznRequestId },
    });
    return errors;
  }

  return graphQLErrors.reduce(
    (memo, { path: pathSrc, extensions: { code: status, stack, state: { _error } = {} } }) => {
      if (status === 401 && !_error) {
        memo.push({ status, message: { id: 'app.login.signed.out' } });
      } else if (status === 403) {
        memo.push({ status, message: { id: 'app.errors.forbidden' } });
      } else if (!status || status === 500 || !response) {
        memo.push({
          status: status || 500,
          message: { id: 'app.errors.unexpected' },
          error: { path: pathSrc.join('/'), stack, 'x-amzn-requestid': xAmznRequestId },
        });
      } else if (_error && _error.length) {
        memo.push({ status, message: Array.isArray(_error) ? first(_error) : _error });
      }

      return memo;
    },
    [],
  );
};

export default getErrors;
