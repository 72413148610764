// @flow
import React, { useRef, useEffect } from 'react';
import { useIntl } from 'react-intl';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import classNames from 'classnames';

import Dropdown from '../../../../FormControls/Dropdown';
import VideoIcon from '../../../../Icons/Video';
import EditVideoMenu from '../EditVideoMenu';
import type { Option } from '../../../../FormControls/Dropdown';

import styles from './styles.module.scss';

type Props = {
  userProfileQuestionId: number,
  file?: File,
  location?: string,
  options: Array<Option>,
  disabled?: boolean,
  onSelect: (userProfileQuestionId?: number) => void,
  onDelete: () => void,
  onVideoModalOpen: (userProfileQuestionId: number, mode: string) => void,
};

const UserAnswerVideo = ({
  userProfileQuestionId,
  file,
  location,
  options,
  disabled,
  onSelect,
  onDelete,
  onVideoModalOpen,
}: Props) => {
  const { formatMessage } = useIntl();
  const videoRef = useRef();

  useEffect(() => {
    if (!file) return;
    const video = videoRef.current;
    const source = video?.children[0];

    if (video && source) {
      source.setAttribute('src', URL.createObjectURL(file));
      video.load();
    }
  }, [file]);

  const source = file ? URL.createObjectURL(file) : location;

  return (
    <>
      <Box mt={4}>
        <Dropdown
          input={{
            name: 'userProfileQuestion',
            value: userProfileQuestionId,
            onChange: onSelect,
          }}
          label={formatMessage({ id: 'app.user-profile.question' })}
          options={options}
          disabled={disabled}
          sortOptions={false}
        />

        <Grid className={styles.grid} spacing={6} container>
          <Grid xs={3} item />
          <Grid className={styles.videoContainer} xs={6} item>
            {source ? (
              // eslint-disable-next-line jsx-a11y/media-has-caption
              <video
                ref={videoRef}
                className={styles.video}
                controls
                disablePictureInPicture
                controlsList="nodownload noplaybackrate"
              >
                <source src={source} />
              </video>
            ) : (
              <Box
                display="flex"
                justifyContent="center"
                alignItems="center"
                className={classNames(styles.video, styles.videoPlaceholder)}
              >
                <VideoIcon />
              </Box>
            )}
          </Grid>
          <Grid className={styles.videoActions} xs={3} item>
            <EditVideoMenu
              onRecord={() => onVideoModalOpen(userProfileQuestionId, 'record')}
              onUpload={() => onVideoModalOpen(userProfileQuestionId, 'upload')}
              onDelete={onDelete}
            />
          </Grid>
        </Grid>
      </Box>
    </>
  );
};

export default UserAnswerVideo;
