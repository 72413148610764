// @flow
/* global pendo */

import { connect } from 'react-redux';

import { authUserSelector } from '../SignIn/selectors';

type Props = {
  currentUser: {
    firstName: string,
    id: Number,
    firstName: string,
    lastName: string,
    email: string,
    uuid: string,
    entities: Array<{
      name: string,
      id: number,
      timezone: string,
      chatEnabled: boolean,
      uuid: string,
      incentivesEnabled: boolean,
      thirdPartyAnalyticsEnabled: boolean,
      conditions: Array<{ id: Number, name: string }>,
    }>,
    role: { name: string },
  },
  type: string,
  currentPatient: {
    patientId: Number,
    patientEntityId: Number,
  },
};

export const Pendo = ({ currentUser, currentPatient = {}, type }: Props) => {
  let visitor;
  let account;

  try {
    /* eslint-disable */
    (function(apiKey) {
      (function(p, e, n, d, o) {
        let v;
        let w;
        let x;
        let y;
        let z;
        o = p[d] = p[d] || {};
        o._q = [];
        v = ['initialize', 'identify', 'updateOptions', 'pageLoad'];
        for (w = 0, x = v.length; w < x; ++w)
          (function(m) {
            // $FlowFixMe
            o[m] =
              // $FlowFixMe
              o[m] ||
              function() {
                // $FlowFixMe
                o._q[m === v[0] ? 'unshift' : 'push']([m].concat([].slice.call(arguments, 0)));
              };
          })(v[w]);
        y = e.createElement(n);
        y.async = !0;
        y.src = `https://cdn.pendo.io/agent/static/${apiKey}/pendo.js`;
        z = e.getElementsByTagName(n)[0];
        // $FlowFixMe
        z.parentNode.insertBefore(y, z);
      })(window, document, 'script', 'pendo');
    })('578cb52c-2c8f-4e8e-49ad-991602e754df');
    /* eslint-enable */
    // $FlowFixMe

    if (type === 'user') {
      const {
        uuid,
        email,
        firstName,
        id,
        lastName,
        entities,
        role: { name: role },
      } = currentUser;
      const [
        {
          name: entityName,
          id: entityId,
          timezone,
          chatEnabled,
          uuid: entityUuid,
          incentivesEnabled,
          thirdPartyAnalyticsEnabled,
        },
      ] = entities;
      visitor = {
        id: `user-${String(id)}`,
        uuid,
        email,
        full_name: `${firstName} ${lastName}`,
        role,
        type: 'Care Team Member',
      };
      account = {
        id: entityId,
        name: entityName,
        timezone,
        uuid: entityUuid,
        incentivesEnabled,
        chatEnabled,
        thirdPartyAnalyticsEnabled,
      };
      // $FlowFixMe
      pendo.initialize({
        visitor,
        account,
      });
      entities
        .slice(1)
        .forEach(
          ({
            id: eId,
            name: eName,
            timezone: eTimezone,
            uuid: eUuid,
            incentivesEnabled: eIncentivesEnabled,
            chatEnabled: eChatEnabled,
            thirdPartyAnalyticsEnabled: eThirdPartyAnalyticsEnabled,
            conditions = [],
          }) => {
            pendo.identify({
              visitor: {
                id: `${type}-${String(id)}`,
                uuid,
                email,
                full_name: `${firstName} ${lastName}`,
                role,
                conditions: conditions.map((c) => c.name).join(', '),
              },
              account: {
                id: eId,
                name: eName,
                timezone: eTimezone,
                uuid: eUuid,
                incentivesEnabled: eIncentivesEnabled,
                chatEnabled: eChatEnabled,
                sisenseEnabled: eThirdPartyAnalyticsEnabled,
              },
            });
          },
        );
    } else {
      const { patientId, patientEntityId } = currentPatient;
      visitor = {
        id: `${type}-${String(patientId)}`,
      };
      account = {
        id: patientEntityId,
      };

      // $FlowFixMe
      pendo.initialize({
        visitor,
        account,
      });
      pendo.identify({
        visitor,
        account,
      });
    }
  } catch (e) {
    // eslint-disable-next-line no-console
    console.log('Pendo failed to initialize', e);
  }

  return null;
};

export const mapStateToProps = (state: Map<string, any>) => ({
  currentUser: authUserSelector(state),
});

export default connect(mapStateToProps)(Pendo);
