// @flow
import React from 'react';
import styles from './styles.module.scss';

type Props = {
  text?: string,
  matchedText: string,
  caseInsensitive: boolean,
};

export default function TextHighlight({ text, matchedText, caseInsensitive = true }: Props) {
  if (!text) return '';

  if (matchedText === '') return text;

  const escapedMatch = matchedText
    .replace(/[.*+?^${}()|[\]\\]/g, '\\$&')
    .trim()
    .replace(/ /g, '|');
  const regexFlags = `g${caseInsensitive ? 'i' : ''}`;
  const regexWithCaptureGroup = new RegExp(`(${escapedMatch})`, regexFlags);
  const textSplitThatIncludesDelimiter = text.split(regexWithCaptureGroup);
  return (
    <div className={styles.textHighlight}>
      {textSplitThatIncludesDelimiter.map((subString, index) => {
        const key = `highlight-${subString}-${index}`;
        return subString.match(regexWithCaptureGroup) ? (
          <mark key={key}>{subString}</mark>
        ) : (
          <React.Fragment key={key}>{subString}</React.Fragment>
        );
      })}
    </div>
  );
}
