// @flow
import React, { createContext, useState, type Node } from 'react';

export type Props = {
  children: Node,
};

export const LiveVideoPatientContext = createContext<{
  patientUuid: string | null,
  setPatientUuid: (string | null) => void,
}>({
  patientUuid: null,
  setPatientUuid: () => {},
});

const LiveVideoProvider = (props: Props) => {
  const { children } = props;
  const [patientUuid, setPatientUuid] = useState(null);

  return (
    <LiveVideoPatientContext.Provider value={{ patientUuid, setPatientUuid }}>
      {children}
    </LiveVideoPatientContext.Provider>
  );
};

export default LiveVideoProvider;
