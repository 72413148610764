// @flow

import React from 'react';
import { Link } from 'react-router-dom';
import { FormattedMessage, useIntl } from 'react-intl';
import classNames from 'classnames';

import StandardButton from '../../../../components/Buttons/Standard';
import Separator from '../../../../components/Separator';
import PhoneForm from './PhoneForm';
import usePath from '../../usePath';
import { SCENARIOS, PATHS } from '../../constants';

import styles from './styles.module.scss';

type Props = {
  scenario: string,
  formattedPhoneNumber: string,
};

const PageButtons = ({ scenario, formattedPhoneNumber }: Props) => {
  const getPath = usePath();
  const { formatMessage } = useIntl();

  return (
    <div className={styles.buttonsContainer}>
      {(scenario === SCENARIOS.NOT_FOUND || scenario === SCENARIOS.UNEXPECTED_ERROR) && (
        <div
          className={classNames(
            styles.tryAgainButton,
            scenario === SCENARIOS.UNEXPECTED_ERROR && styles.unexpectedError,
          )}
        >
          <Link to={getPath(PATHS.IDENTIFY)}>
            <StandardButton
              classes={{ outlined: styles.customButtonStyle }}
              isPill
              extraRootClasses={styles.button}
              variant={scenario === SCENARIOS.NOT_FOUND ? 'primary' : 'secondary'}
            >
              <FormattedMessage id="app.self-enroll.try-again" />
            </StandardButton>
          </Link>
        </div>
      )}

      {scenario !== SCENARIOS.NOT_FOUND && (
        <a href={`tel:${formattedPhoneNumber}`} className={styles.callMobile}>
          <StandardButton
            classes={{ outlined: styles.customButtonStyle }}
            isPill
            extraRootClasses={styles.button}
            variant="primary"
          >
            <FormattedMessage id="app.self-enroll.call-support" />
          </StandardButton>
        </a>
      )}

      {scenario === SCENARIOS.NOT_FOUND && (
        <div className={styles.phoneForm}>
          <Separator text={formatMessage({ id: 'app.self-enroll.notify.not-found.or' })} />
          <PhoneForm />
        </div>
      )}
    </div>
  );
};

export default PageButtons;
