// @flow
import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

const StepThree = (props: Object) => (
  <SvgIcon viewBox="0 0 20 20" {...props}>
    <path
      d="M13 13V11.5C13 11.1022 12.842 10.7206 12.5607 10.4393C12.2794 10.158 11.8978 10 11.5 10C11.8978 10 12.2794 9.84196 12.5607 9.56066C12.842 9.27936 13 8.89782 13 8.5V7C13 5.89 12.1 5 11 5H7V7H11V9H9V11H11V13H7V15H11C11.5304 15 12.0391 14.7893 12.4142 14.4142C12.7893 14.0391 13 13.5304 13 13ZM10 0C11.3132 0 12.6136 0.258658 13.8268 0.761205C15.0401 1.26375 16.1425 2.00035 17.0711 2.92893C17.9997 3.85752 18.7362 4.95991 19.2388 6.17317C19.7413 7.38642 20 8.68678 20 10C20 12.6522 18.9464 15.1957 17.0711 17.0711C15.1957 18.9464 12.6522 20 10 20C8.68678 20 7.38642 19.7413 6.17317 19.2388C4.95991 18.7362 3.85752 17.9997 2.92893 17.0711C1.05357 15.1957 0 12.6522 0 10C0 7.34784 1.05357 4.8043 2.92893 2.92893C4.8043 1.05357 7.34784 0 10 0Z"
      fill="black"
    />
  </SvgIcon>
);

export default StepThree;
