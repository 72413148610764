import React, { Component } from 'react';
import PropTypes from 'prop-types';

import styles from './styles.module.scss';

export default class Countdown extends Component {
  static propTypes = {
    countdownTime: PropTypes.number,
  };

  constructor(props) {
    super(props);

    this.state = {
      number: props.countdownTime / 1000,
    };
  }

  componentDidMount() {
    this.timeout = setTimeout(this.updateNumber, 1000);
  }

  componentWillUnmount() {
    clearInterval(this.timeout);
  }

  updateNumber = () => {
    const nextNumber = this.state.number - 1;
    this.setState((prev) => ({
      number: prev.number - 1,
    }));
    if (nextNumber !== 0) {
      this.timeout = setTimeout(this.updateNumber, 1000);
    }
  };

  render() {
    return <div className={styles.root}>{this.state.number !== 0 ? this.state.number : null}</div>;
  }
}
