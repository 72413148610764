/**
 * Create the store with dynamic reducers
 */

import { createStore, applyMiddleware, compose } from 'redux';
import createHistory from 'history/createBrowserHistory';
import { fromJS } from 'immutable';
import createSagaMiddleware from 'redux-saga';
import createReducer from './reducers';

let store;
let history;

function configureStore(initialState) {
  const sagaMiddleware = createSagaMiddleware();
  // Create the store with two middlewares
  // 1. sagaMiddleware: Makes redux-sagas work
  const middlewares = [sagaMiddleware];

  const enhancers = [applyMiddleware(...middlewares)];

  // If Redux DevTools Extension is installed use it, otherwise use Redux compose
  // TODO Try to remove when `react-router-redux` is out of beta, LOCATION_CHANGE should not be fired more than once after hot reloading
  // Prevent recomputing reducers for `replaceReducer`
  const composeEnhancers =
    process.env.NODE_ENV !== 'production' && typeof window === 'object' && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
      ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({
          shouldHotReload: false,
        })
      : compose;

  const myStore = createStore(createReducer(), fromJS(initialState), composeEnhancers(...enhancers));

  // Extensions
  myStore.runSaga = sagaMiddleware.run;
  myStore.injectedReducers = {}; // Reducer registry
  myStore.injectedSagas = {}; // Saga registry

  // Make reducers hot reloadable, see http://mxs.is/googmo
  /* istanbul ignore next */
  if (module.hot) {
    module.hot.accept('./reducers', () => {
      myStore.replaceReducer(createReducer(myStore.injectedReducers));
    });
  }

  return myStore;
}
const getHistory = () => {
  if (!history) {
    history = createHistory();
  }

  return history;
};

const getStore = () => {
  if (!store) {
    store = configureStore({}, getHistory());
  }

  return store;
};

export { configureStore, getHistory, getStore };
