// @flow

import React, { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import Switch from '@material-ui/core/Switch';

import styles from './styles.module.scss';

type Props = {
  onChange: (e: Object) => void,
  value: boolean,
  disabled?: boolean,
};

export default function SwitchWithInsideText(props: Props) {
  const { onChange, disabled = false } = props;
  const { formatMessage } = useIntl();

  const [value, setValue] = useState(props.value);

  const switchProps = {
    label: '',
    input: {
      name: 'switchWithText',
      value,
      onChange,
    },
    meta: {
      touched: false,
      error: '',
    },
    disabled,
  };

  useEffect(() => setValue(props.value), [props.value]);

  return (
    <Switch
      intl={{ formatMessage }}
      disableRipple
      checked={Boolean(value)}
      onChange={(e) => {
        const { checked } = e.target;
        setValue(checked);
        onChange(checked);
      }}
      classes={{
        root: styles.switch,
        thumb: styles.switchSwitch,
        track: styles.switchTrack,
        switchBase: styles.switchBase,
        checked: styles.checked,
      }}
      {...switchProps}
    />
  );
}
