// @flow
import React, { type Node } from 'react';
import Tooltip from '@material-ui/core/Tooltip';
import InfoIcon from '@material-ui/icons/Info';
import { FormattedMessage } from 'react-intl';
import classNames from 'classnames';
import styles from './styles.module.scss';

type Props = {
  text?: string | Node,
  values?: Object,
  size?: number,
  placement?: string,
  children?: any,
  hidden?: boolean,
  extraPopperClasses?: string,
  extraTooltipClasses?: string,
  open?: boolean,
  disableFocusListener?: boolean,
  disableHoverListener?: boolean,
  disableTouchListener?: boolean,
};

export default function CustomizedTooltip({
  text,
  values = {},
  size = 19,
  placement = 'top',
  children,
  hidden = false,
  extraPopperClasses = '',
  extraTooltipClasses = '',
  ...rest
}: Props) {
  if (!text) return children || <InfoIcon className={styles.infoIcon} style={{ height: size, width: size }} />;
  return (
    <Tooltip
      placement={placement}
      classes={{
        popper: extraPopperClasses,
        tooltip: classNames(styles.tooltip, { [styles.hidden]: hidden }, extraTooltipClasses),
      }}
      title={
        <>
          {typeof text === 'string' && <FormattedMessage id={text} values={values} />}
          {typeof text !== 'string' && text}
        </>
      }
      {...rest}
    >
      {children || <InfoIcon className={styles.infoIcon} style={{ height: size, width: size }} />}
    </Tooltip>
  );
}
