// @flow

import { Map } from 'immutable';
import { reduce, findIndex } from 'lodash';

import { SELECTED_SUBMISSION, SET_SUBMISSION_NOTES } from './constants';

type State = Map<string, any>;
type Action = { type: string, payload: any };

const initialState = Map({ submissionDetails: undefined });

function patientSubmissionsReducer(state: State = initialState, action: Action) {
  switch (action.type) {
    case SELECTED_SUBMISSION:
      return state.set('submissionDetails', action.payload);
    case SET_SUBMISSION_NOTES: {
      const submissionDetails = state.get('submissionDetails');

      if (!submissionDetails || !submissionDetails.submissions) return state;

      const { submissions } = submissionDetails;
      const {
        payload: { notesGroupedBySubmission },
      } = action;

      const newSubmissionsData = reduce(
        notesGroupedBySubmission,
        (acc, notes, submissionId) => {
          const submissionIdx = findIndex(submissions, ({ id }) => id.toString() === submissionId);

          if (submissionIdx === -1) return acc;

          const submission = submissions[submissionIdx];
          const { notes: submissionNotes } = submission;
          const submissionNoteIds = submissionNotes.map(({ id }) => id);
          const newSubmissionNotes = notes.filter(({ id }) => !submissionNoteIds.includes(id));

          return [
            ...acc.slice(0, submissionIdx),
            { ...submission, notes: [...submission.notes, ...newSubmissionNotes] },
            ...acc.slice(submissionIdx + 1),
          ];
        },
        submissions,
      );

      return state.set('submissionDetails', { ...submissionDetails, submissions: newSubmissionsData });
    }
    default:
      return state;
  }
}

export default patientSubmissionsReducer;
