import gql from 'graphql-tag';

export const CREATE_TOKEN_WITH_TWO_FACTOR_AUTH = gql(`
  mutation createTokenWith2FactorAuth {
    createTokenWith2FactorAuth {
      qrCode
    }
  }
`);

export const CONFIRM_CODE_AND_TURN_ON_TWO_FACTOR_CODE = gql(`
  mutation confirmCodeAndTurnOn2Factor($code: String!) {
    confirmCodeAndTurnOn2Factor(code: $code) {
      id
      twoFactorEnabled
    }
  }
`);

export const TURN_OFF_TWO_FACTOR_AUTH = gql`
  mutation turnOffTwoFactorAuth {
    turnOffTwoFactorAuth {
      id
      twoFactorEnabled
    }
  }
`;
