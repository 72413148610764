// @flow

import React from 'react';
import { useMutation } from 'react-apollo';
import { useHistory } from 'react-router-dom';

import Form from './Form';
import PageHeader from '../PageHeader';
import LoadingIndicator from '../LoadingIndicator';
import useSelfEnrollment from '../../../hooks/useSelfEnrollment';
import usePath from '../usePath';
import useScrollToTop from '../useScrollToTop';

import { MESSAGES, PATHS } from '../constants';
import { VALIDATE_SELF_ENROLLMENT_PATIENT_IDENTITY } from '../mutations';

import styles from './styles.module.scss';

const IdentifyPatient = () => {
  useScrollToTop();
  const getPath = usePath();
  const history = useHistory();

  const [validateSelfEnrollment, { loading }] = useMutation(VALIDATE_SELF_ENROLLMENT_PATIENT_IDENTITY);
  const { setPatientData, setMessage } = useSelfEnrollment();

  const handleOnSubmit = async (input) => {
    const result = await validateSelfEnrollment({ variables: { input } });

    const { data: { validateSelfEnrollmentPatientIdentity = {} } = {} } = result || {};
    const { message = '', patientInformation: patientData = null } = validateSelfEnrollmentPatientIdentity;
    setPatientData(patientData);
    setMessage(message);

    if (message === MESSAGES.PATIENT_FOUND || message === MESSAGES.ACCOUNT_PENDING_TO_BE_COMPLETED) {
      history.push(getPath(PATHS.FOUND));
    } else if (message.length) {
      history.push(getPath(PATHS.NOTIFY));
    }
  };

  return (
    <div className={styles.container}>
      <PageHeader headerId="app.self-enroll.identify.header" subHeaderId="app.self-enroll.identify.sub-header" />
      {!loading ? <Form onSubmit={handleOnSubmit} loading={loading} /> : <LoadingIndicator />}
    </div>
  );
};

export default IdentifyPatient;
