// @flow

import React, { Suspense } from 'react';

import LoadingIndicator from '../../../components/LoadingIndicator';

const CognitoSignIn = React.lazy(() => import('./index'));

const SignInLoadable = () => (
  <div>
    <Suspense fallback={<LoadingIndicator />}>
      <CognitoSignIn />
    </Suspense>
  </div>
);

export default SignInLoadable;
