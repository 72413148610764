// @flow

import React, { useEffect } from 'react';
import { useIntl, FormattedMessage } from 'react-intl';
import classnames from 'classnames';
import { useMutation } from '@apollo/react-hooks';
import { useHistory } from 'react-router-dom';
import { isMobile } from 'react-device-detect';
import startsWith from 'lodash/startsWith';
import { Controller, useForm } from 'react-hook-form';
import { isValidNumber, parseNumber } from 'libphonenumber-js';

import useContactNumber from '../../useContactNumber';
import usePath from '../../usePath';
import useSelfEnrollment from '../../../../hooks/useSelfEnrollment';
import StandardButton from '../../../../components/Buttons/Standard';
import PhonePrefixInput from './PhonePrefixInput';

import { SELF_ENROLLMENT } from '../../mutations';
import { MESSAGES, PATHS } from '../../constants';

import styles from './styles.module.scss';

const PhoneForm = () => {
  const getPath = usePath();
  const { formatMessage } = useIntl();

  const contactNumber = useContactNumber();
  const history = useHistory();

  const { formData, setMessage, setSelfEnrollmentResponse, patientPhoneNumber } = useSelfEnrollment();

  const [selfEnrollment, { loading }] = useMutation(SELF_ENROLLMENT);

  const {
    handleSubmit,
    control,
    errors,
    formState: { isValid },
  } = useForm({
    mode: 'onChange',
    reValidateMode: 'onChange',
    defaultValues: { phone: patientPhoneNumber },
  });

  const handleSendSms = async ({ phone }) => {
    const response = await selfEnrollment({
      variables: {
        input: {
          ...formData,
          phoneNumber: !startsWith(phone, '+') ? `+${phone}` : phone,
        },
      },
    });

    const selfEnrollmentResponse = response?.data?.selfEnrollPatient || null;
    setSelfEnrollmentResponse(selfEnrollmentResponse);

    if (selfEnrollmentResponse?.success) {
      setMessage(MESSAGES.THANK_YOU);
      history.push(getPath(PATHS.ENROLLED));
    } else {
      history.push(getPath(PATHS.IDENTIFY));
    }
  };

  const submitOnEnter = (event) => {
    if (event.key === 'Enter' && isValid && !loading) {
      handleSendSms({ phone: patientPhoneNumber });
      event.preventDefault();
      history.push(getPath('identify'));
    }
  };

  useEffect(() => {
    window.addEventListener('keydown', submitOnEnter);
    return () => {
      window.removeEventListener('keydown', submitOnEnter);
    };
  });

  return (
    <form className={styles.phoneFormContainer}>
      <Controller
        name="phone"
        control={control}
        rules={{
          validate: (phoneValue) => {
            const parsePhoneNumber = parseNumber(`+${phoneValue}`);
            const { country } = parsePhoneNumber;
            const args = [phoneValue.startsWith('+') ? phoneValue : `+${phoneValue}`, country];
            return isValidNumber(...args);
          },
        }}
        render={({ name, value, onChange }) => (
          <PhonePrefixInput
            country="us"
            input={{ name, value, onChange }}
            placeholder={formatMessage({ id: 'app.forms.phone.enter.mobile.number' })}
            meta={{ touched: !!errors[name], error: patientPhoneNumber && errors[name] }}
          />
        )}
        data-testid="manage-user-phone"
      />
      <p className={styles.contactContainer}>
        <span className={styles.contactUsMessage}>
          <FormattedMessage id="app.self-enroll.found.contact-us-message" />
        </span>
        <span className={styles.contactNumber}>
          {isMobile ? (
            <a href={`tel:${contactNumber}`}>
              <FormattedMessage id="app.self-enroll.found.contact-us-link" />
            </a>
          ) : (
            <span>Call {contactNumber}</span>
          )}
        </span>
      </p>
      <div className={styles.disclaimer}>
        <FormattedMessage id="app.self-enroll.found.text-rate-disclaimer" />
      </div>

      <StandardButton
        disabled={!isValid || loading}
        extraRootClasses={classnames(styles.button, {
          [styles.buttonDisabled]: !isValid || loading,
        })}
        isPill
        variant="primary"
        onClick={handleSubmit(handleSendSms)}
      >
        {loading ? (
          <FormattedMessage id="app.self-enroll.found.sending-text" />
        ) : (
          <FormattedMessage id="app.self-enroll.found.send-text" />
        )}
      </StandardButton>
    </form>
  );
};

export default PhoneForm;
