// @flow

import React from 'react';

const SceneIcon = (props: {}) => (
  <svg width="118" height="39" viewBox="0 0 118 39" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <g clipPath="url(#clip0_921_21766)">
      <path
        d="M0.176981 38.2672C0.0589939 38.1491 0 37.9957 0 37.7832V0.660999C0 0.460339 0.0589939 0.295089 0.176981 0.177053C0.294969 0.0590174 0.448352 0 0.660728 0H7.90517C8.34168 0 8.55409 0.20066 8.55409 0.613784C8.55409 0.814444 8.49506 0.979694 8.37712 1.09773C8.25912 1.21577 8.10573 1.27478 7.89338 1.27478H3.62221V37.1694H7.90517C8.10573 37.1694 8.27091 37.2284 8.38891 37.3465C8.50691 37.4645 8.56588 37.618 8.56588 37.8304C8.56588 38.2436 8.35353 38.4442 7.91697 38.4442H0.660728C0.448352 38.4442 0.294969 38.3852 0.176981 38.2672Z"
        fill="#171717"
      />
      <path
        d="M15.7512 32.5543C14.1348 32.153 12.8134 31.6691 11.8104 31.0907C11.5037 30.9136 11.3385 30.6421 11.3385 30.2762L11.5273 23.5128C11.5273 23.0407 11.7633 22.8164 12.2234 22.8164C12.6364 22.8164 12.8841 23.0288 12.9667 23.442C14.3118 28.8834 17.0137 31.61 21.0488 31.61C22.6063 31.61 23.857 31.1733 24.8009 30.288C25.7448 29.4027 26.2167 28.2578 26.2167 26.8296C26.2167 25.8735 25.9808 25.0709 25.497 24.4216C25.0132 23.7725 24.3643 23.2177 23.5502 22.7691C22.7361 22.3206 21.6506 21.8131 20.3056 21.2701L18.5593 20.6091C16.117 19.653 14.3354 18.5789 13.1909 17.3749C12.0464 16.1828 11.4801 14.6837 11.4801 12.8659C11.4801 11.3079 11.9049 9.91505 12.7425 8.68748C13.5803 7.45991 14.7365 6.49206 16.1996 5.80742C17.6626 5.12282 19.2791 4.78052 21.037 4.78052C22.4647 4.78052 23.7508 4.89855 24.9071 5.14643C26.0633 5.3943 27.2314 5.71297 28.4231 6.10254C28.7299 6.20873 28.895 6.45664 28.895 6.84616L28.777 12.6417C28.777 13.1139 28.541 13.3381 28.0809 13.3381C27.668 13.3381 27.4084 13.1257 27.3022 12.7125C26.2875 8.42783 24.1047 6.29137 20.7303 6.29137C19.3616 6.29137 18.2172 6.69269 17.3323 7.48352C16.4355 8.27436 15.9872 9.26588 15.9872 10.458C15.9872 11.6738 16.4002 12.6417 17.226 13.3617C18.052 14.0699 19.4678 14.8018 21.4618 15.5572L23.326 16.2536C25.969 17.2687 27.8685 18.39 29.0248 19.6176C30.1811 20.8452 30.7592 22.4386 30.7592 24.3862C30.7592 26.0505 30.3345 27.5496 29.4731 28.8834C28.6237 30.2172 27.4202 31.2677 25.8981 32.035C24.3643 32.8022 22.6299 33.1799 20.6831 33.1799C19.0195 33.1563 17.3676 32.9556 15.7512 32.5543Z"
        fill="#171717"
      />
      <path
        d="M36.6705 31.787C35.3137 30.9608 34.2754 29.8159 33.5556 28.364C32.8477 26.9122 32.4819 25.2833 32.4819 23.4655C32.4819 21.4943 32.9067 19.712 33.7444 18.1185C34.5821 16.525 35.7266 15.2739 37.1661 14.3886C38.6055 13.4915 40.1629 13.043 41.8501 13.043C43.8441 13.043 45.4134 13.4207 46.5578 14.188C47.7023 14.9552 48.481 15.8168 48.894 16.7729L48.9766 17.0798C48.9766 17.2332 48.9058 17.3985 48.7878 17.5874C48.481 18.0831 48.0917 18.4608 47.6197 18.7205C47.1478 18.9802 46.664 19.11 46.1449 19.11C45.5786 19.11 45.1066 18.9684 44.729 18.6969C44.3515 18.4254 44.0211 17.9179 43.738 17.1625C43.325 16.1473 42.9592 15.4509 42.6288 15.0614C42.2985 14.6719 41.8029 14.483 41.1304 14.483C40.5287 14.483 39.9623 14.7191 39.396 15.1794C38.8415 15.6516 38.3813 16.3834 38.0391 17.3985C37.6852 18.4136 37.52 19.6884 37.52 21.2465C37.52 23.7134 38.0746 25.6138 39.1954 26.9594C40.3045 28.305 41.7321 28.9778 43.4784 28.9778C44.493 28.9778 45.3544 28.7654 46.0623 28.3404C46.7702 27.9155 47.4899 27.2427 48.2215 26.3338C48.5046 26.0269 48.776 25.9325 49.0356 26.0623C49.2125 26.145 49.3069 26.2748 49.3069 26.4518C49.3069 26.5345 49.2833 26.6407 49.2243 26.8059C47.7731 30.9608 45.201 33.0264 41.5198 33.0264C39.6438 33.0382 38.0273 32.6251 36.6705 31.787Z"
        fill="#171717"
      />
      <path
        d="M67.1939 26.6526C67.1939 26.7352 67.1465 26.9004 67.0407 27.1601C66.2145 29.1549 65.1293 30.6539 63.7955 31.6337C62.4623 32.6133 60.9169 33.1091 59.1472 33.1091C57.4364 33.1091 55.9142 32.7078 54.581 31.9051C53.2477 31.1025 52.1976 29.9694 51.4543 28.5175C50.6992 27.0657 50.3217 25.4014 50.3217 23.5364C50.3217 21.4826 50.7346 19.6649 51.5605 18.0713C52.3864 16.4779 53.4837 15.2385 54.8405 14.3532C56.1975 13.468 57.6723 13.0312 59.253 13.0312C60.9406 13.0312 62.3681 13.3972 63.5359 14.1172C64.7044 14.849 65.5658 15.7933 66.144 16.9618C66.71 18.1304 66.9933 19.3579 66.9933 20.6563V21.0104C66.9933 21.2229 66.9343 21.3763 66.8164 21.4944C66.6984 21.6124 66.5331 21.6715 66.3325 21.6715H55.2065C55.2533 24.1384 55.8552 25.9679 56.9999 27.1719C58.1441 28.3759 59.5721 28.9778 61.2829 28.9778C62.3212 28.9778 63.2174 28.7772 63.9846 28.3759C64.7512 27.9746 65.4594 27.3608 66.1081 26.5227C66.3914 26.1568 66.6631 26.0505 66.9227 26.2158C67.1112 26.3103 67.1939 26.4519 67.1939 26.6526ZM56.4333 15.9821C55.761 17.009 55.3713 18.4255 55.2655 20.2432H62.1438C62.0616 18.4845 61.7431 17.0681 61.1881 16.0175C60.6336 14.967 59.867 14.4476 58.9113 14.4476C57.9319 14.4476 57.1057 14.9552 56.4333 15.9821Z"
        fill="#171717"
      />
      <path
        d="M89.9654 32.0232C89.9654 32.2002 89.9065 32.3419 89.7885 32.4363C89.6706 32.5307 89.5052 32.5779 89.3046 32.5779H80.6324C80.4554 32.5779 80.2901 32.5307 80.1606 32.4245C80.0305 32.3183 79.9715 32.1884 79.9715 32.035C79.9715 31.7281 80.1606 31.5274 80.5497 31.4566C81.2226 31.3268 81.6707 31.1143 81.895 30.831C82.1194 30.5477 82.2252 30.0166 82.2252 29.2376V19.5468C82.2252 18.4019 81.9656 17.5284 81.4464 16.9264C80.9272 16.3126 80.1606 16.0175 79.1575 16.0175C78.4851 16.0175 77.848 16.1356 77.2583 16.3835C76.6564 16.6313 76.1488 16.9618 75.7007 17.3749V29.2376C75.7007 30.0166 75.8187 30.5359 76.0661 30.8074C76.3141 31.0789 76.7743 31.2914 77.4467 31.4448C77.8364 31.5274 78.0249 31.7163 78.0249 32.0232C78.0249 32.1766 77.9544 32.3064 77.8364 32.4127C77.7063 32.5189 77.5531 32.5662 77.3641 32.5662H68.6565C68.4444 32.5662 68.279 32.5189 68.1495 32.4245C68.0194 32.3301 67.9604 32.2002 67.9604 32.0114C67.9604 31.7045 68.1732 31.5038 68.586 31.433C69.2584 31.3032 69.7186 31.0907 69.9666 30.8074C70.2141 30.5241 70.332 29.993 70.332 29.2139V18.2484C70.332 17.5992 70.2141 17.1153 69.9666 16.7848C69.7186 16.466 69.2468 16.171 68.5507 15.9113C68.1611 15.7815 67.9726 15.569 67.9726 15.2857C67.9726 14.9788 68.1611 14.7781 68.5507 14.7074L74.6976 13.3027C74.7803 13.2791 74.8746 13.2673 75.0046 13.2673C75.37 13.2673 75.5475 13.4562 75.5475 13.8457V16.4897C76.1488 15.5572 76.9987 14.7427 78.1191 14.0581C79.2286 13.3735 80.4907 13.0312 81.895 13.0312C83.8654 13.0312 85.305 13.586 86.2254 14.7074C87.1452 15.8169 87.6054 17.1861 87.6054 18.7914V29.2139C87.6054 29.993 87.7355 30.5123 87.9951 30.7838C88.2547 31.0553 88.7149 31.2678 89.3989 31.4212C89.7648 31.5156 89.9654 31.7163 89.9654 32.0232Z"
        fill="#171717"
      />
      <path
        d="M107.239 26.6526C107.239 26.7352 107.191 26.9004 107.085 27.1601C106.259 29.1549 105.174 30.6539 103.841 31.6337C102.507 32.6133 100.962 33.1091 99.1922 33.1091C97.4809 33.1091 95.9592 32.7078 94.6259 31.9051C93.2927 31.1025 92.2422 29.9694 91.4993 28.5175C90.7442 27.0657 90.3667 25.4014 90.3667 23.5364C90.3667 21.4826 90.7795 19.6649 91.6051 18.0713C92.4313 16.4779 93.5286 15.2385 94.8855 14.3532C96.2424 13.468 97.7173 13.0312 99.298 13.0312C100.986 13.0312 102.413 13.3972 103.581 14.1172C104.749 14.849 105.61 15.7933 106.188 16.9618C106.755 18.1304 107.038 19.3579 107.038 20.6563V21.0104C107.038 21.2229 106.979 21.3763 106.861 21.4944C106.743 21.6124 106.578 21.6715 106.377 21.6715H95.2509C95.2983 24.1384 95.9002 25.9679 97.0444 27.1719C98.1891 28.3759 99.6166 28.9778 101.316 28.9778C102.354 28.9778 103.251 28.7772 104.017 28.3759C104.785 27.9746 105.492 27.3608 106.142 26.5227C106.425 26.1568 106.696 26.0505 106.956 26.2158C107.144 26.3103 107.239 26.4519 107.239 26.6526ZM96.4662 15.9821C95.7938 17.009 95.4047 18.4255 95.2983 20.2432H102.177C102.095 18.4845 101.776 17.0681 101.221 16.0175C100.667 14.967 99.8998 14.4476 98.9442 14.4476C97.9648 14.4476 97.1392 14.9552 96.4662 15.9821Z"
        fill="#171717"
      />
      <path
        d="M110.082 38.4442C109.646 38.4442 109.433 38.2436 109.433 37.8304C109.433 37.6298 109.493 37.4645 109.61 37.3465C109.728 37.2284 109.882 37.1694 110.094 37.1694H114.377V1.27478H110.094C109.893 1.27478 109.728 1.21577 109.61 1.09773C109.493 0.979694 109.433 0.826249 109.433 0.613784C109.433 0.20066 109.646 0 110.082 0H117.326C117.527 0 117.692 0.0590174 117.81 0.177053C117.928 0.295089 117.987 0.448536 117.987 0.660999V37.7832C117.987 37.9839 117.928 38.1491 117.81 38.2672C117.692 38.3852 117.539 38.4442 117.326 38.4442H110.082Z"
        fill="#171717"
      />
    </g>
    <defs>
      <clipPath id="clip0_921_21766">
        <rect width="118" height="38.4494" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export default SceneIcon;
