import React, { Component } from 'react';
import PropTypes from 'prop-types';

import styles from './styles.module.scss';

const pad = (unit) => {
  const str = `${unit}`;
  const padString = '00';
  return padString.substring(0, padString.length - str.length) + str;
};

const getState = (seconds) => {
  const minutes = Math.floor(seconds / 60);

  const humanTime = minutes !== 0 ? `${minutes}:${pad(seconds - minutes * 60)}` : `${seconds - minutes * 60}s`;

  return {
    seconds,
    human: humanTime,
  };
};

class Timer extends Component {
  static propTypes = {
    timeLimit: PropTypes.number,
    defaultText: PropTypes.string,
  };

  constructor(props) {
    super(props);

    const nextSeconds = props.timeLimit ? props.timeLimit / 1000 : 0;

    this.state = getState(nextSeconds);
  }

  componentWillUnmount() {
    clearInterval(this.timer);
  }

  componentDidMount() {
    const { timeLimit } = this.props;
    this.timer = setInterval(() => {
      const { seconds } = this.state;
      const nextSeconds = timeLimit ? seconds - 1 : seconds + 1;

      const nextState = getState(nextSeconds);
      this.setState(nextState);
    }, 1000);
  }

  render() {
    const defaultText = this.props.defaultText || '0:00';
    return (
      <div className={styles.text} {...this.props}>
        <div className={styles.icon} />
        {this.state.human || defaultText}
      </div>
    );
  }
}

export default Timer;
