// @flow

import { Map } from 'immutable';
import { isValidNumber } from 'libphonenumber-js';
import { touch } from 'redux-form';
import matches from 'validator/lib/matches';
import isEmail from 'validator/lib/isEmail';

const required = 'app.errors.required';
const invalid = 'app.errors.invalid';
const namePattern = new RegExp("^[a-z]+[a-z- ']*$", 'i');
const upperPattern = new RegExp('[A-Z]+');
const numberPattern = new RegExp('[0-9]+');
const specialCharacterPattern = new RegExp('[^a-zA-Z0-9\\s]');
const chatDisplayNamePattern = new RegExp('^(?![\\d\\s]*$)[\\w\\W\\s]+$');

export default function validate(
  values: Map<string, any>,
  props: { initialValues: Map<string, any>, uuid?: string, isPasswordSet: boolean, dispatch: (any) => void },
) {
  let errors = Map();

  // validate first name
  const firstName = (values.get('firstName') || '').trim();

  if (!firstName.length) {
    errors = errors.set('firstName', required);
  } else if (!matches(firstName, namePattern)) {
    errors = errors.set('firstName', invalid);
  }

  // validate last name
  const lastName = (values.get('lastName') || '').trim();

  if (!lastName.length) {
    errors = errors.set('lastName', required);
  } else if (!matches(lastName, namePattern)) {
    errors = errors.set('lastName', invalid);
  }

  // validate chat display name
  const chatDisplayName = values.get('chatDisplayName');

  if (chatDisplayName && !matches(chatDisplayName, chatDisplayNamePattern)) {
    errors = errors.set('chatDisplayName', invalid);
  }

  // validate email
  const email = values.get('email');

  if (!email) {
    errors = errors.set('email', required);
  } else if (!isEmail(email)) {
    errors = errors.set('email', invalid);
  }

  // validate phone number
  const phone = (values.get('phone') || '').trim();

  if (phone) {
    const isInternational = phone.startsWith('+');
    const args = isInternational ? [phone] : [phone, 'US'];

    if (!isValidNumber(...args)) {
      errors = errors.set('phone', invalid);
    }
  }

  // validate the update of the email
  const { initialValues, uuid, isPasswordSet, dispatch } = props;
  const isEditingEmail = values.get('email') !== initialValues.get('email');

  // only validate if the user is in edit mode and has a password
  if (isEditingEmail && uuid && isPasswordSet) {
    if (values.get('currentPassword', '') === '') {
      errors = errors.set('currentPassword', 'app.errors.password.current.requiredForEmail');
      dispatch(touch('manage-user', 'currentPassword'));
    }
  }

  // validate passwords
  const currentPassword = (values.get('currentPassword') || '').trim();
  const newPassword = (values.get('newPassword') || '').trim();
  const newConfirmPassword = (values.get('confirmNewPassword') || '').trim();
  const changingPassword = newPassword.length || newConfirmPassword.length;
  if (newPassword.length && !currentPassword.length) {
    errors = errors.set('currentPassword', 'app.errors.password.current.required');
  }

  if (changingPassword && isPasswordSet) {
    if (!newPassword) {
      errors = errors.set('newPassword', required);
    } else if (newPassword.length < 8) {
      errors = errors.set('newPassword', 'app.errors.password.min');
    } else if (newPassword.length > 255) {
      errors = errors.set('newPassword', 'app.errors.password.max');
    } else if (!upperPattern.test(newPassword)) {
      errors = errors.set('newPassword', 'app.errors.password.upper');
    } else if (!numberPattern.test(newPassword)) {
      errors = errors.set('newPassword', 'app.errors.password.number');
    } else if (!specialCharacterPattern.test(newPassword)) {
      errors = errors.set('newPassword', 'app.errors.password.specialCharacter');
    }
    if (newPassword !== newConfirmPassword) {
      errors = errors.set('confirmNewPassword', 'app.errors.password.confirm.no.match');
    }
  }

  // validate multi-select
  const entityUuids = values.get('entityUuids');

  if (!entityUuids || !entityUuids.size) {
    errors = errors.set('entityUuids', required);
  }

  // validate single-select
  const roleUuid = (values.get('roleUuid') || '').trim();

  if (!roleUuid) {
    errors = errors.set('roleUuid', required);
  }

  const profileVideos = values.get('profileVideos');

  if (profileVideos) {
    const profileVideosArray = profileVideos.toJS();
    const missingFiles = profileVideosArray.some(({ file, location }) => !file && !location);

    if (missingFiles) {
      errors = errors.set('profileVideos', 'app.user-profile.video.error');
    }
  }

  return errors.toJS();
}
