// @flow
import React from 'react';
import DoseUndeterminedQuestionMark from '../../components/Icons/DoseUndeterminedQuestionMark';
import DoseYes from '../../components/Icons/DoseYes';
import DoseNo from '../../components/Icons/DoseNo';
import DoseOmit from '../../components/Icons/DoseOmit';
import AlertChip from '../../components/AlertChip';

export const SELECTED_SUBMISSION = 'selected.submissions';

export const SET_SUBMISSION_NOTES = 'submission.notes';

export const FAHRENHEIT = 'F';

export const filtersDefinition = [
  {
    label: 'app.submissions.filter.unreviewed',
    value: 'UNREVIEWED_VIDEOS',
    icon: <AlertChip count={1} backgroundColor="#f9d849" color="#171717" />,
  },
  {
    label: 'app.submissions.filter.missed.submissions',
    value: 'MISSED_SUBMISSIONS',
    icon: <AlertChip count={1} backgroundColor="#ff1744" color="#171717" />,
  },
  {
    label: 'app.submissions.filter.missed.medications',
    value: 'MISSED_MEDICATIONS',
    icon: <AlertChip count={1} backgroundColor="#ff1744" color="#171717" />,
  },
  {
    label: 'app.submissions.filter.side.effects',
    value: 'SYMPTOMS_OR_SIDE_EFFECTS',
    icon: <AlertChip count={1} backgroundColor="#d116e4" color="#171717" />,
  },
  {
    label: 'app.submissions.filter.dose.count.undetermined',
    value: 'DOSE_COUNT_UNDETERMINED',
    icon: <DoseUndeterminedQuestionMark />,
  },
  {
    label: 'app.submissions.filter.dose.count.yes',
    value: 'DOSE_COUNT_YES',
    icon: <DoseYes />,
  },
  {
    label: 'app.submissions.filter.dose.count.no',
    value: 'DOSE_COUNT_NO',
    icon: <DoseNo />,
  },
  {
    label: 'app.submissions.filter.dose.count.omitted',
    value: 'DOSE_COUNT_OMITTED',
    icon: <DoseOmit />,
  },
];
