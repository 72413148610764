// @flow

import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useForm, Controller } from 'react-hook-form';
import { useQuery } from '@apollo/react-hooks';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import classNames from 'classnames';
import uniqBy from 'lodash/uniqBy';

import FormModal from '../../../../components/Modals/Form';
import Dropdown from '../../../../components/FormControls/Dropdown';
import StandardButton from '../../../../components/Buttons/Standard';
import { GET_CARE_TEAM_MEMBERS_AND_REVIEWERS } from './queries';
import type { User } from '../../../../components/ResultsTable';

import styles from './styles.module.scss';

type Props = {
  open: boolean,
  entityId: number,
  editingUser: User,
  careTeamPatientsCount?: number,
  reviewedPatientsCount?: number,
  totalPatientCount?: number,
  appointmentCount?: number,
  defaultCareTeamMemberUuid?: string,
  defaultReviewerUuid?: string,
  onClose: () => void,
  onSave: (data: { careTeamMember: User, reviewer: User, appointmentAssignee: User }) => void,
};

const BulkReassignmentForm = ({
  open,
  entityId,
  editingUser,
  careTeamPatientsCount = 0,
  reviewedPatientsCount = 0,
  totalPatientCount = 0,
  appointmentCount = 0,
  defaultCareTeamMemberUuid = '',
  defaultReviewerUuid = '',
  onClose,
  onSave,
}: Props) => {
  const { formatMessage } = useIntl();

  const { data: { careTeamMembers = [], reviewers = [] } = {}, loading } = useQuery(
    GET_CARE_TEAM_MEMBERS_AND_REVIEWERS,
    {
      fetchPolicy: 'cache-and-network',
    },
  );

  const selectedEntityCareTeamMembers = careTeamMembers.filter(({ entities }) =>
    entities.map(({ id }) => id).includes(entityId),
  );
  const selectedEntityReviewers = reviewers.filter(({ entities }) => entities.map(({ id }) => id).includes(entityId));

  const initialFormData = {
    careTeamUuid: '',
    careTeamUnassigned: false,
    reviewerUuid: '',
    reviewerUnassigned: false,
    appointmentAssigneeUuid: '',
  };

  const { handleSubmit, control, reset, getValues, watch } = useForm({
    mode: 'onChange',
    reValidateMode: 'onChange',
    defaultValues: {
      ...initialFormData,
      careTeamUuid: careTeamPatientsCount ? defaultCareTeamMemberUuid : '',
      reviewerUuid: reviewedPatientsCount ? defaultReviewerUuid : '',
    },
  });

  const careTeamUuid = watch('careTeamUuid');
  const careTeamUnassigned = watch('careTeamUnassigned');
  const reviewerUuid = watch('reviewerUuid');
  const reviewerUnassigned = watch('reviewerUnassigned');
  const appointmentAssigneeUuid = watch('appointmentAssigneeUuid');

  const careTeamMemberOptions = selectedEntityCareTeamMembers.map(({ uuid, firstName, lastName }) => ({
    value: uuid,
    label: `${lastName}, ${firstName}`,
  }));

  const reviewersOptions = selectedEntityReviewers.map(({ uuid, firstName, lastName }) => ({
    value: uuid,
    label: `${lastName}, ${firstName}`,
  }));

  const allUsers = uniqBy([...selectedEntityCareTeamMembers, ...selectedEntityReviewers], 'uuid');

  const careTeamsAndReviewersOptions = allUsers.map(({ uuid, firstName, lastName }) => ({
    value: uuid,
    label: `${lastName}, ${firstName}`,
  }));

  const handleLeaveUnassigned = (checked, fieldName, onChange) => {
    onChange(checked);
    if (checked) {
      reset({ ...getValues(), [fieldName]: '' });
    }
  };

  const handleOnClose = () => {
    reset(initialFormData);
    onClose();
  };

  const onSubmit = async () => {
    const careTeamMember = allUsers.find(({ uuid }) => uuid === careTeamUuid);
    const reviewer = allUsers.find(({ uuid }) => uuid === reviewerUuid);
    const appointmentAssignee = allUsers.find(({ uuid }) => uuid === appointmentAssigneeUuid);

    reset(initialFormData);
    onSave({ careTeamMember, reviewer, appointmentAssignee });
  };

  const isValidCareTeam = !!careTeamUuid || careTeamUnassigned || !careTeamPatientsCount;
  const isValidReviewer = !!reviewerUuid || reviewerUnassigned || !reviewedPatientsCount;
  const isValidAppointmentAssignee = (!!appointmentCount && !!appointmentAssigneeUuid) || !appointmentCount;
  const isValidData = isValidCareTeam && isValidReviewer && isValidAppointmentAssignee;

  return (
    <FormModal open={open} title={formatMessage({ id: 'app.user.bulk-reassignment.title' })}>
      <div className={styles.container}>
        <div className={styles.content}>
          <Box className={styles.patientCount} mt={2} mb={6}>
            <FormattedMessage
              tagName="strong"
              id="app.user.bulk-reassignment.assignments-count"
              values={{
                userName: `${editingUser.firstName} ${editingUser.lastName}`,
                totalPatientCount,
                appointmentCount,
              }}
            />
          </Box>
          <FormattedMessage tagName="legend" id="app.user.bulk-reassignment.reassign-careteam-reviewer" />
          <Box mb={6}>
            <Box display="flex" gridGap={12}>
              <Grid className={styles.gridItem} item xs={6}>
                <Controller
                  render={({ name, value, onChange, onBlur }) => (
                    <div>
                      <Dropdown
                        input={{ name, value, onChange, onBlur }}
                        label={formatMessage({ id: 'app.user.bulk-reassignment.careteam' })}
                        placeholder={formatMessage({ id: 'app.user.bulk-reassignment.careteam' })}
                        options={careTeamMemberOptions}
                        meta={{ touched: true, error: '' }}
                        isClearable={false}
                        disabled={!careTeamPatientsCount || careTeamUnassigned}
                        loading={loading}
                        required
                      />
                    </div>
                  )}
                  name="careTeamUuid"
                  control={control}
                />
                <Controller
                  render={({ value, onChange }) => (
                    <FormControlLabel
                      label={<FormattedMessage id="app.user.bulk-reassignment.leave-unassign" />}
                      control={
                        <Checkbox
                          checked={value || !careTeamPatientsCount}
                          onChange={(e) => handleLeaveUnassigned(e.target.checked, 'careTeamUuid', onChange)}
                          disableRipple
                          color="primary"
                          classes={{
                            root: classNames({ [styles.checkboxDefault]: !!careTeamPatientsCount }),
                          }}
                          disabled={!careTeamPatientsCount}
                        />
                      }
                    />
                  )}
                  name="careTeamUnassigned"
                  control={control}
                  defaultValue={false}
                />
              </Grid>
              <Grid className={styles.gridItem} item xs={6}>
                <Controller
                  render={({ name, value, onChange, onBlur }) => (
                    <div>
                      <Dropdown
                        input={{ name, value, onChange, onBlur }}
                        label={formatMessage({ id: 'app.user.bulk-reassignment.reviewer' })}
                        placeholder={formatMessage({ id: 'app.user.bulk-reassignment.reviewer' })}
                        options={reviewersOptions}
                        meta={{ touched: true, error: '' }}
                        isClearable={false}
                        disabled={!reviewedPatientsCount || reviewerUnassigned}
                        loading={loading}
                        required
                      />
                    </div>
                  )}
                  name="reviewerUuid"
                  control={control}
                />
                <Controller
                  render={({ value, onChange }) => (
                    <FormControlLabel
                      label={<FormattedMessage id="app.user.bulk-reassignment.leave-unassign" />}
                      control={
                        <Checkbox
                          checked={value || !reviewedPatientsCount}
                          onChange={(e) => handleLeaveUnassigned(e.target.checked, 'reviewerUuid', onChange)}
                          disableRipple
                          color="primary"
                          classes={{
                            root: classNames({ [styles.checkboxDefault]: !!reviewedPatientsCount }),
                          }}
                          disabled={!reviewedPatientsCount}
                        />
                      }
                    />
                  )}
                  name="reviewerUnassigned"
                  control={control}
                />
              </Grid>
            </Box>
          </Box>
          <FormattedMessage tagName="legend" id="app.user.bulk-reassignment.reassign-appointments" />
          <Grid container>
            <Grid className={styles.gridItem} item xs={6}>
              <Controller
                render={({ name, value, onChange, onBlur }) => (
                  <div>
                    <Dropdown
                      input={{ name, value, onChange, onBlur }}
                      label={formatMessage({ id: 'app.user.bulk-reassignment.careteam-or-reviewer' })}
                      placeholder={formatMessage({ id: 'app.user.bulk-reassignment.careteam-or-reviewer' })}
                      options={careTeamsAndReviewersOptions}
                      meta={{ touched: true, error: '' }}
                      isClearable={false}
                      menuPlacement="top"
                      disabled={!appointmentCount}
                      loading={loading}
                      required
                    />
                  </div>
                )}
                name="appointmentAssigneeUuid"
                control={control}
              />
            </Grid>
          </Grid>
        </div>
      </div>
      <div className={styles.footer}>
        <StandardButton variant="secondary" onClick={handleOnClose}>
          <FormattedMessage id="app.forms.cancel" />
        </StandardButton>
        <StandardButton
          onClick={async () => {
            handleSubmit(onSubmit)();
          }}
          disabled={!isValidData}
        >
          <FormattedMessage id="app.user.bulk-reassignment.reassign" />
        </StandardButton>
      </div>
    </FormModal>
  );
};

export default BulkReassignmentForm;
