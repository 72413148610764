// @flow

import cx from 'classnames';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import React, { useState, useEffect, useContext } from 'react';
import { useMutation } from 'react-apollo';
import CallIcon from '@material-ui/icons/Call';
import CloseIcon from '@material-ui/icons/Close';
import Video from 'twilio-video';

import styles from './styles.module.scss';
import Participant from './Participant/index';
import { authUserSelector } from '../SignIn/selectors';
import { GET_VIDEO_ROOM } from './mutations';
import { LiveVideoPatientContext } from '../LiveVideoPatientProvider';
import VideoButtons from './VideoButtons';
export function LiveVideo() {
  const currentUser = useSelector(authUserSelector);
  const { firstName: userFirstName, lastName: userLastName } = currentUser || {};
  const { patientUuid, setPatientUuid } = useContext(LiveVideoPatientContext);
  const [tryToConnect, setTryToConnect] = useState(false);
  const [name, setName] = useState('');
  const [getVideoRoom] = useMutation(GET_VIDEO_ROOM);
  const [token, setToken] = useState(null);
  const [roomName, setRoomName] = useState(null);
  const [muted, setMuted] = useState(false);
  const [videoPaused, setVideoPaused] = useState(false);
  const [room, setRoom] = useState(null);
  const [participants, setParticipants] = useState([]);
  const [maximized, setMaximized] = useState(false);
  const [bottom, setBottom] = useState(false);

  const closeLiveVideo = () => {
    setPatientUuid(null);
    setRoomName(null);
  };

  const hangup = () => {
    if (!room) {
      return;
    }
    room.disconnect();
    setRoom(null);
    setTryToConnect(false);
    setParticipants([]);
    setMuted(false);
    setVideoPaused(false);
    setMaximized(false);
  };

  const startCall = () => {
    setTryToConnect(true);
  };

  const toggleMaximize = () => {
    setMaximized(!maximized);
  };

  const toggleBottom = () => {
    setBottom(!bottom);
    setMaximized(false);
  };

  const toggleMute = () => {
    if (!room) {
      return;
    }
    room.localParticipant.audioTracks.forEach((publication) => {
      if (muted) {
        publication.track.enable();
      } else {
        publication.track.disable();
      }
    });
    setMuted(!muted);
  };

  const toggleVideo = () => {
    if (!room) {
      return;
    }
    room.localParticipant.videoTracks.forEach((publication) => {
      if (videoPaused) {
        publication.track.enable();
      } else {
        publication.track.disable();
      }
    });
    setVideoPaused(!videoPaused);
  };

  useEffect(() => {
    if (!patientUuid) {
      return;
    }
    const getPatientUuid = async () => {
      const {
        data: {
          getVideoRoomForPatient: { room: roomToUse, accessToken, firstName, lastName },
        },
      } = await getVideoRoom({
        variables: { patientUuid },
      });
      setRoomName(roomToUse);
      setToken(accessToken);
      setName(`${firstName} ${lastName}`);
    };
    if (!roomName) {
      getPatientUuid();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [patientUuid]);

  useEffect(() => {
    if (!roomName || !token || !tryToConnect) {
      return () => {};
    }

    const participantConnected = (participant) => {
      setParticipants((prevParticipants) => [...prevParticipants, participant]);
    };

    const participantDisconnected = (participant) => {
      setParticipants((prevParticipants) => prevParticipants.filter((p) => p !== participant));
    };

    Video.connect(token, {
      name: roomName,
    }).then((roomConnected) => {
      setRoom(roomConnected);
      roomConnected.on('participantConnected', participantConnected);
      roomConnected.on('participantDisconnected', participantDisconnected);
      roomConnected.participants.forEach(participantConnected);
    });

    return () => {
      setRoom((currentRoom) => {
        if (currentRoom && currentRoom.localParticipant.state === 'connected') {
          currentRoom.localParticipant.tracks.forEach((trackPublication) => {
            trackPublication.track.stop();
          });
          currentRoom.disconnect();
          return null;
        }
        return currentRoom;
      });
    };
  }, [roomName, token, tryToConnect]);

  if (!patientUuid) {
    return null;
  }

  const remoteParticipants = participants.map((participant) => (
    <Participant
      key={participant.sid}
      className={cx(maximized ? styles.remoteMax : styles.remoteMin)}
      participant={participant}
    />
  ));
  let wrapper = [styles.wrapper];
  if (bottom) {
    wrapper = styles.bottomRightCorner;
  }
  if (maximized) {
    wrapper = styles.maximized;
  }

  return (
    <div className={wrapper} id="liveVideo">
      {remoteParticipants}
      {remoteParticipants.length === 0 && (
        <div className={styles.remoteVideoPlaceholder}>
          <div>
            {tryToConnect && room && `Waiting for ${name || 'patient'} to join`}
            {!tryToConnect && !room && `Video Chat has not started`}
            {tryToConnect && !room && `Connecting to room`}
          </div>
          {!room && !tryToConnect && (
            <div className={styles.initialConnect}>
              <div className={styles.videoButtonGroup}>
                <CallIcon className={cx(styles.videoButtonOn, styles.join)} onClick={startCall} />
                <div>Join</div>
              </div>
              <div className={styles.videoButtonGroup}>
                <CloseIcon className={cx(styles.videoButtonOn, styles.hangup)} onClick={closeLiveVideo} />
                <div>Close</div>
              </div>
            </div>
          )}
        </div>
      )}
      <div className={styles.blackTransparent} />
      <div className={styles.patientName}>
        <Link
          to={`/patient/${patientUuid}/submissions`}
          onClick={() => setMaximized(false)}
          title="Go to submissions page"
        >
          {name}
        </Link>
      </div>
      <VideoButtons
        muted={muted}
        room={room}
        maximized={maximized}
        bottom={bottom}
        videoPaused={videoPaused}
        onHangup={hangup}
        toggleMaximize={toggleMaximize}
        toggleBottom={toggleBottom}
        toggleMute={toggleMute}
        toggleVideo={toggleVideo}
      />
      {room && (
        <Participant
          title={`${userFirstName} ${userLastName}`}
          className={styles.localVideo}
          muted={muted}
          key={room.localParticipant.sid}
          participant={room.localParticipant}
        />
      )}
    </div>
  );
}

export default LiveVideo;
