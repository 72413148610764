// @flow

export const CHAT_HISTORY_REFRESH = 'chat.history.refresh';

export const CHAT_HISTORY_RESULTS = 'chat.history.results';

export const CHAT_RESET = 'chat.reset';

export const CHAT_SESSION_REQUEST = 'chat.session.request';

export const CHAT_SESSION_SUCCESS = 'chat.session.success';

export const CHAT_SESSION_ERROR = 'chat.session.error';

export const CHAT_OPEN_MESSAGE_BANK_FILTERED = 'chat.message.bank.filter';

export const CHAT_NO_PREVIOUS = 'chat.message.no-more';

export const CHAT_LOAD_MORE = 'chat.message.loading';

export const CHAT_MESSAGE_ATTRIBUTES_UPDATED = 'chat.message.attribute.updated';
