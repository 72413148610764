// @flow

import { useState, useEffect } from 'react';
import { isEqual } from 'lodash';

import { subscribe, authGet } from '../utils/authentication';

const useAuth = (): Object => {
  const [auth, setAuth] = useState(authGet().toJS());

  useEffect(() => {
    const subscriber = (newAuth) => {
      if (isEqual(auth, newAuth.toJS())) {
        return;
      }

      // NOTE: Deferring just to prevent issue with react-redux hooks
      setTimeout(() => setAuth(newAuth ? newAuth.toJS() : {}), 1);
    };
    const unsubscribe = subscribe(subscriber);

    return unsubscribe;
  }, [auth]);

  return auth;
};

export default useAuth;
