// @flow

import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

function DoseUndeterminedQuestionMark(props: Object) {
  return (
    <SvgIcon viewBox="0 0 22 22" {...props}>
      <g fill="none" fillRule="evenodd">
        <circle cx="11" cy="11" r="11" fill="#f9d849" />
        <path
          fill="#FFF"
          d="M14.8015385,8.06307692 C14.8015385,8.66102863 14.6548733,9.16025441 14.3615385,9.56076923 C14.0682037,9.96128405 13.6056442,10.3194856 12.9738462,10.6353846 C12.4435871,10.9061552 12.0966675,11.1374349 11.9330769,11.3292308 C11.7694864,11.5210266 11.6651284,11.6987171 11.62,11.8623077 C11.5748716,12.0258983 11.5523077,12.2148707 11.5523077,12.4292308 L11.5523077,12.92 L9.55538462,12.92 L9.55538462,12.2430769 C9.55538462,11.2276872 9.85999695,10.5056432 10.4692308,10.0769231 C10.6835908,9.91897357 10.9994851,9.73282158 11.4169231,9.51846154 C11.8343611,9.30410149 12.1361529,9.08692418 12.3223077,8.86692308 C12.5084625,8.64692198 12.6015385,8.36487352 12.6015385,8.02076923 C12.6015385,7.67666495 12.4605142,7.38897552 12.1784615,7.15769231 C11.8964088,6.9264091 11.4789771,6.81076923 10.9261538,6.81076923 C10.3733306,6.81076923 9.93897594,6.94333201 9.62307692,7.20846154 C9.30717791,7.47359107 9.12666689,7.82051068 9.08153846,8.24923077 L7,8.24923077 C7.05641054,7.2676874 7.41743257,6.48077219 8.08307692,5.88846154 C8.74872128,5.29615088 9.68230168,5 10.8838462,5 C12.0853906,5 13.0387144,5.2933304 13.7438462,5.88 C14.4489779,6.4666696 14.8015385,7.19435463 14.8015385,8.06307692 Z M9.59769231,14.1553846 C9.85153973,13.9071782 10.1843569,13.7830769 10.5961538,13.7830769 C11.0079508,13.7830769 11.3492294,13.9071782 11.62,14.1553846 C11.8907706,14.403591 12.0261538,14.7194853 12.0261538,15.1030769 C12.0261538,15.4866686 11.8907706,15.8053833 11.62,16.0592308 C11.3492294,16.3130782 11.0079508,16.44 10.5961538,16.44 C10.1843569,16.44 9.85153973,16.3130782 9.59769231,16.0592308 C9.34384488,15.8053833 9.21692308,15.4866686 9.21692308,15.1030769 C9.21692308,14.7194853 9.34384488,14.403591 9.59769231,14.1553846 Z"
        />
      </g>
    </SvgIcon>
  );
}

export default DoseUndeterminedQuestionMark;
