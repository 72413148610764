// @flow

import { useState } from 'react';

const useAnchor = (options?: {
  anchor?: HTMLElement,
  targetAccessor?: (event: SyntheticEvent<any>) => HTMLElement,
}) => {
  const { anchor, targetAccessor } = options || {};
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);

  const handleOpen = (event: SyntheticEvent<any>) => {
    if (anchor) {
      setAnchorEl(anchor);
    }
    let target;
    if (targetAccessor) {
      target = targetAccessor(event);
    } else {
      target = event.currentTarget;
    }
    setAnchorEl(target);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  return {
    anchorEl,
    setAnchorEl,
    handleOpen,
    handleClose,
    open,
  };
};

export default useAnchor;
