// @flow

import React from 'react';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import { useIntl } from 'react-intl';

import styles from './styles.module.scss';

type Props = {
  onClick?: () => void,
};

const RecordButton = ({ onClick }: Props) => {
  const { formatMessage } = useIntl();
  return (
    <Box display="flex" flexDirection="column" justifyContent="center" alignItems="center" position="absolute">
      <div className={styles.instructions}>
        <div>{formatMessage({ id: 'app.video-back.press.record.line.1' })}</div>
        <div className={styles.instructionsHighlight}>
          {formatMessage({ id: 'app.video-back.press.record.line.2' })}
        </div>
        {formatMessage({ id: 'app.video-back.press.record.line.3' })}
      </div>

      <div className={styles.buttonBorder}>
        <Button className={styles.button} onClick={onClick} disableRipple />
      </div>
    </Box>
  );
};

export default RecordButton;
