// @flow
import { useParams } from 'react-router-dom';

const usePath = () => {
  const { slug } = useParams();

  return (relativePath: string) => {
    const path = ['/self-enrollment'];
    if (slug) path.push(slug);
    path.push(relativePath);

    return path.join('/');
  };
};

export default usePath;
