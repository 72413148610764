// @flow
// import ReactGA from 'react-ga';

// ReactGA.initialize(process.env.REACT_APP_GOOGLE_ANALYTICS_ID, {
//   debug: process.env.NODE_ENV !== 'production',
//   gaOptions: { cookieDomain: document.location.hostname },
// });

const pageview = (path: string, trackerNames: ?(string[]), title: string) => {
  // eslint-disable-next-line no-console
  console.debug('Analytic event (pageview): ', { path, trackerNames, title });
  // ReactGA.pageview(pathname, value, pageTitle);
};

const modalview = (path: string) => {
  // eslint-disable-next-line no-console
  console.log('Analytic event (modalview): ', { path });
  // ReactGA.modalview(pathname);
};

export default { pageview, modalview };
