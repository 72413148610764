// @flow
import React, { useState, useEffect, useContext, createContext } from 'react';
import gql from 'graphql-tag';
import { useQuery } from '@apollo/react-hooks';
import { useParams } from 'react-router-dom';
import type { Element } from 'react';

type FormDataType = {
  firstName: string,
  lastName: string,
  dateOfBirth: string,
  memberId: string,
  entitySuffix: string,
};

type PatientDataType = {
  patientId: number,
  name: string,
  deactivationReason: string,
  phoneNumber: string,
  email: string,
  attemptId: number,
};

type SelfEnrollmentProcessType = {
  message: string,
  success: boolean,
};

type ContextPropsType = {
  message: string,
  setMessage: (message: string) => void,
  patientPhoneNumber: string,
  setPatientPhoneNumber: (phoneNumber: string) => void,
  patientData: PatientDataType | null,
  setPatientData: (patientData: PatientDataType | null) => void,
  formData: FormDataType,
  setFormData: (formData: FormDataType | ((FormDataType) => FormDataType)) => void,
  selfEnrollmentResponse: SelfEnrollmentProcessType | null,
  setSelfEnrollmentResponse: (selfEnrollmentResponse: SelfEnrollmentProcessType) => void,
};

const SELF_ENROLLMENT_PAGE_INFO = gql`
  query selfEnrollmentPageInformation($entitySuffix: String!) {
    selfEnrollmentPageInformation(entitySuffix: $entitySuffix) {
      termsOfUse
      privacyPolicy
      selfEnrollment {
        payerSelfEnrollment
        sampleInsuranceCard
        selfEnrollmentSuffix
        cobrandedWebsiteProgramLink
        selfEnrollmentSupportNumber
      }
      organizationName
      orgPhone
    }
  }
`;

const Context = createContext<ContextPropsType>({});
const DEFAULT_FORM_DATA = {
  entitySuffix: '',
  memberId: '',
  firstName: '',
  lastName: '',
  dateOfBirth: '',
};

export const SelfEnrollmentProvider = ({ children }: { children: Element<any> }) => {
  const [formData, setFormData] = useState<FormDataType>(DEFAULT_FORM_DATA);
  const [patientData, setPatientData] = useState<PatientDataType | null>(null);
  const [message, setMessage] = useState('');
  const [patientPhoneNumber, setPatientPhoneNumber] = useState('');
  const [selfEnrollmentResponse, setSelfEnrollmentResponse] = useState<SelfEnrollmentProcessType | null>(null);

  return (
    <Context.Provider
      value={{
        patientData,
        setPatientData,
        message,
        setMessage,
        formData,
        setFormData,
        patientPhoneNumber,
        setPatientPhoneNumber,
        selfEnrollmentResponse,
        setSelfEnrollmentResponse,
      }}
    >
      {children}
    </Context.Provider>
  );
};

const useSelfEnrollment = () => {
  const context = useContext(Context);
  const { slug } = useParams();
  const { entitySuffix } = context.formData;

  const { data } = useQuery(SELF_ENROLLMENT_PAGE_INFO, {
    variables: { entitySuffix },
    skip: !entitySuffix?.length,
    fetchPolicy: 'network-only',
  });

  const { setFormData } = context;
  useEffect(() => {
    if (!entitySuffix?.length) {
      setFormData((formData) => ({ ...formData, entitySuffix: slug }));
    }
  }, [slug, setFormData, entitySuffix]);
  const isOrganizationDefined = !!slug;

  return {
    ...context,
    entityData: data?.selfEnrollmentPageInformation,
    isOrganizationDefined,
  };
};

export default useSelfEnrollment;
