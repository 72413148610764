import React from 'react';

import styles from './styles.module.scss';

const LoadingIndicator = () => (
  <div className={styles.loadingContainer}>
    <div className={styles.loader} />
  </div>
);

export default LoadingIndicator;
