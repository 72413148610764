// @flow

import React from 'react';
import { Redirect } from 'react-router-dom';

const RootRedirect = () => {
  const authStr = sessionStorage.getItem('auth');
  const authUser = JSON.parse(authStr || '{}')?.user;

  const getRedirectLocation = () => {
    let to;

    if (!authUser) {
      to = '/login';
    } else {
      const {
        isPayerUser,
        role: { permissions, name: roleName },
        entities: [{ thirdPartyAnalyticsEnabled }],
      } = authUser;

      if (permissions.find(({ name }) => name === 'nullPermission')) {
        to = '/no-permissions';
      } else if (permissions.find(({ name }) => name === 'quickEnrollPatient') && roleName === 'quickEnroller') {
        to = '/quick-enroll';
      } else if (permissions.find(({ name }) => name === 'planEligiblePatients')) {
        to = '/eligible-patients';
      } else if (isPayerUser) {
        to = '/payer';
      } else {
        const patientsPermission = permissions.find(({ name }) => name === 'patients');

        if (!patientsPermission) {
          to = thirdPartyAnalyticsEnabled ? '/analytics/data' : 'analytics';
        } else {
          to = '/alerts';
        }
      }
    }

    return to;
  };
  const to = getRedirectLocation();

  return <Redirect to={to} />;
};

export default RootRedirect;
