// @flow

import React from 'react';
import Button from '@material-ui/core/Button';

import styles from './styles.module.scss';

Button.defaultProps = {
  color: 'black',
  backgroundColor: 'white',
};

type Props = {
  onClick: () => void,
};

export default ({ onClick }: Props) => (
  <div className={styles.border}>
    <Button className={styles.button} onClick={onClick} />
  </div>
);
