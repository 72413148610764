// @flow
import gql from 'graphql-tag';

export const GET_USER_PROFILE_QUESTION_OPTIONS = gql(`
  query userProfileQuestions {
    userProfileQuestions {
      id
      question
    }
  }
`);
