// @flow
import keyBy from 'lodash/keyBy';

export const getProfilePictureUpdate = (profilePicture: { id?: number, location?: string, file?: File }) => {
  if (!profilePicture) return 'delete';
  if (profilePicture && profilePicture.file) return 'create';
  return null;
};

export const uploadUserMedia = async ({
  profilePicture,
  profileVideosUploadLinks = [],
  profileVideos,
}: {
  profilePicture?: { file?: File, uploadLink?: string },
  profileVideosUploadLinks?: Array<{ userProfileQuestionId: number, uploadLink: string }>,
  profileVideos?: Array<{ userProfileQuestionId: number, file: File }>,
}) => {
  if (profilePicture && profilePicture.file && profilePicture.uploadLink) {
    await fetch(profilePicture.uploadLink, { method: 'put', body: profilePicture.file });
  }

  if (profileVideosUploadLinks.length) {
    const profileVideosByQuestionId = keyBy(profileVideos, 'userProfileQuestionId');

    await Promise.all(
      profileVideosUploadLinks.map(async ({ userProfileQuestionId, uploadLink }) => {
        const { file } = profileVideosByQuestionId[userProfileQuestionId] ?? {};

        if (file) {
          await fetch(uploadLink, { method: 'put', body: file });
        }
      }),
    );
  }
};
