// @flow
import React, { useState } from 'react';
import { useQuery } from '@apollo/react-hooks';
import { FormattedMessage, useIntl } from 'react-intl';
import Box from '@material-ui/core/Box';
import { List } from 'immutable';
import keyBy from 'lodash/keyBy';

import Dropdown from '../../../FormControls/Dropdown';
import AddVideoModal from './AddVideoModal';
import UserAnswerVideo from './UserAnswerVideo';
import type { Option } from '../../../FormControls/Dropdown';
import { GET_USER_PROFILE_QUESTION_OPTIONS } from './queries';

import styles from './styles.module.scss';

type Props = {
  input: {
    value: any,
    onChange: (any) => void,
  },
  meta: {
    error?: string,
  },
  disabled?: boolean,
};

const UserProfileVideos = ({ input, meta, disabled }: Props) => {
  const { formatMessage } = useIntl();
  const { value, onChange } = input;
  const [videoModalOpen, setVideoModalOpen] = useState(false);
  const [videoModalMode, setVideoModalMode] = useState('record');
  const [selectedQuestionId, setSelectedQuestionId] = useState();
  const selectedQuestions = value ? value.toJS() : [];

  const { data: { userProfileQuestions = [] } = {} } = useQuery(GET_USER_PROFILE_QUESTION_OPTIONS, {
    fetchPolicy: 'cache-and-network',
  });

  const options: Array<Option> = userProfileQuestions.map(({ id, question }) => ({ label: question, value: id }));

  const handleSelectOptions = (index: number, userProfileQuestionId: any) => {
    const question = selectedQuestions[index] ?? {};
    const questions = [...selectedQuestions];
    questions[index] = { ...question, userProfileQuestionId };

    onChange(List(questions));
  };

  const handleVideoModalOpen = (userProfileQuestionId: number, mode: string) => {
    setSelectedQuestionId(userProfileQuestionId);
    setVideoModalOpen(true);
    setVideoModalMode(mode);
  };

  const handleSaveVideo = (file: File) => {
    const questions = selectedQuestions.map((question) => {
      if (question.userProfileQuestionId === selectedQuestionId) {
        return { ...question, file };
      }

      return question;
    });

    onChange(List(questions));
  };

  const handleVideoModalClose = () => setVideoModalOpen(false);

  const handleDeleteVideo = (index: number, userProfileQuestionId: number) => {
    const questions = selectedQuestions.filter(({ userProfileQuestionId: id }) => id !== userProfileQuestionId);
    onChange(List(questions));
  };

  const questionNumber = selectedQuestions.length;
  const canAddNewVideo = questionNumber < 3;
  const selectedQuestionIds = selectedQuestions.map(({ userProfileQuestionId }) => userProfileQuestionId);
  const availableOptions = options.filter(({ value: id }) => !selectedQuestionIds.includes(id));
  const optionsById = keyBy(options, 'value');

  return (
    <>
      <div>
        {selectedQuestions.map(({ userProfileQuestionId }, index) => {
          const question = selectedQuestions.find(({ userProfileQuestionId: id }) => id === userProfileQuestionId);
          const { file, location } = question ?? {};
          const availableQuestions = optionsById[userProfileQuestionId]
            ? [optionsById[userProfileQuestionId], ...availableOptions]
            : availableOptions;
          return (
            <div key={`user-profile-question-${userProfileQuestionId}`}>
              <UserAnswerVideo
                userProfileQuestionId={userProfileQuestionId}
                file={file}
                location={location}
                options={availableQuestions}
                onSelect={(id) => handleSelectOptions(index, parseInt(id, 10))}
                onDelete={() => handleDeleteVideo(index, userProfileQuestionId)}
                onVideoModalOpen={handleVideoModalOpen}
              />
            </div>
          );
        })}
        {meta?.error && (
          <Box className={styles.error} mt={2}>
            <FormattedMessage id={meta?.error} />
          </Box>
        )}
        {canAddNewVideo && (
          <Box mt={4}>
            <Dropdown
              input={{ name: '', value: '', onChange: (id) => handleSelectOptions(questionNumber, parseInt(id, 10)) }}
              label={formatMessage({ id: 'app.user-profile.question.select' })}
              options={availableOptions}
              disabled={disabled}
              sortOptions={false}
            />
          </Box>
        )}
      </div>
      <AddVideoModal
        open={videoModalOpen}
        mode={videoModalMode}
        onSaveVideo={handleSaveVideo}
        onClose={handleVideoModalClose}
      />
    </>
  );
};

export default UserProfileVideos;
