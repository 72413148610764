// @flow

import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useRouteMatch } from 'react-router-dom';
import classNames from 'classnames';
import { SCENARIOS } from '../constants';

import styles from './styles.module.scss';

type Props = {
  headerId: string,
  subHeaderId: string,
  scenario?: string,
  values?: Object,
  phoneNumber?: string,
  alignCenter?: boolean,
};

const PageHeader = ({ headerId, subHeaderId, scenario, values, phoneNumber, alignCenter }: Props) => {
  const { formatMessage } = useIntl();
  const { isExact: isNotifyPage = false } = useRouteMatch('/self-enrollment/:slug?/notify') ?? {};
  const { isExact: isThanksPage = false } = useRouteMatch('/self-enrollment/:slug?/thanks') ?? {};

  if (!headerId || !subHeaderId) return null;

  const notifyPageVariant = isNotifyPage && scenario !== SCENARIOS.NOT_FOUND;

  return (
    <div className={classNames(styles.container, { [styles.alignCenter]: alignCenter })}>
      <div className={styles.header}>
        <FormattedMessage id={headerId} values={values} />
      </div>
      <div
        className={classNames({
          [styles.subHeader4]: isNotifyPage || isThanksPage,
          [styles.subHeader5]: !isNotifyPage || !isThanksPage,
        })}
      >
        <FormattedMessage id={subHeaderId} values={values} />
      </div>
      {notifyPageVariant && (
        <div className={styles.callSupport}>
          {formatMessage({ id: 'app.self-enroll.call-support' })}:{' '}
          <span className={styles.callSupportPhone}>{phoneNumber}</span>
        </div>
      )}
    </div>
  );
};

export default PageHeader;
