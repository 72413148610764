// @flow

import branch from 'recompose/branch';
import withProps from 'recompose/withProps';
import mapProps from 'recompose/mapProps';
import renderComponent from 'recompose/renderComponent';
import { Redirect } from 'react-router-dom';

type Predicate = (props: Object) => boolean;

const withTo = withProps(() => ({ to: '/' }));

const withoutProps = mapProps(({ user, permission, ...rest }) => rest);

const withAuthorization = (isForbidden: Predicate) =>
  branch(isForbidden, renderComponent(withTo(Redirect)), withoutProps);

export default withAuthorization(
  ({ user, permission }) => Boolean(user) === false || !user.role.permissions.some(({ name }) => permission === name),
);
