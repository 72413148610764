// @flow

const updateFavicon = () => {
  const link = document.createElement('link');
  link.setAttribute('rel', 'icon');
  link.setAttribute('type', 'image/png');
  link.setAttribute('id', 'spotlight-favicon');
  link.setAttribute('href', '/spotlight-favicon.png');

  const [head] = document.getElementsByTagName('head');
  head.append(link);

  return link;
};

export default updateFavicon;
