// @flow

import branch from 'recompose/branch';
import withProps from 'recompose/withProps';
import mapProps from 'recompose/mapProps';
import renderComponent from 'recompose/renderComponent';
import { Redirect } from 'react-router-dom';

type Predicate = (props: Object) => boolean;

const withTo = withProps(({ location }) => ({ to: `/login?returnTo=${location.pathname}` }));

const withoutToken = mapProps(({ token, ...rest }) => rest);

const withAuthentication = (isUnauthenticated: Predicate) =>
  branch(isUnauthenticated, renderComponent(withTo(Redirect)), withoutToken);

export default withAuthentication(({ token }) => Boolean(token) === false);
