// @flow

import gql from 'graphql-tag';

export const GET_VIDEO_ROOM = gql`
  mutation videoRoom($patientUuid: ID!) {
    getVideoRoomForPatient(patientUuid: $patientUuid) {
      room
      accessToken
      firstName
      lastName
    }
  }
`;
