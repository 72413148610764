// @flow
import React from 'react';
import { FormattedMessage } from 'react-intl';
import Box from '@material-ui/core/Box';
import ImageIcon from '@material-ui/icons/ImageOutlined';

import StandardButton from '../../Buttons/Standard';
import Slider from '../../Slider';
import RotateLeft from '../../Icons/RotateLeft';
import RotateRight from '../../Icons/RotateRight';
import StepOne from '../../Icons/StepOne';
import StepTwo from '../../Icons/StepTwo';
import StepThree from '../../Icons/StepThree';

import styles from './styles.module.scss';

type Props = {
  zoom: number,
  minZoom: number,
  maxZoom: number,
  zoomStep: number,
  onRotateLeft: () => void,
  onRotateRight: () => void,
  onZoomChange: (number) => void,
};

const CropperActions = ({ zoom, minZoom, maxZoom, zoomStep, onRotateLeft, onRotateRight, onZoomChange }: Props) => {
  const handleZoomChange = (event, newValue) => {
    onZoomChange(newValue);
  };

  return (
    <Box display="flex" flexDirection="column" gridGap={6} mt={6}>
      <Box display="flex" className={styles.control} p={2} gridGap={32}>
        <StepOne className={styles.icon} />
        <Box display="flex" flexDirection="column" gridGap={8} width="80%">
          <Box display="flex" alignItems="center" className={styles.controlTitle} height="20px">
            <FormattedMessage id="app.user-profile.picture.cropper.rotate" />
          </Box>
          <Box display="flex" justifyContent="space-between">
            <StandardButton
              variant="tertiarySpecial"
              onClick={onRotateLeft}
              startIcon={<RotateLeft className={styles.icon} />}
            >
              <FormattedMessage id="app.user-profile.picture.cropper.rotate-left" />
            </StandardButton>
            <StandardButton
              variant="tertiarySpecial"
              onClick={onRotateRight}
              startIcon={<RotateRight className={styles.icon} />}
            >
              <FormattedMessage id="app.user-profile.picture.cropper.rotate-right" />
            </StandardButton>
          </Box>
        </Box>
      </Box>
      <Box display="flex" className={styles.control} p={2} gridGap={32}>
        <StepTwo className={styles.icon} />
        <Box display="flex" flexDirection="column" gridGap={8} width="80%">
          <Box display="flex" alignItems="center" className={styles.controlTitle} height="20px">
            <FormattedMessage id="app.user-profile.picture.cropper.zoom" />
          </Box>
          <Box display="flex" alignItems="center" gridGap={8}>
            <ImageIcon fontSize="small" />
            <Slider value={zoom} min={minZoom} max={maxZoom} step={zoomStep} onChange={handleZoomChange} />
            <ImageIcon fontSize="large" />
          </Box>
        </Box>
      </Box>
      <Box display="flex" className={styles.control} p={2} gridGap={32}>
        <StepThree className={styles.icon} />
        <Box display="flex" alignItems="center" className={styles.controlTitle} height="20px">
          <FormattedMessage id="app.user-profile.picture.cropper.drag" />
        </Box>
      </Box>
    </Box>
  );
};

export default CropperActions;
