// @flow

import React from 'react';
import Modal from '@material-ui/core/Modal';

import Close from '../../Icons/Close';

import styles from './styles.module.scss';

type Props = {
  src: string,
  open: boolean,
  onClose: () => void,
};

export const InsuranceCard = ({ src, open, onClose }: Props) => (
  <Modal onClose={onClose} open={open}>
    <div className={styles.modalContainer}>
      <div className={styles.closeButton}>
        <Close size={18} onClick={onClose} applyBackground={false} />
      </div>
      <img alt="insuranceCard" src={src} className={styles.image} />
    </div>
  </Modal>
);

export default InsuranceCard;
