// @flow

import React from 'react';
import styled from 'styled-components';

import StandardButton from '../../Buttons/Standard';
import RecordButton from '../RecordButton';
import StopButton from '../StopButton';
import Timer from '../Timer';
import Countdown from '../CountDown';

type Props = {
  isVideoInputSupported: boolean,
  isInlineRecordingSupported: boolean,
  thereWasAnError: boolean,
  isRecording: boolean,
  isCameraOn: boolean,
  streamIsReady: boolean,
  isConnecting: boolean,
  isRunningCountdown: boolean,
  countdownTime: number,
  timeLimit: number,
  isReplayingVideo: boolean,
  useVideoInput: boolean,

  onTurnOnCamera: () => void,
  onOpenVideoInput: () => void,
  onStartRecording: () => void,
  onStopRecording: () => void,
  onStopReplaying: () => void,
};

const ActionsWrapper = styled.div`
  position: absolute;
  bottom: 50px;
  left: 0;
  right: 0;
  display: flex;
  align-items: center;
  padding-top: 20px;
  padding-bottom: 30px;
  justify-content: center;
`;

const Actions = ({ onSendVideo, intl: { formatMessage } }: { onSendVideo: () => any, intl: Object }) => ({
  isVideoInputSupported,
  isInlineRecordingSupported,
  thereWasAnError,
  isRecording,
  isCameraOn,
  streamIsReady,
  isConnecting,
  isRunningCountdown,
  isReplayingVideo,
  countdownTime,
  timeLimit,
  useVideoInput,
  onTurnOnCamera,
  onOpenVideoInput,
  onStartRecording,
  onStopRecording,
  onStopReplaying,
}: Props) => {
  const renderContent = () => {
    const shouldUseVideoInput = !isInlineRecordingSupported && isVideoInputSupported;

    if (
      (!isInlineRecordingSupported && !isVideoInputSupported) ||
      thereWasAnError ||
      isConnecting ||
      isRunningCountdown
    ) {
      return null;
    }

    if (isReplayingVideo) {
      return (
        <>
          <StandardButton variant="secondary" onClick={onStopReplaying} data-testid="re-record">
            {formatMessage({ id: 'app.video-back.re-record' })}
          </StandardButton>
          <StandardButton onClick={onSendVideo} data-testid="start-replaying">
            {formatMessage({ id: 'app.video-back.next' })}
          </StandardButton>
        </>
      );
    }

    if (isRecording) {
      return <StopButton type="button" onClick={onStopRecording} data-testid="stop-recording" />;
    }

    if (isCameraOn && streamIsReady) {
      return <RecordButton type="button" onClick={onStartRecording} data-testid="start-recording" />;
    }

    if (useVideoInput) {
      return (
        <StandardButton type="button" onClick={onOpenVideoInput} data-testid="open-input">
          {formatMessage({ id: 'app.video-back.upload.a.video' })}
        </StandardButton>
      );
    }

    return shouldUseVideoInput ? (
      <StandardButton type="button" onClick={onOpenVideoInput} data-testid="open-input">
        {formatMessage({ id: 'app.video-back.record.a.video' })}
      </StandardButton>
    ) : (
      <StandardButton type="button" onClick={onTurnOnCamera} data-testid="turn-on-camera">
        {formatMessage({ id: 'app.video-back.turn.on.camera' })}
      </StandardButton>
    );
  };

  return (
    <div>
      {isRecording && <Timer timeLimit={timeLimit} />}
      {isRunningCountdown && <Countdown countdownTime={countdownTime} />}
      <ActionsWrapper>{renderContent()}</ActionsWrapper>
    </div>
  );
};

export default Actions;
