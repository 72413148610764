import gql from 'graphql-tag';

export const SELF_ENROLLMENT = gql`
  mutation selfEnrollPatient($input: SelfEnrollPatientData!) {
    selfEnrollPatient(input: $input) {
      success
      message
      resetToken
    }
  }
`;

export const SELF_ENROLLMENT_UNSUCCESSFUL_ATTEMPT = gql`
  mutation saveSelfEnrollmentUnsuccessfulAttempt($input: SelfEnrollmentUnsuccessfulAttemptData!) {
    saveSelfEnrollmentUnsuccessfulAttempt(input: $input) {
      success
      message
    }
  }
`;

export const VALIDATE_SELF_ENROLLMENT_PATIENT_IDENTITY = gql`
  mutation validateSelfEnrollmentPatientIdentity($input: ValidateSelfEnrollmentPatientData!) {
    validateSelfEnrollmentPatientIdentity(input: $input) {
      success
      message
      patientInformation {
        name
        deactivationReason
        phoneNumber
        email
        patientId
        attemptId
      }
    }
  }
`;
