// @flow

import withHandlers from 'recompose/withHandlers';

import type { AuthUser } from '../../utils/authentication';

type Props = {
  history: {
    push: (string) => void,
  },
  setSubmissionDetails: ({ loading: boolean, submissions: Array<any> }) => void,
  authUser: AuthUser,
};

export const handlerCreators = () => ({
  handleOnSelect: ({ history: { push }, setSubmissionDetails, authUser }: Props) => (uuid: string) => {
    const { role: { permissions = [] } = {} } = authUser || {};
    const hasPatientSubmissionsPermission = permissions.map(({ name }) => name).includes('patientSubmissions');

    if (hasPatientSubmissionsPermission) {
      setSubmissionDetails({ loading: false, submissions: [] });
      push(`/patient/${uuid}/submissions`);
    } else {
      push(`/patient/${uuid}/medications`);
    }
  },
});

export default withHandlers(handlerCreators);
