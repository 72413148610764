// @flow
import React, { useState, useMemo } from 'react';
import { useIntl, FormattedMessage } from 'react-intl';
import Cropper from 'react-easy-crop';
import Box from '@material-ui/core/Box';

import FormModal from '../Modals/Form';
import StandardButton from '../Buttons/Standard';
import FileDropzone from '../FormControls/FileDropzone';
import CropperActions from './CropperActions';
import { getCroppedImg } from './utils';
import {
  MIN_ZOOM,
  MAX_ZOOM,
  ZOOM_STEP,
  ROTATION_STEP,
  CROP_SIZE,
  CROP_SHAPE,
  DEFAULT_CROP,
  DEFAULT_ZOOM,
  DEFAULT_ROTATION,
  ACCEPTED_FILE_TYPES,
  CROPPED_IMAGE_TYPE,
  MAX_FILE_SIZE,
  MAX_FILE_NUMBER,
} from './constants';

import styles from './styles.module.scss';

type Props = {
  open: boolean,
  onSaveImage: (File) => void,
  onClose: () => void,
};

const ProfilePictureModal = ({ open, onSaveImage, onClose }: Props) => {
  const { formatMessage } = useIntl();
  const [fileToUpload, setFileToUpload] = useState(null);
  const [crop, setCrop] = useState(DEFAULT_CROP);
  const [zoom, setZoom] = useState(DEFAULT_ZOOM);
  const [rotation, setRotation] = useState(DEFAULT_ROTATION);
  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);

  const image = useMemo(() => (fileToUpload ? URL.createObjectURL(fileToUpload) : null), [fileToUpload]);

  const handleCropComplete = (_croppedArea, pixels) => {
    setCroppedAreaPixels(pixels);
  };

  const handleRotateRight = () => {
    setRotation(rotation + ROTATION_STEP);
  };

  const handleRotateLeft = () => {
    setRotation(rotation - ROTATION_STEP);
  };

  const handleFileChange = (files) => {
    if (!files) {
      setFileToUpload(null);
      return;
    }

    const [file] = files;
    setFileToUpload(file);
  };

  const handleClose = () => {
    setFileToUpload(null);
    onClose();
  };

  const getProcessedImage = async () => {
    if (!image || !croppedAreaPixels) return null;

    const croppedImage = await getCroppedImg({ imageSrc: image, pixelCrop: croppedAreaPixels, rotation });

    if (!croppedImage) return null;

    const imageFile = new File([croppedImage.file], `img-${Date.now()}.${CROPPED_IMAGE_TYPE}`, {
      type: CROPPED_IMAGE_TYPE,
    });

    return imageFile;
  };

  const resetStates = () => {
    setFileToUpload(null);
    setCrop(DEFAULT_CROP);
    setZoom(DEFAULT_ZOOM);
    setRotation(DEFAULT_ROTATION);
    setCroppedAreaPixels(null);
  };

  const handleFileUpload = async () => {
    const newImg = await getProcessedImage();
    if (newImg) {
      onSaveImage(newImg);
    }

    onClose();
    resetStates();
  };

  return (
    <FormModal
      open={open}
      title={formatMessage({ id: 'app.user-profile.picture.add' })}
      onClose={handleClose}
      customClassName={styles.modal}
      md
    >
      <Box p={4}>
        {!image && (
          <FileDropzone
            fileName={fileToUpload?.name}
            maxFiles={MAX_FILE_NUMBER}
            maxSize={MAX_FILE_SIZE}
            accept={ACCEPTED_FILE_TYPES}
            customClassName={styles.fileDropzone}
            onChange={(files) => handleFileChange(files)}
          />
        )}
        {image && (
          <>
            <div className={styles.cropperContainer}>
              <Cropper
                image={image ?? ''}
                aspect={1}
                crop={crop}
                zoom={zoom}
                rotation={rotation}
                onCropChange={setCrop}
                onCropComplete={handleCropComplete}
                onZoomChange={setZoom}
                setRotation={setRotation}
                cropSize={CROP_SIZE}
                cropShape={CROP_SHAPE}
              />
            </div>
            <CropperActions
              zoom={zoom}
              minZoom={MIN_ZOOM}
              maxZoom={MAX_ZOOM}
              zoomStep={ZOOM_STEP}
              onZoomChange={setZoom}
              onRotateLeft={handleRotateLeft}
              onRotateRight={handleRotateRight}
            />
          </>
        )}
      </Box>
      <Box className={styles.footer} display="flex" justifyContent="flex-end">
        <StandardButton variant="secondary" onClick={handleClose}>
          <FormattedMessage id="app.forms.cancel" />
        </StandardButton>
        <StandardButton onClick={handleFileUpload} disabled={!image}>
          <FormattedMessage id="app.user-profile.picture.upload" />
        </StandardButton>
      </Box>
    </FormModal>
  );
};

export default ProfilePictureModal;
