// @flow

import React, { Suspense } from 'react';

import LoadingIndicator from '../../components/LoadingIndicator';

const RegimenTemplate = React.lazy(() => import('./index'));

const RegimenTemplateLoadable = () => (
  <div>
    <Suspense fallback={<LoadingIndicator />}>
      <RegimenTemplate />
    </Suspense>
  </div>
);

export default RegimenTemplateLoadable;
