// @flow

import gql from 'graphql-tag';

export const ADD_USER = gql`
  mutation addUser($input: CreateUserData!) {
    createUser(input: $input) {
      id
      uuid
      firstName
      middleName
      role {
        displayName
      }
      entities {
        uuid
        name
      }
      analyticGroups {
        id
        name
        entityId
        entityName
      }
      lastName
      email
      phone
    }
  }
`;

export const UPDATE_USER = gql`
  mutation updateUser($input: UpdateUserData!) {
    updateUser(input: $input) {
      id
      uuid
      firstName
      middleName
      role {
        id
        uuid
        name
        displayName
      }
      entities {
        uuid
        name
      }
      analyticGroups {
        id
        name
        entityId
        entityName
      }
      lastName
      email
      phone
      chatDisplayName
      profilePictureUploadLink
      bio
      profileVideosUploadLinks {
        userProfileQuestionId
        uploadLink
      }
    }
  }
`;
