// @flow

import React from 'react';
import { Redirect } from 'react-router-dom';

import usePath from '../usePath';
import useScrollToTop from '../useScrollToTop';
import useSelfEnrollment from '../../../hooks/useSelfEnrollment';
import PageHeader from '../PageHeader';
import PhoneForm from './PhoneForm';
import ForgotAccount from './ForgotAccount';
import HiFi from './HiFi';
import { MESSAGES, PATHS } from '../constants';

import styles from './styles.module.scss';

const RESPONSES = {
  [MESSAGES.ACCOUNT_PENDING_TO_BE_COMPLETED]: 'found.pending-account',
  [MESSAGES.THANK_YOU]: 'found.pending-account',
  [MESSAGES.PATIENT_FOUND]: 'found.account-created',
};

const FoundPatient = () => {
  useScrollToTop();
  const getPath = usePath();
  const { message, patientData } = useSelfEnrollment();

  if (!message) {
    return <Redirect to={getPath(PATHS.IDENTIFY)} />;
  }

  const name = patientData?.name ?? '';
  const responseMessage = RESPONSES[message];

  return (
    <div className={styles.container}>
      {message === MESSAGES.PATIENT_FOUND && (
        <div className={styles.imageWrapper}>
          <HiFi />
        </div>
      )}
      <PageHeader
        headerId="app.self-enroll.found.header"
        subHeaderId={`app.self-enroll.${responseMessage}.sub-header`}
        values={{ name }}
      />
      {message === MESSAGES.ACCOUNT_PENDING_TO_BE_COMPLETED && <PhoneForm className={styles.phoneForm} />}
      {message === MESSAGES.PATIENT_FOUND && <ForgotAccount />}
    </div>
  );
};

export default FoundPatient;
