// @flow
import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

const RotateRight = (props: Object) => (
  <SvgIcon viewBox="0 0 18 18" {...props}>
    <path
      d="M12.5085 4.78814L18 10.2881L12.5 15.7797L7 10.2881L12.5085 4.78814ZM15.6017 10.2881L12.5 7.18644L9.40678 10.2881L12.5085 13.3898L15.6017 10.2881ZM2.32203 4.98305C3.80509 3.49153 5.76271 2.74576 7.71186 2.74576V0L11.3051 3.59322L7.71186 7.18644V4.44068C6.19492 4.44068 4.67797 5.01695 3.51695 6.17797C1.20339 8.49153 1.20339 12.2542 3.51695 14.5678C4.67797 15.7288 6.19492 16.3051 7.71186 16.3051C8.5339 16.3051 9.35593 16.1271 10.1186 15.7881L11.3814 17.0508C10.2542 17.678 8.98305 18 7.71186 18C5.76271 18 3.80509 17.2542 2.32203 15.7627C-0.661016 12.7881 -0.661016 7.95763 2.32203 4.98305Z"
      fill="#2863EF"
    />
  </SvgIcon>
);

export default RotateRight;
