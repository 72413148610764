// @flow
import React, { useMemo } from 'react';
import { FormattedMessage } from 'react-intl';

import useSelfEnrollment from '../../../hooks/useSelfEnrollment';
import SceneIcon from '../../Icons/SceneIcon';
import LanguageToggle from '../../LanguageToggle';

import styles from './styles.module.scss';

type Props = {
  language: string,
  setLanguage: (language: string) => void,
};

const Header = (props: Props) => {
  const { language, setLanguage } = props;
  const { entityData } = useSelfEnrollment();
  const { orgPhone = '', selfEnrollment = {} } = entityData || {};
  const { selfEnrollmentSupportNumber } = selfEnrollment;
  const contactNumber = selfEnrollmentSupportNumber || orgPhone;

  const phoneNumber = useMemo(() => {
    const cleaned = contactNumber.replace(/[^0-9]/g, '');
    const match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
    return match ? `${match[1]}-${match[2]}-${match[3]}` : '';
  }, [contactNumber]);

  return (
    <div className={styles.headerContainer}>
      <div className={styles.header}>
        <SceneIcon />
        <div className={styles.headerRight}>
          <LanguageToggle language={language} setLanguage={setLanguage} shortStyle />
          <div className={styles.callSupport}>
            <FormattedMessage id="app.self-enroll.callsupport" />: {phoneNumber}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Header;
