// @flow

import React, { Suspense } from 'react';

import LoadingIndicator from '../../components/LoadingIndicator';

const SecurityLogs = React.lazy(() => import('./index'));

const SecurityLogsLoadable = () => (
  <div>
    <Suspense fallback={<LoadingIndicator />}>
      <SecurityLogs />
    </Suspense>
  </div>
);

export default SecurityLogsLoadable;
