// @flow

import React, { useMemo } from 'react';
import { FormattedMessage } from 'react-intl';
import { parsePhoneNumber } from 'libphonenumber-js';
import { Switch, Route } from 'react-router-dom';
import startsWith from 'lodash/startsWith';

import StandardButton from '../../../components/Buttons/Standard';
import useSelfEnrollment from '../../../hooks/useSelfEnrollment';

import styles from './styles.module.scss';

const InfoAndLinks = () => {
  const { entityData } = useSelfEnrollment();
  const { orgPhone = '', selfEnrollment = {} } = entityData || {};
  const { cobrandedWebsiteProgramLink = '', selfEnrollmentSupportNumber } = selfEnrollment;
  const contactNumber = selfEnrollmentSupportNumber || orgPhone;

  const phoneNumber = useMemo(() => {
    if (!contactNumber) return '';
    if (startsWith(contactNumber, '+')) parsePhoneNumber(contactNumber).formatInternational();
    return parsePhoneNumber(`+1${contactNumber}`).formatNational();
  }, [contactNumber]);

  return (
    <Switch>
      <Route path="/self-enrollment/:slug?/(identify|found|enrolled)">
        <div className={styles.container}>
          <Switch>
            <Route path="/self-enrollment/:slug?/identify">
              <>
                <div className={styles.question}>
                  <FormattedMessage id="app.self-enroll.still-have-questions" />
                </div>
                <div className={styles.learnMoreButton}>
                  <a href={cobrandedWebsiteProgramLink}>
                    <StandardButton
                      classes={{ outlined: styles.customButtonStyle }}
                      isPill
                      extraRootClasses={styles.button}
                      variant="secondary"
                    >
                      <FormattedMessage id="app.self-enroll.learn-more" />
                    </StandardButton>
                  </a>
                </div>
              </>
            </Route>
          </Switch>

          <div className={styles.callSupportButton}>
            <a href={`tel:${phoneNumber}`}>
              <StandardButton
                classes={{ outlined: styles.customButtonStyle }}
                isPill
                extraRootClasses={styles.button}
                variant="secondary"
              >
                <FormattedMessage id="app.self-enroll.call-support" />
              </StandardButton>
            </a>
          </div>
        </div>
      </Route>
    </Switch>
  );
};

export default InfoAndLinks;
