// @flow

import React from 'react';

import Deactivated from './Images/Deactivated';
import WeCantFindYou from './Images/WeCantFindYou';
import NeedMoreInfo from './Images/NeedMoreInfo';
import { SCENARIOS } from '../../constants';

import styles from './styles.module.scss';

type Props = {
  scenario: string,
};

const renderImage = (scenario: string) => {
  if (scenario === SCENARIOS.DEACTIVATED) {
    return <Deactivated />;
  }
  if (scenario === SCENARIOS.NOT_FOUND) {
    return <WeCantFindYou />;
  }

  return <NeedMoreInfo />;
};

const Image = ({ scenario }: Props) => <div className={styles.image}>{renderImage(scenario)}</div>;

export default Image;
