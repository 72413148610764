// @flow

import gql from 'graphql-tag';

export const GET_USER_ASSIGNMENTS = gql`
  query userAssignments($uuid: ID!) {
    user(uuid: $uuid) {
      id
      careTeamPatients {
        id
        uuid
        firstName
        lastName
        entity {
          id
        }
      }
      reviewedPatients {
        id
        uuid
        firstName
        lastName
        entity {
          id
        }
      }
      appointments {
        id
        status
        patient {
          id
          uuid
          firstName
          lastName
          entity {
            id
          }
        }
      }
    }
    assignmentsCountByEntity(uuid: $uuid) {
      patientsCount {
        entityId
        count
      }
      upcomingAppointmentsCount {
        entityId
        count
      }
    }
  }
`;
