// @flow

import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

function DoseYes(props: Object) {
  return (
    <SvgIcon viewBox="0 0 22 22" {...props}>
      <g fill="none" fillRule="evenodd">
        <circle cx="11" cy="11" r="11" fill="#046b55" />
        <polygon
          fill="#FFF"
          stroke="#FFF"
          strokeWidth=".5"
          points="6.86 10.51 10.116 13.405 15.887 6.769 16.923 7.526 10.214 15.231 5.923 11.401"
        />
      </g>
    </SvgIcon>
  );
}

export default DoseYes;
