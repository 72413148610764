// @flow

import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

const ForwardRedo = (props: {}) => (
  <SvgIcon viewBox="0 0 24 24" {...props}>
    <path
      d="M14.5499 14.4533L22.2512 14.5073L22.2514 6.99999"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
    />
    <path
      d="M22.2515 14.5075L18.7878 10.8576C13.9962 5.80822 5.57969 7.46177 3.05451 13.9486V13.9486"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
    />
  </SvgIcon>
);

export default ForwardRedo;
