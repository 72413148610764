// @flow
import React, { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import Menu from '@material-ui/core/Menu';
import Box from '@material-ui/core/Box';
import MenuItem from '@material-ui/core/MenuItem';

import EditIcon from '../../../../Icons/EditPencil';
import TrashIcon from '../../../../Icons/Trash';
import StandardButton from '../../../../Buttons/Standard';
import ConfirmModal from '../../../../Modals/Confirm';
import useAnchor from '../../../../../hooks/useAnchor';

type Props = {
  onUpload: () => void,
  onRecord: () => void,
  onDelete: () => void,
};

const EditVideoMenu = ({ onUpload, onRecord, onDelete }: Props) => {
  const { anchorEl, handleClose: handleCloseMenu, handleOpen: handleOpenMenu, open: menuOpen } = useAnchor();
  const [confirmationOpen, setConfirmationOpen] = useState(false);

  const handleOpenConfirmation = () => setConfirmationOpen(true);

  const handleCloseConfirmation = () => setConfirmationOpen(false);

  const handleUpload = () => {
    onUpload();
    handleCloseMenu();
  };

  const handleRecord = () => {
    onRecord();
    handleCloseMenu();
  };

  const handleDelete = () => {
    onDelete();
    handleCloseConfirmation();
  };

  return (
    <Box>
      <Box display="flex" gridGap={8}>
        <StandardButton variant="tertiary" startIcon={<EditIcon />} onClick={handleOpenMenu} />
        <StandardButton variant="tertiary" startIcon={<TrashIcon />} onClick={handleOpenConfirmation} />
      </Box>
      <Menu
        anchorEl={anchorEl}
        open={menuOpen}
        onClose={handleCloseMenu}
        getContentAnchorEl={null}
        anchorOrigin={{ vertical: 'bottom' }}
      >
        <MenuItem onClick={handleUpload}>
          <FormattedMessage id="app.user-profile.video.upload" />
        </MenuItem>
        <MenuItem onClick={handleRecord}>
          <FormattedMessage id="app.user-profile.video.record" />
        </MenuItem>
      </Menu>
      <ConfirmModal
        open={confirmationOpen}
        title="app.user-profile.video.remove-modal.title"
        message="app.user-profile.video.remove-modal.message"
        confirmLabel="app.user-profile.video.remove-modal.confirm"
        onCancel={handleCloseConfirmation}
        onConfirm={handleDelete}
        size="medium"
      />
    </Box>
  );
};

export default EditVideoMenu;
