// @flow
import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

const StepTwo = (props: Object) => (
  <SvgIcon viewBox="0 0 20 20" {...props}>
    <path
      d="M7 5V7H11V9H9C8.46957 9 7.96086 9.21071 7.58579 9.58579C7.21071 9.96086 7 10.4696 7 11V15H9H13V13H9V11H11C11.5304 11 12.0391 10.7893 12.4142 10.4142C12.7893 10.0391 13 9.53043 13 9V7C13 6.46957 12.7893 5.96086 12.4142 5.58579C12.0391 5.21071 11.5304 5 11 5H7ZM10 0C11.3132 0 12.6136 0.258658 13.8268 0.761205C15.0401 1.26375 16.1425 2.00035 17.0711 2.92893C17.9997 3.85752 18.7362 4.95991 19.2388 6.17317C19.7413 7.38642 20 8.68678 20 10C20 12.6522 18.9464 15.1957 17.0711 17.0711C15.1957 18.9464 12.6522 20 10 20C8.68678 20 7.38642 19.7413 6.17317 19.2388C4.95991 18.7362 3.85752 17.9997 2.92893 17.0711C1.05357 15.1957 0 12.6522 0 10C0 7.34784 1.05357 4.8043 2.92893 2.92893C4.8043 1.05357 7.34784 0 10 0Z"
      fill="black"
    />
  </SvgIcon>
);

export default StepTwo;
