// @flow
import React, { useState } from 'react';
import { useIntl, FormattedMessage } from 'react-intl';
import Box from '@material-ui/core/Box';

import FormModal from '../../../../Modals/Form';
import StandardButton from '../../../../Buttons/Standard';
import FileDropzone from '../../../../FormControls/FileDropzone';
import VideoBackRecorder from '../../../../VideoBackRecorder';

import { ACCEPTED_FILE_TYPES, MAX_FILE_SIZE, MAX_FILE_NUMBER, VIDEO_LIMIT_SECONDS } from './constants';

import styles from './styles.module.scss';

type Props = {
  open: boolean,
  mode?: string,
  onSaveVideo: (File) => void,
  onClose: () => void,
};

const AddVideoModal = ({ open, mode = 'record', onSaveVideo, onClose }: Props) => {
  const { formatMessage } = useIntl();
  const [recordedVideoInfo, setRecordedVideoInfo] = useState(null);

  const resetStates = () => {
    setRecordedVideoInfo(null);
  };

  const handleClose = () => {
    resetStates();
    onClose();
  };

  const handleFileUpload = (files: Array<File> | null) => {
    if (!files) return;

    const [file] = files;
    onSaveVideo(file);
    handleClose();
  };

  const handleSaveVideo = () => {
    if (recordedVideoInfo) {
      const { videoBlob } = recordedVideoInfo;
      const file = new File([videoBlob], 'recorded.webm', { type: 'video/webm' });
      onSaveVideo(file);
    }

    handleClose();
  };

  return (
    <FormModal
      open={open}
      title={formatMessage({ id: 'app.user-profile.video.add' })}
      onClose={handleClose}
      customClassName={styles.modal}
      md
    >
      <Box p={4}>
        {mode === 'upload' && (
          <FileDropzone
            maxFiles={MAX_FILE_NUMBER}
            maxSize={MAX_FILE_SIZE}
            accept={ACCEPTED_FILE_TYPES}
            customClassName={styles.fileDropzone}
            onChange={handleFileUpload}
          />
        )}
        {mode === 'record' && (
          <div className={styles.videoRecorderWrapper}>
            <VideoBackRecorder
              hidden={false}
              videoBackTimeLimitSeconds={VIDEO_LIMIT_SECONDS}
              onSendVideo={handleSaveVideo}
              onChange={setRecordedVideoInfo}
            />
          </div>
        )}
      </Box>
      {mode === 'upload' && (
        <Box className={styles.footer} display="flex" justifyContent="flex-end">
          <StandardButton variant="secondary" onClick={handleClose}>
            <FormattedMessage id="app.forms.cancel" />
          </StandardButton>
        </Box>
      )}
    </FormModal>
  );
};

export default AddVideoModal;
