// @flow
import React, { useState } from 'react';
import Box from '@material-ui/core/Box';
import { Map } from 'immutable';

import ProfilePicturePlaceholder from '../../../Icons/ProfilePicturePlaceholder';
import CameraIcon from '../../../Icons/Camera';
import EditPencil from '../../../Icons/EditPencil';
import ImageCropper from '../../../ImageCropper';
import ProfilePictureMenu from './ProfilePictureMenu';
import useAnchor from '../../../../hooks/useAnchor';

import styles from './styles.module.scss';

type Props = {
  input: {
    value: Object,
    onChange: (e: any) => void,
  },
};

const ProfilePicture = ({ input }: Props) => {
  const { value, onChange } = input;
  const { anchorEl, handleClose: handleCloseMenu, handleOpen: handleOpenMenu, open: menuOpen } = useAnchor();
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [croppedImage, setCroppedImage] = useState(null);

  const handleModalOpen = () => {
    setDeleteModalOpen(true);
    handleCloseMenu();
  };

  const handleModalClose = () => {
    setDeleteModalOpen(false);
    handleCloseMenu();
  };

  const handleSaveImage = (image: File) => {
    if (!image) return;

    setCroppedImage(image);
    onChange(Map({ id: null, location: image.name, file: image }));
  };

  const handleConfirmDelete = () => {
    setCroppedImage(null);
    onChange(null);
    handleModalClose();
  };

  const profilePictureLocation = value ? value.get('location') : null;
  const croppedImageUrl = croppedImage ? URL.createObjectURL(croppedImage) : null;

  return (
    <>
      <Box onClick={profilePictureLocation ? handleOpenMenu : handleModalOpen} mt={4}>
        {croppedImageUrl || profilePictureLocation ? (
          <div className={styles.pictureContainer}>
            <img className={styles.image} src={croppedImageUrl ?? profilePictureLocation} alt="profile" />
            <Box display="flex" justifyContent="center" alignItems="center" className={styles.overlay}>
              <EditPencil fill="white" />
            </Box>
          </div>
        ) : (
          <div className={styles.pictureContainer}>
            <ProfilePicturePlaceholder />
            <Box display="flex" justifyContent="center" alignItems="center" className={styles.overlay}>
              <CameraIcon />
            </Box>
          </div>
        )}
      </Box>
      <ImageCropper open={deleteModalOpen} onSaveImage={handleSaveImage} onClose={handleModalClose} />
      <ProfilePictureMenu
        anchorEl={anchorEl}
        open={menuOpen}
        onClose={handleCloseMenu}
        onEdit={handleModalOpen}
        onDelete={handleConfirmDelete}
      />
    </>
  );
};

export default ProfilePicture;
