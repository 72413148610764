// @flow

import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

function Video(props: {}) {
  return (
    <SvgIcon width={13} height={9} viewBox="0 0 13 9" {...props}>
      <path d="M8.49779 1.75C8.49779 1.75 8.49779 3.44589 8.49779 4.57407C8.49779 5.70225 8.49779 7.25 8.49779 7.25H1.67712V4.57407V1.75H8.49779ZM8.96864 0.5H1.20627C0.817823 0.5 0.5 0.8 0.5 1.16667V7.83333C0.5 8.2 0.817823 8.5 1.20627 8.5H8.96864C9.35709 8.5 9.67491 8.2 9.67491 7.83333V5.5L12.5 8.16667V0.833333L9.67491 3.5V1.16667C9.67491 0.8 9.35709 0.5 8.96864 0.5Z" />
    </SvgIcon>
  );
}

export default Video;
