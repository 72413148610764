// @flow

import React, { type Node, useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import classNames from 'classnames';
import noop from 'lodash/noop';
import startsWith from 'lodash/startsWith';

import TextField from '@material-ui/core/TextField';
import InputLabel from '@material-ui/core/InputLabel';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';

import { VARIANTS } from './constants';

import styles from './styles.module.scss';

type Props = {
  input?: {
    name?: string,
    value?: any,
    onChange?: (e: any) => void,
    onBlur?: (e: any) => void,
  },
  type?: string | null,
  label?: string | null,
  meta?: {
    touched?: boolean,
    error?: string,
  },
  maxLength?: number,
  step?: number,
  min?: string,
  max?: string,
  autoCapitalize?: string,
  addlInputProps?: Object,
  extraRootClasses?: string,
  helperText?: Node,
  placeholder?: string,
  disabled?: boolean,
  variant?: 'short' | 'long' | 'phone',
  minRows?: number,
  maxRows?: number,
  country?: string,
  required?: boolean,
  description?: string,
};

const InputField = (props: Props) => {
  const {
    label = '',
    maxLength = -1,
    step,
    min,
    max,
    input: { name = '', value = '', onChange = noop, onBlur = noop } = {},
    type = 'text',
    meta,
    extraRootClasses,
    autoCapitalize,
    addlInputProps = {},
    helperText = null,
    placeholder = '',
    disabled = false,
    variant = 'short',
    minRows = 4,
    maxRows = 4,
    country = 'us',
    required = false,
    description,
    ...custom
  } = props;
  const { formatMessage } = useIntl();
  const { touched = false, error = '' } = meta || {};
  const optional = {
    ...(name ? { id: name } : null),
    value,
    ...(extraRootClasses ? { className: extraRootClasses } : null),
  };
  const [prefixedPhone, setPrefixedPhone] = useState(value);
  const fieldVariant = VARIANTS.includes(variant) ? variant : 'short';
  const isPhoneField = fieldVariant === 'phone';
  const isLongField = fieldVariant === 'long';

  useEffect(() => {
    if (!startsWith(value, '+') && !startsWith(prefixedPhone, '+')) setPrefixedPhone(`+1${value || ''}`);
    else if (value && startsWith(value, '+')) setPrefixedPhone(value);
    else if (!value) setPrefixedPhone('+');
  }, [value, prefixedPhone]);

  return (
    <FormControl
      data-testid={`${fieldVariant}-input-field`}
      error={touched && Boolean(error)}
      classes={{ root: extraRootClasses }}
      fullWidth
      required={required}
    >
      {isPhoneField ? (
        <>
          <div className={styles.formControlRoot}>
            {/* eslint-disable-next-line jsx-a11y/label-has-for */}
            <label
              htmlFor={name}
              className={classNames(styles.inputLabelRoot, styles.phoneInputLabel, {
                [styles.inputLabelRootDisabled]: disabled,
              })}
            >
              {formatMessage({ id: label })}
              {required && <div className={styles.asterisk}>*</div>}
            </label>
            <PhoneInput
              value={prefixedPhone}
              containerClass={styles.phoneContainer}
              inputClass={classNames(styles.inputInput, { [styles.inputError]: touched && Boolean(error) })}
              country={country}
              inputStyle={{ width: '100%' }}
              inputProps={{
                ...(name ? { id: name, name, required } : null),
              }}
              onChange={onChange}
              onBlur={onBlur}
              {...meta}
              {...custom}
              data-testid="input-field-phone"
            />
          </div>
          {touched && Boolean(error) && (
            <div className={classNames(styles.helperTextRoot, styles.helperTextError)}>
              {formatMessage({ id: error })}
            </div>
          )}
        </>
      ) : (
        <>
          {description && (
            <>
              <InputLabel
                htmlFor={name}
                classes={{
                  root: classNames(styles.formControlRoot, styles.inputLabelRoot, {
                    [styles.inputLabelRootDisabled]: disabled,
                  }),
                  formControl: styles.inputLabelFormControl,
                  shrink: styles.inputLabelShrink,
                }}
                required={required}
                shrink
              >
                {label}
              </InputLabel>
              <FormHelperText classes={{ root: classNames(styles.helperTextRoot, styles.description) }}>
                {description}
              </FormHelperText>
            </>
          )}
          <TextField
            value={value}
            name={name}
            onChange={onChange}
            onBlur={onBlur}
            type={type}
            label={description ? '' : label}
            placeholder={placeholder}
            data-testid={`input-field-${fieldVariant}`}
            error={touched && Boolean(error)}
            helperText={touched && Boolean(error) ? formatMessage({ id: error }) : helperText}
            classes={{
              root: classNames(styles.formControlRoot, {
                [styles.formControlRootLong]: isLongField,
                [styles.formControlRootError]: touched && Boolean(error),
              }),
            }}
            InputLabelProps={{
              shrink: true,
              classes: {
                root: classNames(styles.inputLabelRoot, { [styles.inputLabelRootDisabled]: disabled }),
                formControl: styles.inputLabelFormControl,
                shrink: styles.inputLabelShrink,
              },
            }}
            InputProps={{
              autoComplete: 'off',
              disableUnderline: true,
              classes: {
                input: classNames(styles.inputInput, {
                  [styles.inputError]: touched && Boolean(error),
                  [styles.inputLong]: isLongField,
                }),
                error: styles.inputError,
                disabled: styles.inputDisabled,
              },
              inputProps: { maxLength, autoCapitalize, min, max, step },
              ...addlInputProps,
            }}
            FormHelperTextProps={{
              classes: {
                root: styles.helperTextRoot,
                error: styles.helperTextError,
              },
            }}
            fullWidth
            disabled={disabled}
            multiline={isLongField}
            minRows={minRows}
            maxRows={maxRows}
            required={required}
            {...optional}
            {...custom}
          />
          {isLongField && maxLength > 0 && (
            <div className={styles.maxLengthText}>{`${value ? value.length : 0}/${maxLength}`}</div>
          )}
        </>
      )}
    </FormControl>
  );
};

export default InputField;
