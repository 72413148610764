// @flow

import React, { useState, useEffect } from 'react';
import moment from 'moment-timezone';
import classnames from 'classnames';
import { useForm, Controller } from 'react-hook-form';
import { FormattedMessage, useIntl } from 'react-intl';

import InputField from '../../../../components/FormControls/InputField';
import StandardButton from '../../../../components/Buttons/Standard';
import Label from './Label';
import Description from './Description';
import InsuranceCard from '../../../../components/Modals/InsuranceCard';
import OrganizationDisplay from './OrganizationDisplay';
import OrganizationSelector from './OrganizationSelector';
import useSelfEnrollment from '../../../../hooks/useSelfEnrollment';

import styles from './styles.module.scss';

type Props = {
  onSubmit: (input: Object) => Promise<void>,
};

function formatDate(dateString) {
  let formattedDate = dateString.replace(/\D/g, '');
  if (formattedDate.length > 0) {
    formattedDate = formattedDate.match(/(\d{1,2})(\d{0,2})(\d{0,4})/);
    if (formattedDate[1]) {
      formattedDate =
        formattedDate[1] +
        (formattedDate[2] ? `/${formattedDate[2]}` : '') +
        (formattedDate[3] ? `/${formattedDate[3]}` : '');
    }
  }
  return formattedDate;
}

const Form = ({ onSubmit }: Props) => {
  const { formatMessage } = useIntl();
  const { entityData, formData, setFormData, isOrganizationDefined } = useSelfEnrollment();
  const [openInsuranceModal, setOpenInsuranceModal] = useState(false);

  const {
    watch,
    control,
    handleSubmit,
    formState: { errors, isValid },
  } = useForm({
    defaultValues: {
      ...formData,
      dateOfBirth:
        formData.dateOfBirth?.length > 0 ? moment(formData.dateOfBirth, 'YYYY-MM-DD').format('MM/DD/YYYY') : '',
    },
    mode: 'all',
  });

  const entitySuffix = watch('entitySuffix');

  useEffect(() => {
    setFormData((oldData) => ({ ...oldData, entitySuffix }));
  }, [entitySuffix, setFormData]);

  const handleOnSubmit = handleSubmit(async (data) => {
    const { dateOfBirth } = data;
    const formattedDate = moment(dateOfBirth, 'MM/DD/YYYY').format('YYYY-MM-DD');

    setFormData({ ...data, dateOfBirth: formattedDate });
    await onSubmit({
      ...data,
      dateOfBirth: formattedDate,
    });
  });

  const handleDateChange = (event, field) => {
    const formattedDate = formatDate(event.target.value);
    field.onChange(formattedDate);
  };

  const otherFieldsDisabled = !entityData;

  return (
    <form onSubmit={handleOnSubmit}>
      <div className={styles.formContainer}>
        <div className={styles.inputContainer}>
          <Label required text={formatMessage({ id: 'app.self-enroll.identify.form.insurance-title' })} />
          {isOrganizationDefined && <OrganizationDisplay />}
          {!isOrganizationDefined && (
            <Controller
              name="entitySuffix"
              control={control}
              rules={{ required: 'app.self-enroll.identify.form.field-required' }}
              render={({ value, onChange }) => <OrganizationSelector value={value} onChange={onChange} />}
            />
          )}
        </div>

        <div className={styles.inputContainer}>
          <Label text={formatMessage({ id: 'app.self-enroll.identify.form.memberid-title' })} required />
          <Description
            text={formatMessage({ id: 'app.self-enroll.identify.form.memberid-sub-title' })}
            onClick={() => setOpenInsuranceModal(true)}
          />
          <Controller
            name="memberId"
            control={control}
            rules={{ required: 'app.self-enroll.identify.form.field-required', minLength: 1 }}
            render={(field) => (
              <InputField
                input={field}
                meta={{ touched: !!errors.memberId, error: errors?.memberId?.message ?? null }}
                disabled={otherFieldsDisabled}
              />
            )}
          />
        </div>

        <div className={styles.inputContainer}>
          <Label text={formatMessage({ id: 'app.self-enroll.identify.form.first-name-title' })} required />
          <Controller
            name="firstName"
            control={control}
            rules={{ required: 'app.self-enroll.identify.form.field-required', minLength: 1 }}
            render={(field) => (
              <InputField
                input={field}
                meta={{ touched: !!errors.firstName, error: errors?.firstName?.message ?? null }}
                disabled={otherFieldsDisabled}
              />
            )}
          />
        </div>

        <div className={styles.inputContainer}>
          <Label text={formatMessage({ id: 'app.self-enroll.identify.form.last-name-title' })} required />
          <Controller
            name="lastName"
            control={control}
            rules={{ required: 'app.self-enroll.identify.form.field-required', minLength: 1 }}
            render={(field) => (
              <InputField
                input={field}
                meta={{ touched: !!errors.lastName, error: errors?.lastName?.message ?? null }}
                disabled={otherFieldsDisabled}
              />
            )}
          />
        </div>

        <div className={styles.inputContainer}>
          <Label text={formatMessage({ id: 'app.self-enroll.identify.form.dob-title' })} required />
          <Controller
            name="dateOfBirth"
            control={control}
            rules={{
              required: 'app.self-enroll.identify.form.field-required',
              validate: (value) => {
                if (value.length !== 10) return 'app.self-enroll.identify.form.valid-date';
                const date = moment(value, 'MM/DD/YYYY');
                if (!moment(date).isValid()) {
                  return 'app.self-enroll.identify.form.valid-date';
                }

                const now = moment.now();
                if (!date.isSameOrBefore(now)) {
                  return 'app.self-enroll.identify.form.future-date';
                }

                return true;
              },
            }}
            render={(field) => (
              <InputField
                input={field}
                meta={{ touched: !!errors.dateOfBirth, error: errors?.dateOfBirth?.message ?? null }}
                placeholder="MM/DD/YYYY"
                onChange={(e) => handleDateChange(e, field)}
                disabled={otherFieldsDisabled}
              />
            )}
          />
        </div>

        <input type="submit" style={{ display: 'none' }} />
        <StandardButton
          disabled={!isValid}
          extraRootClasses={classnames(styles.button, {
            [styles.buttonDisabled]: !isValid,
          })}
          isPill
          variant="primary"
          onClick={handleOnSubmit}
        >
          <FormattedMessage id="app.self-enroll.identify.form.submit" />
        </StandardButton>
        <InsuranceCard
          src={entityData?.selfEnrollment?.sampleInsuranceCard ?? ''}
          open={openInsuranceModal}
          onClose={() => setOpenInsuranceModal(false)}
        />
      </div>
    </form>
  );
};

export default Form;
