// @flow

import React from 'react';
import Box from '@material-ui/core/Box';
import Chip from '@material-ui/core/Chip';

import Tooltip from '../Tooltip';

import { chip as rootStyle, chipLabel as labelStyle } from './styles.module.scss';

type Props = {
  count: number | string,
  color: string,
  backgroundColor: string,
  type?: string,
  showTooltip?: boolean,
};

const AlertChip = ({ count, color, backgroundColor, type = 'unknown', showTooltip = false, ...rest }: Props) => (
  <Tooltip text={`app.tooltip.alerts.${type}`} values={{ count }} hidden={!showTooltip}>
    <Box display="flex" justifyContent="center" alignItems="center" gridColumnGap={6}>
      <Chip label="" classes={{ root: rootStyle }} style={{ backgroundColor, color }} {...rest} />
      <span className={labelStyle}>{Number(count) > 9 ? '9+' : count}</span>
    </Box>
  </Tooltip>
);

export default AlertChip;
