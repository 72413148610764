// @flow

import React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import { isMobile } from 'react-device-detect';

import SelfEnrollmentWebpageLayout from '../../components/SelfEnrollmentWebpageLayout';
import IdentifyPatient from './IdentifyPatient';
import FoundPatient from './FoundPatient';
import NotifyPatient from './NotifyPatient';
import ThankYou from './ThankYou';
import Privacy from './Privacy';
import InfoAndLinks from './InfoAndLinks';
import ProgressStepper from './ProgressStepper';
import Tracking from './Tracking';

import PatientEnrolled from './PatientEnrolled';
import { SelfEnrollmentProvider } from '../../hooks/useSelfEnrollment';
import usePath from './usePath';
import useMountSpotlightIcon from '../../hooks/useMountSpotlightIcon';
import { PATHS } from './constants';

import styles from './styles.module.scss';

const PatientSelfEnrollment = () => {
  const getPath = usePath();
  useMountSpotlightIcon();

  return (
    <div className={styles.pageContainer}>
      <SelfEnrollmentProvider>
        <SelfEnrollmentWebpageLayout isMobile={isMobile}>
          <div className={styles.container}>
            <ProgressStepper />
            <Switch>
              <Route path="/self-enrollment/:slug?/identify">
                <IdentifyPatient />
              </Route>
              <Route path="/self-enrollment/:slug?/found">
                <FoundPatient />
              </Route>
              <Route path="/self-enrollment/:slug?/notify">
                <NotifyPatient />
              </Route>
              <Route path="/self-enrollment/:slug?/enrolled">
                <PatientEnrolled />
              </Route>
              <Route path="/self-enrollment/:slug?/thanks">
                <ThankYou />
              </Route>
              <Redirect to={getPath(PATHS.IDENTIFY)} />
            </Switch>
            <Privacy />
            <InfoAndLinks />
          </div>
          <Tracking />
        </SelfEnrollmentWebpageLayout>
      </SelfEnrollmentProvider>
    </div>
  );
};

export default PatientSelfEnrollment;
