// @flow

export const MESSAGES = {
  ACCOUNT_PENDING_TO_BE_COMPLETED: 'account-pending-to-be-completed',
  THANK_YOU: 'thank-you',
  PATIENT_FOUND: 'patient-found',
  PATIENT_DEACTIVATED: 'patient-deactivated',
  PATIENT_NOT_FOUND: 'patient-not-found',
  MULTIPLE_PATIENTS_FOUND: 'multiple-patients-found',
  PATIENT_PARTIALLY_MATCHED: 'patient-partially-matched',
  MULTIPLE_PATIENTS_PARTIALLY_MATCHED: 'multiple-patients-partially-matched',
};

export const SCENARIOS = {
  DEACTIVATED: 'deactivated',
  NOT_FOUND: 'not-found',
  MULTIPLE_FOUND: 'multiple-found',
  PARTIALLY_FOUND: 'partially-found',
  UNEXPECTED_ERROR: 'unexpected-error',
};

export const PATHS = {
  IDENTIFY: 'identify',
  ENROLLED: 'enrolled',
  FOUND: 'found',
  NOTIFY: 'notify',
  THANKS: 'thanks',
};
