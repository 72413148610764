// @flow

import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

const PdfFileIcon = (props: {}) => (
  <SvgIcon viewBox="0 0 16 20" {...props}>
    <path
      d="M7.56083 10.0039C7.36588 9.37891 7.36978 8.17188 7.48285 8.17188C7.81038 8.17188 7.77919 9.61328 7.56083 10.0039ZM7.49455 11.8477C7.19431 12.6367 6.81999 13.5391 6.38719 14.2969C7.10073 14.0234 7.90786 13.625 8.83976 13.4414C8.34457 13.0664 7.86887 12.5273 7.49455 11.8477ZM3.82544 16.7227C3.82544 16.7539 4.34013 16.5117 5.18625 15.1523C4.925 15.3984 4.05159 16.1094 3.82544 16.7227ZM10.1382 6.25H15.441V19.0625C15.441 19.582 15.0238 20 14.5052 20H1.40406C0.885472 20 0.468262 19.582 0.468262 19.0625V0.9375C0.468262 0.417969 0.885472 0 1.40406 0H9.20238V5.3125C9.20238 5.82812 9.62349 6.25 10.1382 6.25ZM9.82625 12.9609C9.04641 12.4844 8.52783 11.8281 8.16131 10.8594C8.33677 10.1367 8.61361 9.03906 8.40305 8.35156C8.21979 7.20312 6.74981 7.31641 6.53925 8.08594C6.3443 8.80078 6.52366 9.80859 6.85509 11.0938C6.40278 12.1719 5.73603 13.6172 5.26423 14.4453C5.26033 14.4453 5.26033 14.4492 5.25643 14.4492C4.19976 14.9922 2.38665 16.1875 3.13139 17.1055C3.34974 17.375 3.75525 17.4961 3.96971 17.4961C4.66766 17.4961 5.36171 16.793 6.35209 15.082C7.35808 14.75 8.46154 14.3359 9.43243 14.1758C10.2785 14.6367 11.2689 14.9375 11.9279 14.9375C13.0664 14.9375 13.1444 13.6875 12.696 13.2422C12.154 12.7109 10.5788 12.8633 9.82625 12.9609ZM15.1681 4.10156L11.3469 0.273438C11.1715 0.0976562 10.9336 0 10.6841 0H10.4501V5H15.441V4.76172C15.441 4.51562 15.3436 4.27734 15.1681 4.10156ZM12.2788 14.0742C12.4387 13.9687 12.1813 13.6094 10.61 13.7227C12.0566 14.3398 12.2788 14.0742 12.2788 14.0742Z"
      fill="black"
    />
  </SvgIcon>
);

export default PdfFileIcon;
