// @flow

import React, { useState, useEffect } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useMutation } from '@apollo/react-hooks';
import Box from '@material-ui/core/Box';
import FormHelperText from '@material-ui/core/FormHelperText';
import matches from 'validator/lib/matches';

import FormModal from '../../../Modals/Form';
import ConfirmModal from '../../../Modals/Confirm';
import InputField from '../../../FormControls/InputField';
import StandardButton from '../../../Buttons/Standard';
import SwitchWithTextInside from '../../../FormControls/SwitchWithTextInside';
import {
  CREATE_TOKEN_WITH_TWO_FACTOR_AUTH,
  CONFIRM_CODE_AND_TURN_ON_TWO_FACTOR_CODE,
  TURN_OFF_TWO_FACTOR_AUTH,
} from './mutations';

import styles from './styles.module.scss';

type Props = {
  open: boolean,
  twoFactorEnabled: boolean,
  onClose: () => void,
};

export function TwoFactorModal({ open, twoFactorEnabled, onClose }: Props) {
  const { formatMessage } = useIntl();
  const [enabled, setEnabled] = useState(twoFactorEnabled);
  const [twoFactorCode, setTwoFactorCode] = useState('');
  const [error, setError] = useState('');
  const [warningOpen, setWarningOpen] = useState(false);
  const [qrCodeGenerated, setQrCodeGenerated] = useState(false);
  const twoFactorCodePattern = new RegExp('\\d{6}');

  const [
    createToken,
    { data: { createTokenWith2FactorAuth: { qrCode } = {} } = {}, loading: creatingQrCode },
  ] = useMutation(CREATE_TOKEN_WITH_TWO_FACTOR_AUTH);
  const [confirmCode, { loading: confirmingCode }] = useMutation(CONFIRM_CODE_AND_TURN_ON_TWO_FACTOR_CODE);
  const [turnOffTwoFactorAuth, { loading: turningOff }] = useMutation(TURN_OFF_TWO_FACTOR_AUTH);

  useEffect(() => {
    if (enabled && open) {
      setQrCodeGenerated(true);
      createToken();
    }
  }, [createToken, enabled, open]);

  const handleToggle = (checked) => {
    setEnabled(checked);
    setTwoFactorCode('');
  };

  const handleInputChange = (e) => setTwoFactorCode(e.target.value);

  const handleOnClose = () => {
    setWarningOpen(false);
    setQrCodeGenerated(false);
    setTwoFactorCode('');
    setError('');
    onClose();
  };

  const handleCloseModal = () => {
    setEnabled(twoFactorEnabled);
    handleOnClose();
  };

  const handleOpenWarning = () => {
    if (twoFactorEnabled && qrCodeGenerated) {
      setWarningOpen(true);

      return;
    }

    handleCloseModal();
  };

  const handleCloseWarning = () => setWarningOpen(false);

  const handleTurnOn = async () => {
    try {
      await confirmCode({ variables: { code: twoFactorCode } });

      setEnabled(true);
      handleOnClose();
    } catch (e) {
      setError(formatMessage({ id: 'app.forms.manage.user.enable.two.factor.modal.step.confirm.error' }));
    }
  };

  const handleTurnOff = async () => {
    await turnOffTwoFactorAuth();
    setEnabled(false);
    handleOnClose();
  };

  const GALink = () => (
    <a href="https://support.google.com/accounts/answer/1066447" target="_blank" rel="noopener noreferrer">
      <FormattedMessage id="app.forms.manage.user.enable.two.factor.modal.ga" />
    </a>
  );

  const isValidTwoFactorCode = matches(twoFactorCode, twoFactorCodePattern);

  return (
    <>
      <FormModal
        open={open}
        title={formatMessage({ id: 'app.forms.manage.user.enable.two.factor.modal.title' })}
        onClose={handleOpenWarning}
      >
        <Box p={8}>
          <SwitchWithTextInside onChange={handleToggle} value={enabled} />
          <FormHelperText>
            <FormattedMessage id="app.forms.manage.user.enable.two.factor.helper" />
          </FormHelperText>
          <div className={styles.step}>
            <ol>
              <li>
                <FormattedMessage id="app.forms.manage.user.enable.two.factor.modal.step.download" />
                <GALink />
                <FormattedMessage id="app.forms.manage.user.enable.two.factor.modal.step.on-your-phone" />
              </li>
              <li>
                <FormattedMessage id="app.forms.manage.user.enable.two.factor.modal.step.scan" />
                <GALink />
                {enabled && !creatingQrCode && qrCode && <img src={qrCode} alt="2FA Code" />}
              </li>
              <li>
                <FormattedMessage id="app.forms.manage.user.enable.two.factor.modal.step.code" />
                <GALink />
                {enabled && (
                  <InputField
                    className={styles.codeInput}
                    label={formatMessage({ id: 'app.forms.manage.user.enable.two.factor.modal.step.enter.code' })}
                    meta={error ? { touched: true, error } : { touched: false, error: '' }}
                    input={{
                      name: 'confirm2FaCode',
                      value: twoFactorCode,
                      onChange: handleInputChange,
                    }}
                    maxLength={6}
                  />
                )}
              </li>
            </ol>
          </div>
          <div>
            {enabled && (
              <StandardButton size="small" onClick={handleTurnOn} disabled={!isValidTwoFactorCode || confirmingCode}>
                <FormattedMessage
                  id={
                    twoFactorEnabled
                      ? 'app.forms.manage.user.enable.two.factor.modal.step.confirm-save'
                      : 'app.forms.manage.user.enable.two.factor.modal.step.confirm-turn-on'
                  }
                />
              </StandardButton>
            )}
            {!enabled && (
              <StandardButton size="small" onClick={handleTurnOff} disabled={!twoFactorEnabled || turningOff}>
                <FormattedMessage id="app.forms.manage.user.enable.two.factor.modal.step.confirm-turn-off" />
              </StandardButton>
            )}
          </div>
        </Box>
      </FormModal>
      <ConfirmModal
        open={warningOpen}
        title="app.forms.manage.user.enable.two.factor.modal.step.confirm.warning.title"
        message="app.forms.manage.user.enable.two.factor.modal.step.confirm.warning.message"
        cancelLabel="app.forms.manage.user.enable.two.factor.modal.step.confirm.warning.cancel"
        confirmLabel="app.forms.manage.user.enable.two.factor.modal.step.confirm.warning.exit"
        onConfirm={handleCloseModal}
        onCancel={handleCloseWarning}
      />
    </>
  );
}

export default TwoFactorModal;
