// @flow

import React from 'react';
import classNames from 'classnames';

import styles from './styles.module.scss';

type Props = {
  text?: string,
  customClassName?: string,
};

const Separator = ({ text, customClassName }: Props) => (
  <div className={classNames(styles.separator, customClassName)}>{text}</div>
);

export default Separator;
