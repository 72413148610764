// @flow

import React from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import classNames from 'classnames';
import styles from './styles.module.scss';

const year = new Date().getFullYear();

type Props = {
  defaultPosition?: boolean,
  mobile?: boolean,
};

export const Footer = ({ defaultPosition, mobile }: Props) => (
  <div
    className={classNames({
      [styles.footer]: !mobile,
      [styles.mobileFooter]: mobile,
      [styles.defaultPosition]: defaultPosition,
    })}
  >
    <div>
      <ul>
        <li>
          <a href="https://www.scene.health/privacy-policy" target="_blank" rel="noopener noreferrer">
            <FormattedMessage id="app.login.footer.privacy.policy" />
          </a>
        </li>
        <li>
          <a href="https://www.scene.health/standard-legal-terms-and-license" target="_blank" rel="noopener noreferrer">
            <FormattedMessage id="app.login.footer.legal.terms" />
          </a>
        </li>
        <li>
          <a href="https://www.scene.health/terms-of-use" target="_blank" rel="noopener noreferrer">
            <FormattedMessage id="app.login.footer.terms.of.use" />
          </a>
        </li>
      </ul>
      <ul>
        <li>
          <a href="mailto:helpdesk@scene.health">helpdesk@scene.health</a>
        </li>
        <li>
          <a href="https://www.scene.health" target="_blank" rel="noopener noreferrer">
            www.scene.health
          </a>
        </li>
        <li>
          <a href="https://twitter.com/scenehealth" target="_blank" rel="noopener noreferrer">
            @SceneHealth
          </a>
        </li>
      </ul>
    </div>
    <div className={styles.copyright}>
      {!mobile && <FormattedMessage id="app.login.footer.copyright" values={{ year }} />}
    </div>
    {mobile && (
      <div className={classNames([styles.mobileCopyrightContainer, styles.copyright])}>
        <FormattedMessage id="app.login.footer.copyright" values={{ year }} />
      </div>
    )}
  </div>
);

export default injectIntl(Footer);
