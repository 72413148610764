// @flow

import React, { useMemo } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Switch, Route, useRouteMatch } from 'react-router-dom';

import useSelfEnrollment from '../../../hooks/useSelfEnrollment';
import { MESSAGES } from '../constants';

import styles from './styles.module.scss';

const Privacy = () => {
  const { formatMessage } = useIntl();
  const { entityData, message } = useSelfEnrollment();
  const { termsOfUse = '', privacyPolicy = '' } = entityData ?? {};
  const { isExact: isFoundPage = false } = useRouteMatch('/self-enrollment/:slug/found') ?? {};

  const parts = useMemo(
    () =>
      formatMessage({ id: 'app.self-enroll.privacy' }, { termsOfUse: '{termsOfUse}', privacyPolicy: '{privacyPolicy}' })
        .split(/({termsOfUse})/)
        .flatMap((part) => part.split(/({privacyPolicy})/)),
    [formatMessage],
  );

  if (isFoundPage && message === MESSAGES.PATIENT_FOUND) return null;
  return (
    <Switch>
      <Route path="/self-enrollment/:slug?/(identify|found)">
        <div className={styles.privacyContainer}>
          {parts.map(
            (part) =>
              (part === '{termsOfUse}' && (
                <a href={termsOfUse} key={part}>
                  <FormattedMessage id="app.self-enroll.terms-of-use" />
                </a>
              )) ||
              (part === '{privacyPolicy}' && (
                <a href={privacyPolicy} key={part}>
                  <FormattedMessage id="app.self-enroll.privacy-policy" />
                </a>
              )) ||
              part,
          )}
        </div>
      </Route>
    </Switch>
  );
};

export default Privacy;
