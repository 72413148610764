// @flow

import { useEffect } from 'react';
import updateFavicon from '../utils/updateFavicon';

const useMountSpotlightIcon = () => {
  useEffect(() => {
    const spotlightIconLink = document.getElementById('spotlight-favicon');
    const newSpotlightIconLink = spotlightIconLink || updateFavicon();

    return () => {
      if (newSpotlightIconLink) newSpotlightIconLink.remove();
    };
  }, []);
};

export default useMountSpotlightIcon;
