// @flow

import React, { useState } from 'react';
import { useIntl } from 'react-intl';
import VideoRecorder from 'react-video-recorder';

import Actions from './RenderActions';
import SizeCalculator from './SizeCalculator';

import styles from './styles.module.scss';

type Props = {
  onSendVideo: () => void,
  onChange: (data: Object) => void,
  videoBackTimeLimitSeconds: number,
  hidden: boolean,
  onRecordingStateChange?: (recordingState: 'STARTED' | 'DONE') => void,
};

export const VideoBackRecorder = ({
  videoBackTimeLimitSeconds,
  hidden,
  onSendVideo,
  onChange,
  onRecordingStateChange = () => {},
}: Props) => {
  const intl = useIntl();
  const [videoWidth, setVideoWidth] = useState(null);
  const [videoHeight, setVideoHeight] = useState(null);

  const RenderActions = Actions({ onSendVideo, intl });

  const isSizeInitialized = videoHeight && videoWidth;

  const handleChange = (videoBlob, startedAt, thumbnailBlob, duration) => {
    onChange({ videoBlob, startedAt, thumbnailBlob, duration });
    onRecordingStateChange('DONE');
  };

  return (
    <div className={hidden ? styles.hideVideo : styles.videoHolder}>
      {/* if you stop rendering VideoRecorder it will lose the recorded video in memory */}
      {isSizeInitialized ? (
        <VideoRecorder
          showReplayControls
          replayVideoAutoplayAndLoopOff
          mimeType="video/webm;codecs=h264"
          timeLimit={videoBackTimeLimitSeconds * 1000}
          renderActions={RenderActions}
          isOnInitially
          constraints={{
            video: {
              width: videoWidth,
              height: videoHeight,
            },
            audio: true,
          }}
          onStartRecording={() => onRecordingStateChange('STARTED')}
          onRecordingComplete={handleChange}
        />
      ) : (
        <SizeCalculator onWidthCalculated={setVideoWidth} onHeightCalculated={setVideoHeight} />
      )}
    </div>
  );
};

export default VideoBackRecorder;
