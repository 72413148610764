// @flow

import { Map } from 'immutable';
import {
  CHAT_RESET,
  CHAT_SESSION_SUCCESS,
  CHAT_SESSION_ERROR,
  CHAT_HISTORY_RESULTS,
  CHAT_OPEN_MESSAGE_BANK_FILTERED,
  CHAT_HISTORY_REFRESH,
  CHAT_NO_PREVIOUS,
  CHAT_LOAD_MORE,
  CHAT_MESSAGE_ATTRIBUTES_UPDATED,
} from './constants';

type ChatAction = {
  type: string,
  data: Object,
};

const initialState = Map({
  loading: true,
  token: null,
  conversation: null,
  messages: [],
  users: [],
  videoInformation: [],
  hasPrevPage: null,
  messageBankOpenedAndFiltered: false,
});

function chatReducer(state: Map<string, any> = initialState, { type, data }: ChatAction) {
  switch (type) {
    case CHAT_HISTORY_REFRESH:
      return state.merge({ loading: true });
    case CHAT_SESSION_SUCCESS:
      return state.merge(data);
    case CHAT_HISTORY_RESULTS:
      return state.merge({ ...data, loading: false });
    case CHAT_LOAD_MORE:
      return state.merge({ loading: true });
    case CHAT_NO_PREVIOUS:
      return state.merge({ loading: false, hasPrevPage: false });
    case CHAT_RESET:
    case CHAT_SESSION_ERROR:
      return initialState;
    case CHAT_OPEN_MESSAGE_BANK_FILTERED:
      return state.set('messageBankOpenedAndFiltered', true);
    case CHAT_MESSAGE_ATTRIBUTES_UPDATED: {
      const { attributes } = data;
      const { sid, message: messageUpdated } = attributes;

      const internalSid = sid || messageUpdated?.state?.sid;
      const internalAttributes = messageUpdated ? messageUpdated.state?.attributes : attributes;

      const messages = state.get('messages');

      return state.merge({
        messages: messages.map((message) => {
          if (message.sid === internalSid) {
            return {
              ...message,
              attributes: { ...internalAttributes },
            };
          }
          return { ...message };
        }),
      });
    }

    default:
      return state;
  }
}

export default chatReducer;
