// @flow
import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

const RotateLeft = (props: Object) => (
  <SvgIcon viewBox="0 0 23 21" {...props}>
    <path
      d="M21 5H17.8L16 3H10V5H15.1L17 7H21V19H5V10H3V19C3 20.1 3.9 21 5 21H21C22.1 21 23 20.1 23 19V7C23 5.9 22.1 5 21 5ZM8 13C8 17.45 13.39 19.69 16.54 16.54C19.69 13.39 17.45 8 13 8C10.24 8 8 10.24 8 13ZM13 10C14.64 10.05 15.95 11.36 16 13C15.95 14.64 14.64 15.95 13 16C11.36 15.95 10.05 14.64 10 13C10.05 11.36 11.36 10.05 13 10ZM5 5H8V3H5V0H3V3H0V5H3V8H5"
      fill="white"
    />
  </SvgIcon>
);

export default RotateLeft;
