// @flow

import React from 'react';
import classNames from 'classnames';
import { useRouteMatch } from 'react-router-dom';

import styles from './styles.module.scss';
import useSelfEnrollment from '../../../hooks/useSelfEnrollment';
import { MESSAGES } from '../constants';

const PAGES = [0, 1, 2];
const DEFAULT_MATCH = { isExact: false };

const ProgressStepper = () => {
  const { message } = useSelfEnrollment();
  const { isExact: isIdentifyPage } = useRouteMatch('/self-enrollment/:slug?/identify') ?? DEFAULT_MATCH;
  const { isExact: isFoundPage } = useRouteMatch('/self-enrollment/:slug?/found') ?? DEFAULT_MATCH;
  const { isExact: isNotifyPage } = useRouteMatch('/self-enrollment/:slug?/notify') ?? DEFAULT_MATCH;
  const { isExact: isEnrolledPage } = useRouteMatch('/self-enrollment/:slug?/enrolled') ?? DEFAULT_MATCH;

  let currentPage = 4;
  if (isIdentifyPage) currentPage = 0;
  if (isFoundPage && message === MESSAGES.ACCOUNT_PENDING_TO_BE_COMPLETED) currentPage = 1;
  if (isNotifyPage && message === MESSAGES.THANK_YOU) currentPage = 2;
  if (isEnrolledPage) currentPage = 3;

  return (
    currentPage < 4 && (
      <div className={styles.container}>
        <div className={styles.stepperContainer}>
          {PAGES.map((pageNumber) => (
            <div
              className={classNames({ [styles.completedStep]: pageNumber <= currentPage }, styles.step)}
              key={pageNumber}
            />
          ))}
        </div>
      </div>
    )
  );
};

export default ProgressStepper;
