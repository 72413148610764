// @flow

export const MIN_ZOOM = 1;
export const MAX_ZOOM = 3;
export const ZOOM_STEP = 0.1;
export const ROTATION_STEP = 90;
export const CROP_SIZE = { width: 185, height: 185 };
export const CROP_SHAPE = 'round';
export const DEFAULT_CROP = { x: 0, y: 0 };
export const DEFAULT_ZOOM = 1;
export const DEFAULT_ROTATION = 0;
export const ACCEPTED_FILE_TYPES = 'image/jpg,image/jpeg,image/png';
export const CROPPED_IMAGE_TYPE = 'png';
export const MAX_FILE_SIZE = 5e8;
export const MAX_FILE_NUMBER = 1;
