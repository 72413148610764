// @flow
import React, { createContext, useState, useContext } from 'react';

import type { Element } from 'react';

type MyProfileContext = {
  profileBackdropOpen: boolean,
  userModalOpen: boolean,
  handleOpenProfileBackdrop: () => void,
  handleCloseProfileBackdrop: () => void,
  handleCloseUserModal: () => void,
};
const DEFAULT_VALUE = {
  profileBackdropOpen: false,
  userModalOpen: false,
  handleOpenProfileBackdrop: () => {},
  handleCloseProfileBackdrop: () => {},
  handleCloseUserModal: () => {},
};

const Context = createContext<MyProfileContext>(DEFAULT_VALUE);

const MyProfileProvider = ({ children }: { children: Element<any> | Element<any>[] }) => {
  const [profileBackdropOpen, setProfileBackdropOpen] = useState(false);
  const [userModalOpen, setUserModalOpen] = useState(false);

  const handleOpenProfileBackdrop = () => {
    setProfileBackdropOpen(true);
    setUserModalOpen(true);
  };

  const handleCloseProfileBackdrop = () => setProfileBackdropOpen(false);
  const handleCloseUserModal = () => setUserModalOpen(false);

  const value = {
    profileBackdropOpen,
    userModalOpen,
    handleOpenProfileBackdrop,
    handleCloseProfileBackdrop,
    handleCloseUserModal,
  };

  return <Context.Provider value={value}>{children}</Context.Provider>;
};

const useMyProfile = () => useContext(Context);

export default useMyProfile;
export { MyProfileProvider };
