import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import 'scroll-behavior-polyfill';
import React from 'react';
import ReactDOM from 'react-dom';
import { MuiThemeProvider } from '@material-ui/core/styles';
import { ApolloProvider } from '@apollo/react-hooks';
// import debounce from 'lodash/debounce';

import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';

import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import * as serviceWorker from './serviceWorker';

import configureApollo from './configureApollo';
import { getStore } from './configureStore';
import { translationMessages } from './i18n';
import './global-styles.module.scss';
import emochaTheme from './theme';
import App from './containers/App';
import LanguageProvider from './containers/LanguageProvider';
import LiveVideoProvider from './containers/LiveVideoPatientProvider';
// import { authUserSelector } from './containers/SignIn/selectors';

// const showReportDialogDebounced = debounce((event) => {
//   const { email, firstName, lastName } = authUserSelector(getStore().getState());
//   Sentry.showReportDialog({
//     eventId: event.event_id,
//     user: { email, name: `${firstName} ${lastName}` },
//   });
// }, 200);

Sentry.init({
  release: process.env.REACT_APP_SENTRY_RELEASE,
  dsn: process.env.REACT_APP_WEB_PORTAL_SENTRY_DSN,
  integrations(integrations) {
    return [...integrations, new Integrations.BrowserTracing()].filter(
      (integration) => integration.name !== 'Breadcrumbs',
    );
  },
  tracesSampleRate: 0,
  environment: process.env.REACT_APP_ENVIRONMENT,
  beforeSend(event) {
    // if (event.exception) {
    //   showReportDialogDebounced(event);
    // }
    return event;
  },
});

// configure apollo
const client = configureApollo();

const theme = emochaTheme();

const MOUNT_NODE = document.getElementById('app');

const render = (messages) => {
  ReactDOM.render(
    <MuiThemeProvider theme={theme}>
      <ApolloProvider client={client}>
        <Provider store={getStore()}>
          <LanguageProvider messages={messages}>
            <BrowserRouter>
              <LiveVideoProvider>
                <App />
              </LiveVideoProvider>
            </BrowserRouter>
          </LanguageProvider>
        </Provider>
      </ApolloProvider>
    </MuiThemeProvider>,
    MOUNT_NODE,
  );
};

// Chunked polyfill for browsers without Intl support
if (!window.Intl) {
  new Promise((resolve) => {
    resolve(import('intl'));
  })
    .then(() => Promise.all([import('intl/locale-data/jsonp/en')]))
    .then(() => render(translationMessages));
} else {
  render(translationMessages);
}

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
