// @flow

import React from 'react';
import Icon from '@material-ui/core/Icon';
import noop from 'lodash/noop';
import classNames from 'classnames';
import styles from './styles.module.scss';

type Props = {
  onClick?: (e: any) => any,
  size?: number,
  applyBackground?: boolean,
  className?: string,
};

const Close = ({ onClick = noop, size = 14, applyBackground = true, className = '' }: Props) => (
  <div style={{ height: size, width: size }} className={className}>
    <Icon
      onClick={onClick}
      className={classNames(styles.cancel, { [styles.disableHover]: !applyBackground })}
      style={{ height: size, width: size, fontSize: size * 1.5 }}
    >
      close
    </Icon>
    {applyBackground && (
      <div
        className={styles.background}
        style={{
          height: size,
          width: size,
          padding: size * 0.75,
          position: 'relative',
          top: -(size + size / 4),
          right: size / 4,
        }}
      />
    )}
  </div>
);

export default Close;
