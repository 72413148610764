// @flow

import React, { Suspense } from 'react';

import LoadingIndicator from '../../components/LoadingIndicator';

const RuleEngine = React.lazy(() => import('./index'));

const RuleEngineLoadable = () => (
  <div>
    <Suspense fallback={<LoadingIndicator />}>
      <RuleEngine />
    </Suspense>
  </div>
);

export default RuleEngineLoadable;
