// @flow

import gql from 'graphql-tag';

export const GET_CARE_TEAM_MEMBERS_AND_REVIEWERS = gql`
  query CareTeamMembersAndReviewers {
    careTeamMembers {
      id
      uuid
      firstName
      lastName
      entities {
        id
      }
    }
    reviewers {
      id
      uuid
      firstName
      lastName
      entities {
        id
      }
    }
  }
`;
