// @flow

import { List } from 'immutable';
import type { Map as MapType } from 'immutable';
import { createSelector } from 'reselect';

export const getMessages = (store: MapType<string, any>) => store.get('globalMessage') || List();

export const getGlobalMessages = createSelector([getMessages], (messages) =>
  messages.reduce(
    (memo, message) => [
      ...memo,
      {
        id: message.get('id'),
        type: message.get('type'),
        message: message.get('message'),
        error: message.get('error'),
      },
    ],
    [],
  ),
);
