// @flow

import React from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import flowRight from 'lodash/flowRight';
import groupBy from 'lodash/groupBy';
import partition from 'lodash/partition';
import uniqBy from 'lodash/uniqBy';
import sortBy from 'lodash/sortBy';
import compact from 'lodash/compact';
import isEmpty from 'lodash/isEmpty';
import { Map, List } from 'immutable';
import { Field, reduxForm } from 'redux-form/immutable';
import Box from '@material-ui/core/Box';

import InputField from '../../FormControls/InputField';
import Dropdown from '../../FormControls/Dropdown';
import Checkbox from '../../FormControls/Checkbox';
import StandardButton from '../../Buttons/Standard';
import validate from './validate';
import TwoFactorModal from './TwoFactorModal';
import PatientAssignments from '../../PatientAssignments';
import Tooltip from '../../Tooltip';
import Collapsible from '../../Collapsible';
import UserProfileVideos from './UserProfileVideos';
import ProfilePicture from './ProfilePicture';
import type { EntityType } from './types';

import styles from './styles.module.scss';

type Props = {
  roles?: Array<{
    value: string,
    label: string,
  }>,
  entities?: Array<EntityType>,
  languages: Array<string>,
  editingUserEntities?: Array<EntityType>,
  analyticGroups?: Array<{
    value: string,
    label: string,
  }>,
  intl: { formatMessage: ({ id: string }, values?: Object) => string },
  handleSubmit: ((promise: Promise<any>) => Promise<any>) => void,
  onSubmit: (...args: any) => Promise<any>,
  onCancel: () => void,
  onDelete: () => void,
  submitting: boolean,
  invalid: boolean,
  editing: boolean,
  isCurrentUser: boolean,
  uuid: string,
  careTeamPatientsByEntity: any,
  reviewedPatientsByEntity: any,
  patientWithAppointmentsByEntity: any,
  loadingAssignments: boolean,
  userHasAssignments: boolean,
  canDeleteUser: boolean,
  onBulkReassignmentModalOpen: (entityId: number) => void,
  isExternalUser: boolean,
  isPasswordSet: boolean,
  twoFactorEnabled: boolean,
  ssoOnly: boolean,
  meetYourCareTeamEnabled: boolean,
  automaticPatientAssignmentEnabled: boolean,
};

type State = {
  twoFactorModalOpen: boolean,
};

const BIO_MAX_LENGTH = 200;

const buildOptions = (start, end) => {
  const options = [];
  for (let i = start; i <= end; i += 10) {
    options.push({ value: i, label: `${i}%` });
  }

  return options;
};

export class ManageUser extends React.Component<Props, State> {
  static defaultProps = {
    entities: [],
    roles: [],
  };

  state = {
    twoFactorModalOpen: false,
  };

  getSubmitButtonText() {
    const { editing } = this.props;
    if (editing) {
      return 'app.forms.update.user';
    }
    return 'app.forms.add.user';
  }

  handleSubmit = (...args: Array<any>) => this.props.onSubmit(...args);

  handle2FACloseModal = () => this.setState({ twoFactorModalOpen: false });

  handle2FAOpenModal = () => this.setState({ twoFactorModalOpen: true });

  renderDelete() {
    const { onDelete, loadingAssignments, userHasAssignments, canDeleteUser } = this.props;

    const deleteButton = (
      <StandardButton
        variant="secondary"
        key="delete"
        onClick={onDelete}
        disabled={userHasAssignments || !canDeleteUser || loadingAssignments}
      >
        <FormattedMessage id="app.forms.delete" />
      </StandardButton>
    );

    if (!canDeleteUser) {
      return (
        <Tooltip text="app.user.delete-tooltip-text">
          <span>{deleteButton}</span>
        </Tooltip>
      );
    }

    return deleteButton;
  }

  renderEditPassword() {
    const {
      intl: { formatMessage },
      isExternalUser = false,
      twoFactorEnabled,
    } = this.props;

    const { twoFactorModalOpen } = this.state;

    return (
      <fieldset>
        <FormattedMessage tagName="legend" id="app.forms.current.password.header" />
        <div className={styles.row} key="currentPassword">
          <Field
            name="currentPassword"
            type="password"
            placeholder={formatMessage({ id: 'app.forms.current.password.placeholder' })}
            label={formatMessage({ id: 'app.forms.current.password' })}
            component={InputField}
            maxLength={15}
          />
        </div>
        <div className={styles.row} key="newPassword">
          <div className={styles.newPassword}>
            <Field
              name="newPassword"
              type="password"
              placeholder={formatMessage({ id: 'app.forms.new.password' })}
              label={formatMessage({ id: 'app.forms.new.password' })}
              component={InputField}
              maxLength={15}
            />
          </div>
          <div>
            <Field
              name="confirmNewPassword"
              type="password"
              placeholder={formatMessage({ id: 'app.forms.confirm.password' })}
              label={formatMessage({ id: 'app.forms.confirm.password' })}
              component={InputField}
              maxLength={254}
            />
          </div>
        </div>
        {isExternalUser && (
          <Box className={styles.row} key="multiFactorAuth" mt={2}>
            <div className={styles.twoFactorButton}>
              <StandardButton variant="secondary" onClick={this.handle2FAOpenModal}>
                {formatMessage({ id: 'app.forms.manage.user.enable.two.factor.setup' })}
              </StandardButton>
            </div>
            <Box className={styles.twoFactorEnabled} display="flex" alignItems="center">
              <FormattedMessage
                id={
                  twoFactorEnabled
                    ? 'app.forms.manage.user.enable.two.factor.enabled'
                    : 'app.forms.manage.user.enable.two.factor.disabled'
                }
                tagName="span"
              />
            </Box>
            <TwoFactorModal
              open={twoFactorModalOpen}
              twoFactorEnabled={twoFactorEnabled}
              onClose={this.handle2FACloseModal}
            />
            <div />
          </Box>
        )}
      </fieldset>
    );
  }

  renderLanguagesDropdown = (props: any) => {
    const { input } = props;
    const { value, onChange } = input;

    return (
      <Dropdown
        {...props}
        input={{
          ...input,
          value: value.toJS ? value.toJS() : value,
          onChange: (newValue) => onChange(List(!isEmpty(newValue) ? newValue : [])),
        }}
        isMulti
      />
    );
  };

  renderAutomaticPatientAssignment(canEditData: boolean) {
    const {
      automaticPatientAssignmentEnabled,
      editing,
      intl: { formatMessage },
    } = this.props;

    if (!editing) return null;
    if (!automaticPatientAssignmentEnabled) return null;

    return (
      <fieldset>
        <FormattedMessage tagName="legend" id="app.forms.user.automatic-assignment" />
        <div className={styles.row} key="automatic-assignment">
          <div>
            <Field
              name="automaticReviewerAssignmentEnabled"
              label={formatMessage({ id: 'app.forms.user.automatic-assignment.automatic-reviewer-assignment-enabled' })}
              component={Checkbox}
              data-testid="automaticReviewerAssignmentEnabled"
              disabled={!canEditData}
            />
          </div>
          <div>
            <Field
              name="automaticCareTeamAssignmentEnabled"
              label={formatMessage({
                id: 'app.forms.user.automatic-assignment.automatic-care-team-assignment-enabled',
              })}
              component={Checkbox}
              data-testid="automaticCareTeamAssignmentEnabled"
              disabled={!canEditData}
            />
          </div>
        </div>
        <div className={styles.row} key="capacity">
          <div>
            <Field
              name="capacity"
              label={formatMessage({
                id: 'app.forms.user.automatic-assignment.capacity',
              })}
              component={Dropdown}
              options={buildOptions(20, 100)}
              data-testid="capacity"
              sortOptions={false}
              disabled={!canEditData}
            />
          </div>
          <div>
            <Field
              name="maximumAssignments"
              label={formatMessage({
                id: 'app.forms.user.automatic-assignment.maximum-assignments',
              })}
              placeholder="1000"
              type="number"
              min={1}
              max={1000}
              component={InputField}
              data-testid="maximum-assignments"
              disabled={!canEditData}
            />
          </div>
        </div>
        <div className={styles.row} key="languages">
          <div>
            <Field
              name="languages"
              label={formatMessage({
                id: 'app.forms.user.automatic-assignment.languages',
              })}
              options={[
                { label: 'English', value: 'en' },
                { label: 'Spanish', value: 'es' },
              ]}
              component={this.renderLanguagesDropdown}
              data-testid="languages"
              disabled={!canEditData}
              isMulti
            />
          </div>
        </div>
      </fieldset>
    );
  }

  groupEntityOptionsByParent = (entityOptions: EntityType[]) => {
    const byName = ({ name }) => name.toLowerCase();
    const [parentEntities, childrenEntities] = partition(entityOptions, ({ parentId }) => parentId === null);
    const parentEntityIds = parentEntities.map(({ id }) => id);
    const childrenWithNoParent = childrenEntities.filter(({ parentId }) => !parentEntityIds.includes(parentId));
    const parentEntitiesWithNoAccess = compact(childrenWithNoParent.map(({ parent }) => parent));
    const allParentEntities = sortBy(uniqBy([...parentEntities, ...parentEntitiesWithNoAccess], 'id'), byName);
    const groupedChildrenByParent = groupBy(childrenEntities, 'parentId');

    return allParentEntities.map((parentEntity) => {
      const children = groupedChildrenByParent[parentEntity.id];
      const childrenOptions = children
        ? sortBy(children, byName).map((entity) => ({
            label: `${entity.name}`,
            value: entity.uuid,
            customStyles: { marginLeft: '15px' },
          }))
        : [];
      const parentOption = {
        value: parentEntity.uuid,
        label: parentEntity.name,
        ...(parentEntitiesWithNoAccess.find(({ id }) => id === parentEntity.id) ? { disabled: true } : {}),
      };

      return {
        label: parentEntity.name,
        options: [parentOption, ...childrenOptions],
      };
    });
  };

  renderEntitySelect = (props: any) => {
    const { input } = props;
    const { value, onChange } = input;
    return (
      <Dropdown
        {...props}
        input={{
          ...input,
          value: value.toJS ? value.toJS() : value,
          onChange: (newValue) => onChange(List(!isEmpty(newValue) ? newValue : [])),
        }}
        isMulti
        required
      />
    );
  };

  render() {
    const {
      roles,
      analyticGroups = [],
      onDelete,
      handleSubmit,
      onCancel,
      submitting,
      invalid,
      editing,
      entities = [],
      editingUserEntities = [],
      isCurrentUser,
      uuid,
      intl: { formatMessage },
      careTeamPatientsByEntity,
      reviewedPatientsByEntity,
      patientWithAppointmentsByEntity,
      loadingAssignments,
      onBulkReassignmentModalOpen,
      isPasswordSet = false,
      ssoOnly,
      meetYourCareTeamEnabled,
    } = this.props;

    const editingCurrentUser = editing && isCurrentUser;
    const entityIds = entities.map(({ id }) => id);
    const canEditData = !editing || editingUserEntities.every(({ id }) => entityIds.includes(id));

    const isEmailDisabled = () => {
      if (!canEditData) return true;
      if (!uuid) return false;
      if (!isPasswordSet) return true;
      if (editingCurrentUser) return false;

      return false;
    };

    return (
      <form onSubmit={handleSubmit(this.handleSubmit)}>
        <div className={styles.body}>
          {meetYourCareTeamEnabled && (
            <Box display="flex" justifyContent="center">
              <Field name="profilePicture" type="hidden" component={ProfilePicture} />
            </Box>
          )}
          <fieldset>
            <FormattedMessage tagName="legend" id="app.forms.account.info" />
            <div className={styles.row}>
              <div>
                <Field
                  name="firstName"
                  label={formatMessage({ id: 'app.forms.first.name' })}
                  placeholder={formatMessage({ id: 'app.forms.first.name' })}
                  component={InputField}
                  maxLength={35}
                  disabled={!canEditData}
                  required
                  data-testid="manage-user-first-name"
                />
              </div>
              <div>
                <Field
                  name="lastName"
                  label={formatMessage({ id: 'app.forms.last.name' })}
                  placeholder={formatMessage({ id: 'app.forms.last.name' })}
                  component={InputField}
                  maxLength={35}
                  disabled={!canEditData}
                  required
                  data-testid="manage-user-last-name"
                />
              </div>
            </div>
            {isCurrentUser && meetYourCareTeamEnabled && (
              <div className={styles.row}>
                <Field
                  name="chatDisplayName"
                  label={
                    <Box display="flex" alignItems="center" justifyContent="space-between" width="100%">
                      {formatMessage({ id: 'app.forms.chat-display-name' })}
                      <Tooltip text="app.forms.chat-display-name.tooltip" infoIcon />
                    </Box>
                  }
                  placeholder={formatMessage({ id: 'app.forms.chat-display-name.placeholder' })}
                  component={InputField}
                  maxLength={40}
                  disabled={!canEditData}
                  data-testid="manage-user-chat-display-name"
                />
              </div>
            )}
            <div className={styles.row}>
              <div>
                <Field
                  name="phone"
                  label={formatMessage({ id: 'app.forms.phone.number' })}
                  placeholder={formatMessage({ id: 'app.forms.phone.number' })}
                  component={InputField}
                  variant="phone"
                  disabled={!canEditData}
                  data-testid="manage-user-phone"
                />
              </div>
              <div>
                <Field
                  name="email"
                  label={formatMessage({ id: 'app.forms.email.address' })}
                  placeholder={formatMessage({ id: 'app.forms.email.address.placeholder' })}
                  component={InputField}
                  maxLength={254}
                  disabled={isEmailDisabled()}
                  required
                  data-testid="manage-user-email"
                />
              </div>
            </div>
          </fieldset>
          {this.renderAutomaticPatientAssignment(canEditData)}
          {isCurrentUser && isPasswordSet && !ssoOnly && this.renderEditPassword()}
          {!isCurrentUser && <FormattedMessage tagName="h5" id="app.forms.create.user.note" />}
          <fieldset>
            <FormattedMessage tagName="legend" id="app.forms.roles.and.permissions" />
            <div className={styles.row}>
              <Field
                className={styles.entitySelector}
                name="entityUuids"
                label={formatMessage({ id: 'app.forms.email.entity' })}
                component={this.renderEntitySelect}
                props={{
                  options: this.groupEntityOptionsByParent(entities),
                  disabled: editingCurrentUser || !canEditData,
                }}
                data-testid="manage-user-entity"
              />
            </div>
            <div className={styles.row}>
              <div>
                <Field
                  name="roleUuid"
                  label={formatMessage({ id: 'app.forms.email.role' })}
                  component={Dropdown}
                  options={roles}
                  disabled={editingCurrentUser || !canEditData}
                  required
                  data-testid="manage-user-role"
                />
              </div>
              {Boolean(analyticGroups.length) && (
                <div>
                  <Field
                    name="analyticGroupIds"
                    label={formatMessage({ id: 'app.forms.email.analytic-groups' })}
                    component={Dropdown}
                    options={analyticGroups}
                    disabled={editingCurrentUser || !canEditData}
                    data-testid="manage-user-analytic-group"
                  />
                </div>
              )}
            </div>
          </fieldset>
          {editingCurrentUser && meetYourCareTeamEnabled && (
            <Collapsible
              wrapperCustomClass={styles.collapsibleWrapper}
              title={formatMessage({ id: 'app.user-profile.bio' })}
            >
              <fieldset className={styles.bioFieldset}>
                <div className={styles.bio}>
                  <Field
                    name="bio"
                    variant="long"
                    label={formatMessage({ id: 'app.user-profile.bio' })}
                    component={InputField}
                    maxLength={BIO_MAX_LENGTH}
                    disabled={!canEditData}
                    data-testid="manage-user-bio"
                  />
                  <Field
                    name="profileVideos"
                    label={formatMessage({ id: 'app.user-profile.bio' })}
                    component={UserProfileVideos}
                    disabled={!canEditData}
                    data-testid="manage-user-videos"
                  />
                </div>
              </fieldset>
            </Collapsible>
          )}
          {editing && (
            <PatientAssignments
              uuid={uuid}
              entities={entities}
              careTeamPatientsByEntity={careTeamPatientsByEntity}
              reviewedPatientsByEntity={reviewedPatientsByEntity}
              patientWithAppointmentsByEntity={patientWithAppointmentsByEntity}
              loading={loadingAssignments}
              onBulkReassignmentModalOpen={onBulkReassignmentModalOpen}
            />
          )}
        </div>
        <div className={styles.footer}>
          {editing && onDelete && !editingCurrentUser && this.renderDelete()}
          <StandardButton variant="secondary" onClick={onCancel}>
            <FormattedMessage id="app.forms.cancel" />
          </StandardButton>
          <StandardButton type="submit" disabled={submitting || invalid || !canEditData}>
            <FormattedMessage id={this.getSubmitButtonText()} />
          </StandardButton>
        </div>
      </form>
    );
  }
}

export default flowRight(
  injectIntl,
  reduxForm({ form: 'manage-user', validate, initialValues: Map({ entityUuids: List([]) }) }),
)(ManageUser);
