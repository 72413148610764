import { createTheme } from '@material-ui/core/styles';

export default () =>
  createTheme({
    palette: {
      primary: {
        main: '#f9d849',
        contrastText: '#171717',
      },
      secondary: {
        main: '#171717',
        contrastText: '#171717',
      },
      success: {
        light: '#c6f1c5',
        main: '#7abe79',
        dark: '#046b55',
      },
      warning: {
        light: '#fff8cf',
        main: '#f9d849',
        dark: '#b68e3d',
      },
      error: {
        light: '#ffdae2',
        main: '#ff1744',
        dark: '#be0026',
      },
      info: {
        light: '#b5cfff',
        main: '#2966f6',
        dark: '#232fc4',
      },
      accepted: {
        main: '#417505',
        contrastText: '#171717',
      },
      background: {
        default: '#edf1f3',
        paper: '#ffffff',
      },
    },
    breakpoints: {
      values: {
        xs: 0,
        sm: 768,
        md: 960,
        lg: 1280,
        xl: 1920,
      },
    },
    typography: {
      htmlFontSize: 10,
      fontFamily: 'letteratextllregular',
      h1: {
        color: '#000000',
        display: 'flex',
        fontSize: '3rem',
        alignItems: 'center',
        minHeight: '70px',
      },
      body1: {
        fontSize: '1.3rem',
      },
    },
    spacing: 4,
  });
