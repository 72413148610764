// @flow
import gql from 'graphql-tag';

export const ORGANIZATIONS = gql`
  query organizations {
    organizations {
      id
      name
      displayName
      suffix
    }
  }
`;
