// @flow

import React from 'react';
import { FormattedMessage } from 'react-intl';

import styles from './styles.module.scss';
import StandardButton from '../Buttons/Standard';
import { EMOCHA_APP } from './constants';

const DownloadEmochaApp = () => {
  const handleOpenEmochaApp = () => {
    document.location.href = EMOCHA_APP;
  };
  return (
    <div className={styles.text}>
      <div className={styles.reset}>
        <FormattedMessage tagName="div" id="app.download-emocha-app.reset" />
      </div>
      <div className={styles.clickArea}>
        <StandardButton variant="tertiary" onClick={handleOpenEmochaApp}>
          <FormattedMessage id="app.download-emocha-app.click" />
        </StandardButton>
      </div>
    </div>
  );
};

export default DownloadEmochaApp;
