// @flow

import React from 'react';
import type { Element } from 'react';
import MuiCheckbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Icon from '@material-ui/core/Icon';
import SvgIcon from '@material-ui/core/SvgIcon';
import classnames from 'classnames';

import styles from './styles.module.scss';

type Props = {
  disabled?: boolean,
  label?: string | Element<any>,
  input: {
    onChange?: (SyntheticEvent<HTMLInputElement>, boolean) => void,
    value: boolean,
  },
  meta?: {
    touched?: boolean,
    error?: string,
  },
  variant?: 'large-checkbox',
  inputProps?: Object,
  formControlLabelProps?: Object,
  classes?: Object,
};

export default function Checkbox(props: Props) {
  const {
    disabled,
    input,
    label,
    inputProps = {},
    meta: { error } = {},
    formControlLabelProps = {},
    variant = 3,
    classes = {},
  } = props;
  const { value, ...rest } = input;

  if (variant === 'large-checkbox') {
    return (
      <FormControlLabel
        data-testid="checkbox"
        control={
          <MuiCheckbox
            color="primary"
            classes={{
              root: styles.root,
              colorPrimary: styles.colorPrimary,
              checked: styles.checked,
              ...classes,
            }}
            icon={
              <SvgIcon
                width="22"
                height="22"
                fill="none"
                viewBox="0 0 22 22"
                {...{ classes: { root: classnames(styles.largeCheckbox, { [styles.error]: error }) } }}
              >
                <rect width="21" height="21" x="0.5" y="0.5" rx="1.5" />
              </SvgIcon>
            }
            checkedIcon={
              <SvgIcon width="22" height="22" viewBox="0 0 22 22" {...{ classes: { root: styles.largeCheckbox } }}>
                <rect width="21" height="21" x="0.5" y="0.5" fill="#212631" stroke="#212121" rx="1.5" />
                <path stroke="#fff" strokeLinecap="round" strokeWidth="1.677" d="M6.378 10.88l3.208 3.232L16 7" />
              </SvgIcon>
            }
            checked={Boolean(value)}
            value={String(value)}
            disabled={disabled}
            disableRipple
            {...{ ...inputProps, ...rest }}
          />
        }
        label={label}
        {...formControlLabelProps}
      />
    );
  }
  return (
    <FormControlLabel
      data-testid="checkbox"
      control={
        <MuiCheckbox
          color="primary"
          classes={{
            root: styles.root,
            colorPrimary: styles.colorPrimary,
            checked: styles.checked,
            ...classes,
          }}
          icon={<Icon className={styles.sizeIcon}>check_box_outline_blank</Icon>}
          checkedIcon={<Icon className={styles.sizeIcon}>check_box</Icon>}
          checked={Boolean(value)}
          value={String(value)}
          disabled={disabled}
          disableRipple
          {...{ ...inputProps, ...rest }}
        />
      }
      label={label}
      {...formControlLabelProps}
    />
  );
}
